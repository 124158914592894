import React, { useCallback, useEffect, useState } from "react";
import {
  UncontrolledTreeEnvironment,
  StaticTreeDataProvider,
  Tree,
} from "react-complex-tree";
import "react-complex-tree/lib/style.css";
import { toast } from "react-toastify";
import { getDepartmentList, orderingFolder } from "../../../../services/frontService/DepartmentService";

const DepartmentTreeSection = ({ limitCount }) => {
  const [myData, setMyData] = useState([]);
  const [itemList, setItemList] = useState(null);
  const [updatedData, setUpdatedData] = useState(null);
  const [updateKey, setUpdateKey] = useState(0);
  const [selectedItem, setSelectedItem] = useState(null);
  const [limit, setLimit] = useState(limitCount);
  const [list, setList] = useState({
    loading: true,
    data: [],
    pageCount: 0,
    totalItem: 0,
  });

  const loadList = useCallback(() => {
    setList((pre) => ({ ...pre, data: [], loading: true }));

    getDepartmentList({ limit, sortQuery: "-createdAt" }).then((res) => {
      if (res && res?.status === 200) {
        setList({
          loading: false,
          data: res?.docs,
          pageCount: res.totalPages,
          totalItem: res.totalDocs,
        });
      } else {
        setList((pre) => ({ ...pre, data: [], loading: false }));
        toast.error(res?.message);
      }
    });
  }, [limit]);

  useEffect(() => {
    loadList();
  }, [loadList]);

  useEffect(() => {
    if (list?.data) {
      const transformedData = myStructureData(list?.data);
      setMyData(transformedData);
    }
  }, [list?.data]);

  useEffect(() => {
    if (myData?.root?.children?.length > 0) {
      const convertedData = convertToNewStructure(myData?.root?.children);
      setItemList(convertedData);
    }
  }, [myData]);
  useEffect(() => {
    if (itemList) {
      const dataProvider = new StaticTreeDataProvider(
        itemList,
        (item, newName) => ({ ...item, data: newName })
      );
      setUpdatedData(dataProvider);
      setUpdateKey((prevKey) => prevKey + 1);
    }
  }, [itemList]);

  const myStructureData = (data) => {
    const buildTree = (parentId) => {
      const nodes = data.filter((item) => item.parentId === parentId);
      return nodes.map((node) => {
        const children = buildTree(node._id);
        return {
          index: node._id,
          ordering: node.ordering,
          children: children.length > 0 ? children : [],
          data: node.name,
          parentId: parentId,
          isFolder: parentId === null || children.length > 0,
          folderType: node.theme?.headerColor || "#71b483",
        };
      });
    };

    const rootNodes = buildTree(null);

    return {
      root: {
        index: "root",
        children: rootNodes,
        data: "Root item",
        parentId: null,
      },
    };
  };

  const convertToNewStructure = (data) => {
    const items = {
      root: {
        index: "root",
        isFolder: true,
        children: [],
        data: "Root item",
        parentId: null,
        folderType: "",
      },
    };

    const traverse = (node, parentId = "root") => {
      const {
        index,
        data,
        ordering,
        isFolder,
        folderType,
        children = [],
      } = node;

      items[index] = {
        index,
        isFolder,
        children: [],
        data,
        ordering,
        parentId,
        folderType,
      };

      items[parentId].children.push(index);
      items[parentId].children.sort(
        (a, b) => items[a].ordering - items[b].ordering
      );

      children.forEach((child) => traverse(child, index));
    };

    data.forEach((rootNode) => traverse(rootNode));
    return items;
  };

  const handleDrop = (items, parentItem) => {
    const newParentId = parentItem?.targetItem
      ? parentItem?.targetItem
      : parentItem?.parentItem;

    let order;

    items.forEach((data) => {
      console.log(data,"data")
      console.log(items,"item",parentItem,"parentitem");
      console.log(newParentId,"newparentId");
      if (
        data?.parentId === newParentId &&
        data?.ordering >= parentItem?.childIndex
      ) {
        order = parentItem?.childIndex;
      } else if (
        data?.parentId === newParentId &&
        data?.ordering <= parentItem?.childIndex
      ) {
        order = parentItem?.childIndex - 1;
      } else {
        order = parentItem?.childIndex;
      }
    });
    const payload = items.map((item) => ({
      itemId: item?.index,
      // isFolder: item?.isFolder,
      // folderType: item?.folderType,
      ordering: order === -1 ? 0 : order || order === 0 ? order : "null",
      newParentId: newParentId,
    }));

    orderingFolder(payload[0]).then((res) => {
      if (res?.status === 200) {
        toast.success(res?.message);
      }
    });
  };

  const handleClick = (item) => {
    if (item) {
      setSelectedItem(item?.index);
    }
  };
  const getItemTitle = (item) => (
    <div
      className={`flex space-x-2 text-sm cursor-pointer font-[IBM Plex Sans], sans-serif border-l-4 rounded-lg ${
        item?.isFolder ? "items-center" : "items-start mt-2 leading-5"
      } ${
        selectedItem === item?.index
          ? "bg-orange-200 text-black w-full rounded-[3px] p-4 "
          : "hover:bg-orange-100/20 shadow-md w-full rounded-[3px] p-4"
      }`}
      style={{ borderLeftColor: item?.folderType || "#04d9a9" }}
      onClick={() => handleClick(item)}
    >
      <div
        className={`relative ${
          !item?.isFolder
            ? "line-clamp-1 text-left text-ellipsis overflow-hidden text-[12px]"
            : ""
        }`}
      >
        {item.data}
      </div>
    </div>
  );

  return (
    <div>
      <div className="mt-3 overflow-y-auto scrollbar-smooth  pr-[2px] text-forest-100 current-items-wrap">
        {updatedData && (
          <UncontrolledTreeEnvironment
            key={updateKey}
            dataProvider={updatedData}
            getItemTitle={getItemTitle}
            // viewState={{}}
            viewState={{
              // eslint-disable-next-line no-useless-computed-key
              ["tree-2"]: {
                expandedItems: ["root"],
              },
            }}
            canDragAndDrop={true}
            canDropOnFolder={true}
            canReorderItems={true}
            onDrop={handleDrop}
          >
            <Tree treeId="tree-2" rootItem="root" treeLabel="Tree Example" />
          </UncontrolledTreeEnvironment>
        )}
      </div>
    </div>
  );
};

export default DepartmentTreeSection;

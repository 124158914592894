import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useForm from "../../../hooks/useForm";
import { toast } from "react-toastify";
import { Validation } from "../../../helpers/Validation";
import { changePassword } from "../../../services/ProfileService";
import { formValidate } from "../../../helpers/formValidate";
import Button from "../../../components/form/Button";
import { Tooltip } from "react-tooltip";
import Input from "../../../components/form/Input";
import Modal from "../../../components/elements/Modal";
import ImageUploader from "../../../components/form/ImageUploader";

const FrontPasswordChange = ({
  passwordModal,
  setPasswordModal = () => {},
}) => {
  const [loading, setLoading] = useState(false);

  const [image, setImage] = useState(null);
  const profile = useSelector((state) => state?.profile?.data);

  useEffect(() => {
    if (profile?.image?.url) {
      setImage(profile?.image?.url);
    } else {
      setImage(null);
    }
  }, [profile]);

  const validation = {
    currentPassword: {
      required: true,
      message: "Please enter current password!",
    },
    newPassword: { required: true, message: "Please enter new password!" },
    confirmPassword: {
      required: true,
      message: "Please enter confirm password",
    },
  };

  const { values, handleChange, resetField, handleSubmit, errors } = useForm(
    {},
    validation
  );

  const validatePassword = (password) => {
    const errors = [];
    if (password.length < 8) {
      errors.push("At least 8 characters long.");
    }
    if (!/(?=.*\d)/.test(password)) {
      errors.push("At least one digit.");
    }
    if (!/(?=.*[a-z])/.test(password)) {
      errors.push("At least one lowercase letter.");
    }
    if (!/(?=.*[A-Z])/.test(password)) {
      errors.push("At least one uppercase letter.");
    }
    if (!/(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?])/.test(password)) {
      errors.push("At least one special character (e.g., @, #, $, etc.).");
    }
    if (errors.length > 0) {
      errors.unshift("Password requirements:");
    }

    return errors;
  };

  const onSubmit = async (values) => {
    setLoading(true);
    try {
      if (values.currentPassword) {
        const currentPasswordErrors = validatePassword(values.currentPassword);
        if (currentPasswordErrors.length > 0) {
          toast.error(currentPasswordErrors.join(" "));
          setLoading(false);
          return;
        }
      }

      if (values.newPassword) {
        const newPasswordErrors = validatePassword(values.newPassword);
        if (newPasswordErrors.length > 0) {
          toast.error(newPasswordErrors.join(" "));
          setLoading(false);
          return;
        }
      }

      if (
        values.newPassword &&
        !Validation(
          "confirmPassword",
          values.confirmPassword,
          values.newPassword
        )
      ) {
        toast.error("Confirm password and password don't match");
        setLoading(false);
        return;
      }

      const res = await changePassword({
        currentPassword: values.currentPassword || "",
        newPassword: values.newPassword || "",
        confirmPassword: values.confirmPassword || "",
      });
      if (res?.status === 200 || res?.status === 201) {
        toast.success(res?.message);
        setPasswordModal({ isOpen: false });
        resetField({
          currentPassword: "",
          newPassword: "",
          confirmPassword: "",
        });
      } else {
        toast.error(res.data?.message || "Something went wrong");
      }
    } catch (err) {
      toast.error("Something went wrong!");
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <Modal
        title="Change Password"
        size="xl2"
        open={passwordModal.isOpen}
        onClose={() => {
          setPasswordModal({ isOpen: false });
          resetField({
            currentPassword: "",
            newPassword: "",
            confirmPassword: "",
          });
        }}
        modaltitleClasses={"!text-slate-200 text-[15px]"}
        headerClass={"!bg-black !rounded-t-lg !text-white"}
        childrenClasses={"!rounded-b-lg"}
      >
        <form onSubmit={(e) => handleSubmit(e, onSubmit)} className="grid grid-cols-12 gap-4 p-6 lg:pl-0 bg-white rounded-lg shadow-md">
          <div className="image bg-white col-span-12 lg:col-span-5 border-0 lg:border-r border-slate-200 h-auto">
            <div className="text-lg font-semibold text-slate-700 text-center border-b border-slate-200 mb-2">
                {profile?.firstName + " " + profile?.lastName}
            </div>
              <ImageUploader
                label=""
                labelClasses="text-lg font-bold"
                isMultiple={false}
                image={image}
                setImage={setImage}
                showImageOnly={true}
                buttonLabel={image ? "Change Logo" : "Upload logo"}
                buttonClasses="!bg-transparent !border border-orange-500 !text-orange-500 !h-8"
                inputClasses="w-40 h-40"
              />
            </div>
            
          <div className="input bg-white col-span-12 lg:col-span-7 space-y-3 flex flex-col">
            {/* <div className="text-2xl font-bold mb-3">Change Password</div> */}
            <div className="relative">
              <Input
                label={"Current Password"}
                inputType={"password"}
                value={values?.currentPassword}
                inputPlaceholder={"Enter current password"}
                inputName="currentPassword"
                onChange={handleChange}
                isValidate={true}
                {...formValidate(errors, "currentPassword")}
              />
            </div>
            <div className="relative">
              <Input
                label={"New Password"}
                inputType={"password"}
                value={values?.newPassword}
                inputPlaceholder={"Enter new password"}
                inputName="newPassword"
                onChange={handleChange}
                isValidate={true}
                {...formValidate(errors, "newPassword")}
              />
            </div>
            <div className="relative">
              <Input
                label={"Confirm Password"}
                inputType={"password"}
                value={values?.confirmPassword}
                inputPlaceholder={"Enter confirm password"}
                inputName="confirmPassword"
                onChange={handleChange}
                isValidate={true}
                {...formValidate(errors, "confirmPassword")}
              />
            </div>
            <div className="w-full flex items-center justify-end gap-3 mt-4">
            <div
              className="relative"
              data-tooltip-id="change-password"
              data-tooltip-place="left"
            >
              <Button
                buttonType={"submit"}
                buttonIcon={"fa-light fa-floppy-disk !text-sm"}
                buttonIconPosition={"left"}
                buttonLabel="Change Password"
                loading={loading}
                  buttonClasses={"!py-2 !px-2 rounded-lg text-white !h-auto !bg-orange-500 hover:!bg-orange-600"}
                buttonLabelClasses={"!text-sm"}
              />
              <Tooltip
                id="change-password"
                className="!text-slate-500 !text-sm !bg-slate-950 !bg-opacity-100 z-100"
                render={() => (
                  <div className="text-white">
                    Click to change password.
                  </div>
                )}
              />
            </div>
          </div>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default FrontPasswordChange;

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import noEmpImg from "../../../../assets/images/noEmpImg.svg";
import Image from "../../../elements/Image";
import dayjs from "dayjs";
import { useState } from "react";

export default function Celebrations({ birthDayList }) {
  const [currentSlide, setCurrentSlide] = useState(0);

  // Custom Arrow components
  const CustomPrevArrow = ({ className, onClick, show }) => (
    show ? (
      <div
        className={`${className} absolute left-0 top-1/2 transform -translate-y-1/2 text-2xl text-black bg-white rounded-full shadow-md p-2 z-10 cursor-pointer`}
        onClick={onClick}
        role="button"
        aria-label="Previous"
      >
        <i className="fa-solid fa-chevron-left"></i>
      </div>
    ) : null
  );

  const CustomNextArrow = ({ className, onClick, show }) => (
    show ? (
      <div
      className={`${className} absolute right-0 top-1/2 transform -translate-y-1/2 text-2xl text-black bg-white rounded-full shadow-md p-2 z-10 cursor-pointer`}
      onClick={onClick}
      role="button"
      aria-label="Next"
    >
      <i className="fa fa-chevron-right"></i> 
    </div>
    ) : null
  );

  const celebrations =
    (birthDayList?.data.length > 0 &&
      birthDayList?.data?.map((item) => ({
        title: "Happy Birthday!",
        date: dayjs(item?.startDate).format("MMM D") || "MM/YY",
        name:
          item?.forUserDetail?.firstName + item?.forUserDetail?.lastName || "",
        image: item?.forUserDetail?.image?.url || noEmpImg,
        position: "Backend Developer",
      }))) ||
    [];

  const totalSlides = celebrations?.length || 0;

  // Slider settings
  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    prevArrow: <CustomPrevArrow show={currentSlide > 0} />,
    nextArrow: <CustomNextArrow show={currentSlide < totalSlides - 1} />,
    centerMode: false,
    centerPadding: "0",
    beforeChange: (current, next) => {
      setCurrentSlide(next);
    },
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="px-2 py-1 relative">
      <p className="text-xl font-semibold text-slate-600 mb-6">Celebrations</p>
      {birthDayList.loading ? (
        <div className="grid grid-cols-2 lg:grid-cols-3 gap-4">
          {Array.from({ length: 3 }).map((_, index) => (
            <div
              key={index}
              className="h-52 mx-2 bg-white border border-slate-300 rounded-lg shadow-sm p-2 flex flex-col items-center justify-center gap-2 py-5 shimmer-effect"
            >
              <div className="w-16 h-16 bg-slate-200 rounded-full"></div>
              <div className="h-4 bg-slate-200 rounded w-3/4 mt-3"></div>
              <div className="h-3 bg-slate-200 rounded w-1/2"></div>
              <div className="h-3 bg-slate-200 rounded w-2/3"></div>
            </div>
          ))}
        </div>
      ) : celebrations?.length > 0 ? (
        <>
          <div className="absolute top-13 right-0 w-full h-56 bg-gradient-to-l from-white via-transparent z-10 pointer-events-none"></div>
          <Slider {...sliderSettings} className="w-full cursor-grab relative">
            {celebrations?.map((celebration, index) => (
              <div key={index}>
                <div className="h-52 mx-2 bg-white border border-slate-300 rounded-lg shadow-sm p-2 flex flex-col items-center justify-center gap-2 py-5">
                  <p className="text-sm xl:text-lg font-semibold text-slate-700">
                    {celebration.title} | {celebration.date}
                  </p>
                  <div className="w-16 h-16 relative flex-shrink-0">
                    <Image
                      src={celebration.image}
                      alt={celebration.name}
                      effect={"blur"}
                      className="w-full h-full object-cover rounded-full border"
                    />
                    <span className="absolute bottom-1 -right-2 text-sm text-orange-500 bg-orange-100 rounded-full py-[3px] px-[5px] border">
                      <i className="fa-solid fa-cake-candles" />
                    </span>
                  </div>
                  <div className="text-center">
                    <p className="text-xs lg:text-lg font-medium text-slate-600">
                      {celebration.name}
                    </p>
                    <p className="text-sm text-slate-500">
                      {celebration.position}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </>
      ) : (
        <div className="border border-slate-300 rounded-lg p-5 bg-white shadow-sm">
          <div className="border-2 border-dashed rounded-lg p-6 text-center flex flex-col items-center justify-center gap-4">
            <div className="w-20 h-20 flex items-center justify-center rounded-full bg-gray-100">
              <i className="fa-light fa-calendar-times text-3xl text-gray-500" />
            </div>
            <p className="text-center text-sm font-medium text-slate-700">
              No upcoming birthdays or celebrations at the moment. Stay tuned
              for future events!
            </p>
          </div>
        </div>
      )}
    </div>
  );
}

import React, { useEffect, useState, useCallback } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Button from "../../components/form/Button";
import Logo from "../../assets/images/logo.png";
import UserDropdown from "./UserDropdown";
import Image from "../../components/elements/Image";
import InviteDropdown from "./InviteDropdown";
import DeleteModal from "../../components/common/DeleteModal";
import { Tooltip } from "react-tooltip";
import SearchWithDropdown from "../../components/form/SearchWithDropdown";
import { getEmployeeList } from "../../services/adminService/EmployeeService";
import { useDebounce } from "../../helpers/useDebounce";
import { useDataSyncModal } from "../../Context/SyncDataModalContext";
import { toast } from "react-toastify";
import axios from "axios";
import {
  NINEBOX_API_URL,
  NINEBOX_BASE_URL,
  SUCCESSION_API_URL,
  SUCCESSION_BASE_URL,
} from "../../config/host";
import Select from "../../components/form/Select";
import nineBoxLogo from "../../assets/images/9boxlogo.png";
import successionnowLogo from "../../assets/images/successionnowlogo.png";
import { syncGenarateToken } from "../../services/SsoService";

const Header = ({ profile, isMenuOpen, setMenuOpen }) => {
  const {
    setSyncDataModalOpen,
    syncingLoader,
    setSyncingLoader,
    syncingStep,
    setSyncingStep,
  } = useDataSyncModal();
  const location = useLocation();
  const navigate = useNavigate();
  const [keyword, setKeyword] = useState("");
  const [adminData, setAdminData] = useState({});
  const [empList, setEmpList] = useState({ loading: false, data: [] });
  const [deleteModal, setDeleteModal] = useState({
    isOpen: false,
    loading: false,
  });
  const [showSearch, setShowSearch] = useState(false);
  const debKeyword = useDebounce(keyword, 300);
  const [selectedWebsite, setSelectedWebsite] = useState({
    _id: 1,
    label: "9BoxNow",
    image: nineBoxLogo,
    code: "9BoxNow",
    value: "9BoxNow",
  });

  const userRole = profile?.role?.find(
    (item) => item?.code === "COMPANY-MANAGER"
  );

  const adminRole = profile?.role?.find(
    (item) => item?.code === "COMPANY-ADMINISTRATOR"
  );
  const companyName = profile?.companyDetails?.companyName;
  const truncatedCompanyName =
    companyName?.length > 15
      ? `${companyName.substring(0, 15)}...`
      : companyName;

  useEffect(() => {
    const dataFromLocalStorage = localStorage.getItem("adminData");
    if (dataFromLocalStorage) {
      setAdminData(JSON.parse(dataFromLocalStorage));
    }
  }, []);

  const loadEmpList = useCallback(() => {
    setEmpList({ loading: true, data: [] });
    getEmployeeList({ keyword: debKeyword, limit: 10 })
      .then((res) => {
        setEmpList({
          loading: false,
          data: res?.status === 200 ? res?.docs : [],
        });
      })
      .catch(() => {
        setEmpList({ loading: false, data: [] });
      });
  }, [debKeyword]);

  useEffect(() => {
    loadEmpList();
  }, [loadEmpList]);

  const handleSearchChange = (val) => {
    navigate(`/directory/employee/${val?._id}`);
  };

  const handleModalOpen = () => {
    setDeleteModal({ isOpen: true, loading: false });
  };

  const handleModalClose = () => {
    setDeleteModal((prevState) => ({ ...prevState, isOpen: false }));
  };

  const handleDashboardSwitch = async () => {
    setDeleteModal((prevState) => ({ ...prevState, loading: true }));
    if (adminData?._id && adminData?.uuid) {
      localStorage.setItem("auth_token", adminData?.authToken);
      localStorage.setItem("role", "ADMIN");
      navigate("/admin/dashboard");
      setDeleteModal({ isOpen: false, loading: false });
    }
  };

  const companyCreateVerify = useCallback(
    async (url, headersToken, selectedsite) => {
      const data = {};
      try {
        const result = await axios.post(url, data, {
          headers: {
            Authorization: `Bearer ${headersToken}`,
            "Content-Type": "application/json",
          },
        });
        if (result?.data?.status === 200) {
          setSyncingLoader(false);
          setSyncingStep({
            step: "1",
            message: "Comapny syncing successfull",
            status: 200,
            selectedWebsite: selectedsite,
          });
          if (result?.data) {
            setTimeout(() => {
              setSyncingStep({
                step: "2",
                message: null,
                selectedWebsite: selectedsite,
              });
            }, 2000);
          }
        } else if (result?.data?.status === 417) {
          setSyncingLoader(false);
          setSyncingStep({
            step: "2",
            message: "Comapany Already Exists",
            status: 417,
            selectedWebsite: selectedsite,
          });
          if (result?.data?._id && result?.data?.email) {
            setTimeout(() => {
              setSyncingStep({
                step: "2",
                message: null,
                selectedWebsite: selectedsite,
              });
            }, 1000);
          }
        } else {
          setSyncingLoader(false);
          setSyncingStep({
            step: "1",
            message:
              result?.data?.message ||
              "An error occurred while processing the request.",
            status: 400,
            selectedWebsite: selectedsite,
          });
          setTimeout(() => {
            setSyncDataModalOpen(false);
            toast.error(
              "Please click on the 9box now button to re-syncing the company"
            );
          }, 1000);
        }
      } catch (err) {
        setSyncingLoader(false);
        setSyncingStep({
          step: "1",
          message:
            err?.response?.data?.message ||
            "An error occurred while processing the request.",
          status: 400,
        });
        setTimeout(() => {
          setSyncDataModalOpen(false);
          toast.error(
            "Please click on the 9box now button to re-syncing the company"
          );
        }, 1000);
      }
    },
    []
  );


  const getGenarateToken = async (DOMAIN, API_URL = "") => {
    setSyncingLoader(true);
    setSyncDataModalOpen(true);
    setSyncingStep({ step: "1", message: null });
    try {
      const res = await syncGenarateToken({
        domain: DOMAIN,
      });
      if (res?.status === 200) {
        const url = API_URL + "verify/token";
        const headersToken = res?.data;
        companyCreateVerify(url, headersToken, selectedWebsite);
      } else {
        toast.error(res?.data?.message || "Something went wrong");
        setSyncDataModalOpen(false);
      }
    } catch (err) {
      console.log(err);
      setSyncingLoader(false);
      toast.error("Somthing went wrong !!!");
      setSyncDataModalOpen(false);
    }
  };

  return (
    <>
      <header className="relative w-full bg-slate-900 shadow flex items-center px-6">
        <div className="mr-auto flex items-center gap-10">
          <div className="flex items-center gap-4">
            <Link to="/" className="h-8">
              <Image
                src={Logo}
                alt="Logo"
                effect="blur"
                className="!w-[73%] xl:!w-auto !h-8 !rounded-none !object-contain"
              />
            </Link>
          </div>

          {adminData?.login === "Administrator-login" && (
            <div
              className="relative"
              data-tooltip-id="back-dashboard"
              data-tooltip-place="bottom"
            >
              <Button
                buttonHasLink={false}
                buttonIcon="fa-regular fa-arrow-left-to-bracket"
                buttonIconPosition="left"
                buttonLabel="Back to Admin"
                buttonFunction={handleModalOpen}
              />
              <Tooltip
                id="back-dashboard"
                className="!text-slate-500 !text-sm !bg-slate-950 !bg-opacity-100 z-100"
                render={() => (
                  <div className="text-white">
                    Please click to return to the admin dashboard.
                  </div>
                )}
              />
            </div>
          )}
        </div>
        <div className="ml-2 md:ml-auto">
          <div className="flex items-center gap-4">
            <div className="relative">
              <i
                className="fa-solid fa-magnifying-glass cursor-pointer md:hidden bg-orange-300 w-7 h-7 p-2 rounded-full text-xs pt-[6px]"
                onClick={() => setShowSearch(!showSearch)}
              ></i>

              {/* Search Input for Both Desktop and Mobile */}
              {(showSearch || window.innerWidth >= 768) && (
                <div
                  className={`${
                    window.innerWidth < 768
                      ? "fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center"
                      : ""
                  }`}
                >
                  <div
                    className={`${
                      window.innerWidth < 768
                        ? `bg-white py-5 px-5 shadow-md w-full absolute top-14 ${
                            showSearch
                              ? "animate-slide-down"
                              : "animate-slide-up"
                          } flex items-center flex-row-reverse gap-4 justify-between transition-all duration-300`
                        : "relative w-auto flex items-center"
                    }`}
                  >
                    {window.innerWidth < 768 && (
                      <button
                        className="bg-orange-500 w-7 h-7 p-2 rounded-full text-sm pt-[4px] text-white"
                        onClick={() => setShowSearch(false)}
                      >
                        <i className="fa-solid fa-times"></i>
                      </button>
                    )}

                    <SearchWithDropdown
                      placeholder={`Search ${truncatedCompanyName || ""}`}
                      loading={empList?.loading}
                      keyword={keyword}
                      setKeyword={setKeyword}
                      onChange={handleSearchChange}
                      divClasses="!rounded-3xl !h-9 !flex !items-center !justify-center !opacity-90"
                      inputClasses="!text-sm"
                      dropdownClass="!rounded-xl"
                      dropdownData={empList?.data?.map((item) => ({
                        name: `${item?.firstName} ${item?.lastName}`,
                        subItem: item?.positionDetails?.title || "No position",
                        image: item?.image?.url,
                        _id: item?._id,
                        type: "user",
                      }))}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="relative ml-auto flex justify-end items-center flex-shrink-0 divide-x divide-slate-200">
              {adminRole?.code === "COMPANY-ADMINISTRATOR" &&
                profile?.email === "sangitamanna272@gmail.com" && (
                  <div className="flex items-center gap-1 px-2">
                    <div className="relative w-44">
                      <Select
                        xPlacement={"right"}
                        transitionClass={"!w-full lg:!w-auto"}
                        dropdownClass={"!w-44"}
                        dropdownButtonClass={""}
                        dropdownButtonLabelClass={""}
                        selectedValue={selectedWebsite?.code}
                        getSelectedValue={(e) => {
                          setSelectedWebsite(e);
                        }}
                        dropdownData={[
                          {
                            _id: 1,
                            name: "9BoxNow",
                            image: nineBoxLogo,
                            code: "9BoxNow",
                            value: "9BoxNow",
                          },
                          {
                            _id: 2,
                            name: "SuccessionNow",
                            image: successionnowLogo,
                            code: "successionNow",
                            value: "successionNow",
                          },
                        ]}
                      />
                    </div>
                    <Button
                      buttonHasLink={false}
                      buttonIcon={"fa-regular fa-arrows-rotate"}
                      buttonIconPosition={"left"}
                      buttonClasses={
                        "!px-0 !aspect-[1/1] hover:!text-slate-800 hover:!bg-slate-200 !justify-center"
                      }
                      buttonFunction={() => {
                        if (selectedWebsite?.code === "successionNow") {
                          getGenarateToken(
                            SUCCESSION_BASE_URL,
                            SUCCESSION_API_URL
                          );
                        } else if (selectedWebsite?.code === "9BoxNow") {
                          getGenarateToken(NINEBOX_BASE_URL, NINEBOX_API_URL);
                        } else {
                          toast.error("Please Select a Website For Syncing");
                        }
                      }}
                    />
                  </div>
                )}
            </div>

            <div className="relative">
              <Button
                buttonHasLink={false}
                buttonIcon="fa-regular fa-bars"
                buttonIconPosition="left"
                buttonClasses="!p-0 !aspect-square flex items-center justify-center !rounded-full text-sm md:!text-xl !h-7 md:!h-10"
                buttonFunction={() => setMenuOpen(!isMenuOpen)}
              />
            </div>

            {userRole?.code !== "COMPANY-MANAGER" && (
              <div className="relative border-x border-gray-700">
                <InviteDropdown xPlacement="bottomRight" />
              </div>
            )}
            <div className="relative">
              <UserDropdown
                data={{
                  image: profile?.image?.url,
                  name: `${profile?.firstName || ""} ${
                    profile?.lastName || ""
                  }`.trim(),
                  email: profile?.email,
                }}
                role="user"
                xPlacement="bottomRight"
                isFront={true}
                dropdownContainer="w-44"
              />
            </div>
          </div>
        </div>
      </header>

      <DeleteModal
        open={deleteModal.isOpen}
        onClose={handleModalClose}
        deleteHandler={handleDashboardSwitch}
        title="Switch Dashboard"
        loading={deleteModal.loading}
        deleteButtonLabel="Yes, Go Back"
        deleteButtonIcon="fa-regular fa-arrow-left-to-bracket"
        customMessage="Are you sure you want to switch to the admin dashboard?"
      />
    </>
  );
};

export default Header;

import React, { useCallback, useEffect, useRef, useState } from "react";
import { v4 } from "uuid";
import Tree from "react-d3-tree";
import NodeEditPanel from "../../../components/shared/NodeEditPannel";
import { dfs, verticalStepPathFunc } from "../../../components/constant/Dfs";
import {
  chartAdd,
  chartEdit,
  deleteChart,
  getChart,
  getExcelData,
  unAssignUser,
} from "../../../services/frontService/ChartService";
import { toast } from "react-toastify";
import NodeDelete from "../../../components/modals/front/NodeDelete";
import AssignModal from "../../../components/common/DeleteModal";
import { useDisableZoom } from "../../../helpers";
import RenderRectSvgNode from "../../../components/constant/SvgNode";
import { useDebounce } from "../../../helpers/useDebounce";
import { getEmployeeList } from "../../../services/frontService/EmployeeService";
import SearchWithDropdown from "../../../components/form/SearchWithDropdown";
import html2canvas from "html2canvas";
import Button from "../../../components/form/Button";
import { min } from "lodash";
import * as XLSX from "xlsx";

export default function Chart() {
  useDisableZoom(true);
  const treeRef = useRef(null);
  const [tree, setTree] = useState({});
  const [keyword, setKeyword] = useState("");
  const debKeyword = useDebounce(keyword, 300);
  const [isLoading, setLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteStatus, setDeleteStatus] = useState("single");
  const [empDetails, setEmpDetails] = useState({ loading: false, data: {} });
  const [nodePositions, setNodePositions] = useState({});
  const [translate, setTranslate] = useState({ x: 600, y: 100 });
  const [zoom, setZoom] = useState(0.7);
  const [empList, setEmpList] = useState({ loading: false, data: [] });
  const [isSidepanelOpen, setSidePanelOpen] = useState({
    isOpen: false,
  });
  const [assignModal, setAssignModal] = useState({
    isOpen: false,
  });
  const [isDeleteModal, setDeleteModal] = useState({
    isOpen: false,
    data: {},
  });

  const loadChart = useCallback(() => {
    setLoading(true);
    getChart().then((res) => {
      setLoading(false);
      if (res?.status === 200) {
        setTree(res?.docs[0]);
      }
    });
  }, []);

  const loadempList = useCallback(() => {
    setEmpList({ loading: true, data: [] });
    getEmployeeList({ keyword: debKeyword, limit: 40, chart: true })
      .then((res) => {
        setEmpList({
          loading: false,
          data: res?.status === 200 ? res?.docs : [],
        });
      })
      .catch(() => {
        setEmpList({ loading: false, data: [] });
      });
  }, [debKeyword]);

  useEffect(() => loadempList(), [loadempList]);

  useEffect(() => loadChart(), [loadChart]);

  const handleAddSibling = (nodeDatum, direction) => {
    const newSibling = {
      name: "New Sibling",
      attributes: {
        id: v4(),
      },
      isRoot: true,
      theme: "teal",
      children: [],
    };

    const newTree = dfs(
      nodeDatum?.attributes?.id,
      tree,
      newSibling,
      "addSibling",
      direction
    );

    if (newTree) {
      setTree(newTree);
    }
  };

  const addNode = (nodeDatum) => {
    setLoading(true);
    chartAdd({
      parentId: nodeDatum?._id,
    }).then((res) => {
      setLoading(false);
      if (res?.status === 200) {
        toast.success("Chart added successfully");
        const newNode = { ...res?.data, children: [] };
        const newTree = dfs({
          id: nodeDatum?._id,
          tree,
          node: newNode,
          action: "add",
        });
        if (newTree) {
          setTree(newTree);
        }
      } else {
        toast.error(res?.data?.message || "Something went wrong !");
      }
    });
  };

  const deleteNode = () => {
    const nodeDatum = isDeleteModal?.data;
    const nodeId = nodeDatum?._id;
    if (!nodeId) {
      toast.error("Node ID not found");
      return;
    }
    setIsDeleting(true);
    deleteChart({
      id: nodeId,
      payload: { deletionStatus: deleteStatus },
    }).then((res) => {
      setIsDeleting(false);
      if (res?.status === 200) {
        toast.success("Chart deleted successfully");
        setSidePanelOpen((pre) => ({ ...pre, isOpen: false }));
        setDeleteModal((pre) => ({ ...pre, isOpen: false }));
        loadChart();
        // const newTree = dfs({
        //   id: nodeId,
        //   tree,
        //   node: nodeDatum,
        //   action: "delete",
        //   isMultiple: deleteStatus === "multiple" ? true : false,
        // });

        // if (newTree) {
        //   setTree(newTree);
        // }
      } else {
        toast.error(res?.data?.message || "Something went wrong !");
      }
    });
  };

  const editNode = (nodeDatum, mode = "edit") => {
    return chartEdit({
      id: isSidepanelOpen?._id,
      payload: nodeDatum,
    }).then((res) => {
      if (res?.status === 200) {
        toast.success("Chart edited successfully");
        loadChart();
        const updatedNode = { ...res?.data };
        const newTree = dfs({
          id: isSidepanelOpen?._id,
          tree,
          node: updatedNode,
          action: mode === "replace" ? "replace" : "edit",
        });
        if (newTree) {
          setTree(newTree);
        }
        return res;
      } else {
        toast.error(res?.data?.message || "Something went wrong !");
        setSidePanelOpen((pre) => ({ isOpen: false }));
        return res;
      }
    });
  };
  const usAssignNode = () => {
    setAssignModal((pre) => ({ ...pre, loading: true }));
    unAssignUser({
      id: assignModal?._id,
    }).then((res) => {
      setLoading(false);
      if (res?.status === 200) {
        toast.success("Unassigned successfully");
        setSidePanelOpen((pre) => ({ ...pre, isOpen: false }));
        setEmpDetails((pre) => ({ ...pre, data: {} }));
        setAssignModal((pre) => ({ ...pre, loading: false, isOpen: false }));
        loadChart();
        // const newTree = dfs({
        //   tree,
        //   id: assignModal?._id,
        //   action: "unassign",
        // });
        // if (newTree) {
        //   setTree(newTree);
        // }
      } else {
        toast.error(res?.data?.message || "Something went wrong !");
      }
    });
  };

  const onSearch = (chart) => {
    let targetNode = null;

    const findAndUpdateNode = (node) => {
      if (node?.userDetails?._id) {
        if (node?.userDetails?._id === chart?._id) {
          node.isSearched = true;
          targetNode = node;
        } else {
          delete node.isSearched;
        }
      }

      if (node?.children && node?.children?.length > 0) {
        node.children.forEach(findAndUpdateNode);
      }
    };

    const newTree = JSON.parse(JSON.stringify(tree));

    findAndUpdateNode(newTree);

    setTree(newTree);

    if (targetNode && nodePositions[targetNode?._id]) {
      const { x, y } = nodePositions[targetNode?._id];
      setTranslate({
        x: (window.innerWidth - 240) / 2 - x,
        y: window.innerHeight / 2 - y,
      });
      setZoom(1.5);
    } else {
      setTranslate({ x: 600, y: 100 });
      setZoom(0.7);
    }
  };
  // const downloadTreeAsImage = () => {
  //   if (treeRef.current) {
  //     html2canvas(treeRef.current, {
  //       useCORS: true,
  //       scale: 2,
  //     }).then((canvas) => {
  //       const link = document.createElement("a");
  //       link.href = canvas.toDataURL("image/png");
  //       link.download = "org_chart.png";
  //       link.click();
  //     });
  //   }
  // };

  const downloadXlsx = () => {
    getExcelData({ isAssigned: true, chart: true }).then((res) => {
      if (res?.status === 200) {
        const employees = res?.docs;
        const filteredData = employees?.map((employee) => ({
          LASTNAME: employee.lastName,
          FIRSTNAME: employee.firstName,
          POSITION: employee?.positionDetails
            ? employee?.positionDetails?.title
            : "",
          EMAIL: employee.email,
          ROLE: (() => {
            if (
              employee.roleDetails?.some(
                (role) => role.code === "COMPANY-MANAGER"
              )
            ) {
              return "EVALUATOR";
            }
            if (
              employee.roleDetails?.some(
                (role) => role.code === "COMPANY-ADMINISTRATOR"
              )
            ) {
              return "ADMINISTRATOR";
            }
            return "EMPLOYEE";
          })(),
          DEPARTMENTS: employee?.departmentDetails
            ? employee?.departmentDetails
                ?.map((department) => department?.name)
                .join(", ")
            : "",
          "SUPERVISOR EMAIL":
            employee?.assignedDetail?.assignedDetail?.length > 1
              ? employee?.assignedDetail?.assignedDetail
                  ?.map((assign) =>
                    assign?.parentDoc
                      ? assign?.parentDoc?.parentSupervisor?.email
                      : "N/A"
                  )
                  .join(",")
              : employee?.assignedDetail?.assignedDetail
                  ?.map((assign) => assign?.parentDoc?.parentSupervisor?.email)
                  .join(","),
        }));

        const ws = XLSX.utils.json_to_sheet(filteredData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Employees");

        XLSX.writeFile(wb, "employees.xlsx");
      }
    });
  };
  return (
    <div className="flex flex-row  w-full h-full relative">
      <div className="absolute top-0 left-0 z-20 p-4 flex items-center">
        <SearchWithDropdown
          placeholder={`Search org chart`}
          loading={empList?.loading}
          keyword={keyword}
          setKeyword={setKeyword}
          onChange={onSearch}
          divClasses={"!rounded-xl"}
          dropdownClass={"!rounded-xl"}
          inputClasses={"!pl-0"}
          dropdownData={empList?.data?.map((item) => ({
            name: item?.firstName + " " + item?.lastName,
            subItem: item?.positionDetails?.title || "No position",
            image: item?.image?.url,
            _id: item?._id,
            type: "user",
          }))}
        />
        <Button
          buttonFunction={() => downloadXlsx()}
          buttonLabel={"Download CSV"}
          buttonClasses={
            "ml-4 !bg-white !border-orange-500 border !text-orange-500 "
          }
          buttonIcon={"fa-regular fa-download"}
          buttonIconPosition={"left"}
        />
      </div>

      <div
        onClick={() =>
          isSidepanelOpen?.isOpen && setSidePanelOpen({ isOpen: false })
        }
        className="w-full h-full"
        ref={treeRef}
      >
        {isLoading && (
          <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-75 z-10">
            <div className="container">
              <div className="level-1 rectangle animate-pulse"></div>
              <ol className="level-2-wrapper">
                <li>
                  <div className="level-2 rectangle animate-pulse"></div>
                </li>
                <li>
                  <div className="level-2 rectangle animate-pulse"></div>
                </li>
              </ol>
            </div>
          </div>
        )}
        <Tree
          ref={treeRef}
          data={tree}
          zoomable={true}
          orientation="vertical"
          pathFunc={verticalStepPathFunc}
          zoom={zoom}
          scaleExtent={{ min: 0, max: 5 }}
          translate={translate}
          nodeSize={{ x: 300, y: 180 }}
          enableLegacyTransitions={true}
          transitionDuration={1000}
          centeringTransitionDuration={1000}
          separation={{ siblings: 1, nonSiblings: 1.2 }}
          renderCustomNodeElement={(nodeInfo) => (
            <RenderRectSvgNode
              nodeInfo={nodeInfo}
              addNode={addNode}
              setAssignModal={setAssignModal}
              setDeleteModal={setDeleteModal}
              isSidepanelOpen={isSidepanelOpen}
              setSidePanelOpen={setSidePanelOpen}
              handleAddSibling={handleAddSibling}
              setNodePositions={setNodePositions}
            />
          )}
        />
      </div>
      <NodeEditPanel
        editNode={editNode}
        empDetails={empDetails}
        setEmpDetails={setEmpDetails}
        isSidepanelOpen={isSidepanelOpen}
        setSidePanelOpen={setSidePanelOpen}
      />
      <NodeDelete
        open={isDeleteModal?.isOpen}
        onClose={() => setDeleteModal((pre) => ({ ...pre, isOpen: false }))}
        loading={isDeleting}
        deleteHandler={deleteNode}
        deleteStatus={deleteStatus}
        setDeleteStatus={setDeleteStatus}
        hasChild={isDeleteModal?.data?.children?.length > 0 ? true : false}
      />
      <AssignModal
        open={assignModal?.isOpen}
        loading={assignModal?.loading}
        onClose={() => setAssignModal((pre) => ({ ...pre, isOpen: false }))}
        deleteHandler={usAssignNode}
        title={
          <>
            Confirm removal of{" "}
            <strong>
              {assignModal?.name?.substring(0, 10) +
                (`${assignModal?.name}`.length > 10 ? "..." : "")}
            </strong>
          </>
        }
        customMessage={
          <>
            Are you sure you want to remove{" "}
            <strong>
              {assignModal?.name?.substring(0, 25) +
                (`${assignModal?.name}`.length > 25 ? "..." : "")}
            </strong>{" "}
            from the organization chart?
          </>
        }
        deleteButtonIcon={"fa-regular fa-user-xmark"}
        deleteButtonLabel={"Remove"}
      />
    </div>
  );
}

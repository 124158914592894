import SelectDropdownSearch from "./form/SelectDropdownSearch";
import { formValidate } from "../helpers/formValidate";
import React, { useEffect, useState } from "react";
import Input from "./form/Input";
import useForm from "../hooks/useForm";
import Button from "./form/Button";
import Modal from "./elements/Modal";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import MultiselectDropdown from "./form/MultiselectDropdown";
import DepartmentMange from "./modals/front/DepartmentMange";
import { toast } from "react-toastify";
import PositionAdd from "./modals/front/PositionAdd";

export default function NodeModal({
  posList,
  empAddModal,
  editNode,
  department,
  setDepartment = () => {},
  setEmpAddModal = () => {},
  setKeywords = () => {},
  setEmpDetails = () => {},
  loadPosList = () => {},
  loadDeplist = () => {},
  dropdownValues,
}) {
  const [loading, setLoading] = useState(false);
  const [phone, setPhone] = useState("");
  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const [positionData, setPositionData] = useState({});
  const [isPositionModal, setPositionModal] = useState({
    data: {},
    isOpen: false,
  });
  const validation = {
    firstName: { required: true, message: "Please enter first name!" },
    lastName: { required: true, message: "Please enter last name!" },
    email: { required: true, message: "Please enter email!" },
    departmentId: {
      required: selectedDepartments?.length <= 0 ? true : false,
      message: "Please choose a department !",
    },
    positionId: { required: true, message: "Please select a job title!" },
  };
  const { values, handleChange, handleSubmit, errors, setFieldsValue } =
    useForm({}, validation);
  useEffect(() => {
    if (dropdownValues?.positionId) {
      setFieldsValue({
        // departmentId: dropdownValues?.departmentId,
        positionId: dropdownValues?.positionId,
      });
    } else {
      setFieldsValue({
        positionId: "null",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdownValues?.positionId]);

  useEffect(() => {
    if (values?.positionId === "other") {
      setPositionModal({ data: {}, isOpen: true });
    } else {
      setPositionModal({ data: {}, isOpen: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values?.positionId]);

  const [isDepModal, setDepModal] = useState({
    data: {},
    isOpen: false,
  });

  const handlePhoneInputChange = (value) => {
    setPhone(value);
  };
  const handleOtherClick = () => {
    setDepModal({ data: {}, isOpen: true });
  };
  const onSubmit = async (values) => {
    setLoading(true);
    const departments = {};

    if (
      selectedDepartments &&
      Array.isArray(selectedDepartments) &&
      selectedDepartments.length > 0
    ) {
      selectedDepartments.forEach((item, i) => {
        departments[`departmentId[${i}]`] = item;
      });
    }
    if (values.positionId === "other" && !positionData?._id) {
      toast.error("Select a valid position");
      setLoading(false);
      return;
    }
    const res = await editNode({
      ...values,
      editUser: true,
      phone: phone || "",
      positionId: positionData?._id ? positionData?._id : values?.positionId,
      ...departments,
    });
    setLoading(false);
    setFieldsValue({
      firstName: "",
      lastName: "",
      email: "",
      positionId: "null",
      departmentId: [],
    });
    setPhone("");
    if (res?.status === 200) {
      setEmpDetails((pre) => ({ ...pre, data: { ...res?.data } }));
      setEmpAddModal((pre) => ({ ...pre, isOpen: false }));
      setPositionData({})
      setFieldsValue({
        firstName: "",
        lastName: "",
        email: "",
        departmentId: [],
        positionId: "null",
      });
    }
  };
  const handleSelectedItems = (val) => {
    setSelectedDepartments(val);
  };

  return (
    <>
      <Modal
        title="Add new employee"
        size="xl2"
        open={empAddModal?.isOpen}
        onClose={() => {
          setPhone("");
          setEmpAddModal((pre) => ({ ...pre, isOpen: false }));
          setFieldsValue({
            firstName: "",
            lastName: "",
            email: "",
            departmentId: [],
            positionId: null,
          });
          setPositionData({});
        }}
        modaltitleClasses={"!text-slate-200 text-lg"}
        headerClass={"!bg-black !rounded-t-lg !text-white"}
        childrenClasses={"!rounded-b-lg"}
      >
        <form onSubmit={(e) => handleSubmit(e, onSubmit)}>
          <div className="py-2 px-6">
            <div className="grid grid-cols-12 gap-4">
              <div className="col-span-6">
                <Input
                  isInputGroup={true}
                  inputGroupIcon={"fa-light fa-user text-slate-400"}
                  inputGroupPosition={"left"}
                  label={"First Name"}
                  inputPlaceholder={"Enter first name"}
                  inputClasses={"pl-2"}
                  inputType={"text"}
                  value={values?.firstName}
                  inputName={"firstName"}
                  onChange={handleChange}
                  isValidate
                  {...formValidate(errors, "firstName")}
                />
              </div>
              <div className="col-span-6">
                <Input
                  isInputGroup={true}
                  inputGroupIcon={"fa-light fa-user text-slate-400"}
                  inputGroupPosition={"left"}
                  label={"Last Name"}
                  inputPlaceholder={"Enter last Name"}
                  inputClasses={"pl-2"}
                  inputType={"text"}
                  value={values?.lastName}
                  inputName={"lastName"}
                  onChange={handleChange}
                  isValidate
                  {...formValidate(errors, "lastName")}
                />
              </div>
              <div className="col-span-6">
                <Input
                  isInputGroup={true}
                  isValidate
                  inputGroupIcon={"fa-light fa-envelope text-slate-400"}
                  inputGroupPosition={"left"}
                  label={"Email"}
                  inputClasses={"pl-2"}
                  inputPlaceholder={"Enter employee email"}
                  inputType={"text"}
                  value={values.email}
                  inputName={"email"}
                  onChange={handleChange}
                  {...formValidate(errors, "email")}
                />
              </div>
              <div className="col-span-6 mt-0.5">
                <div className="text-sm font-medium text-slate-500">Phone</div>
                <PhoneInput
                  placeholder="(xxx) xxx-xxxx"
                  value={phone}
                  onChange={handlePhoneInputChange}
                  // defaultCountry="US"
                  international={false}
                />
              </div>
              <div className="col-span-6">
                <SelectDropdownSearch
                  label="Job title"
                  placeholder="Choose a job title"
                  isSearch={true}
                  xPlacement="bottom"
                  buttonArrowIcon="fa-solid fa-chevron-down"
                  tabTextColor="text-slate-700"
                  selectName="positionId"
                  loading={posList?.loading}
                  value={values?.positionId}
                  onChange={handleChange}
                  isValidate={true}
                  inputClasses={"!text-slate-600"}
                  dropdownButtonClass="px-3 py-2 border rounded-md w-full focus:ring focus:ring-blue-200"
                  {...formValidate(errors, "positionId")}
                  dropdownData={[
                    ...(Array.isArray(posList?.data)
                      ? posList?.data?.map(({ _id, title }) => ({
                          name: title,
                          _id: _id,
                        }))
                      : []),
                    {
                      name: "Other",
                      _id: "other",
                    },
                  ]}
                  onSearch={(val) => {
                    setKeywords((pre) => ({
                      ...pre,
                      posKeyword: val,
                    }));
                  }}
                  newAddedPosition={positionData}
                  setPositionData={setPositionData}
                />
              </div>
              <div className="col-span-6">
                <MultiselectDropdown
                  label={"Departments"}
                  buttonLabel={"Select departments"}
                  xPlacement={"bottom"}
                  isCheckbox={true}
                  isSearch={true}
                  dropdownData={department}
                  setDropdownData={setDepartment}
                  value={selectedDepartments}
                  buttonArrowIconClasses={"!text-sm !text-slate-500 !right-3"}
                  buttonLabelClasses={"!font-medium !text-gray-600"}
                  buttonsinglelabelclass={"!text-xs md:!text-sm !font-medium"}
                  selectedItems={handleSelectedItems}
                  isValidate={true}
                  {...formValidate(errors, "departmentId")}
                  onSearch={(e) =>
                    setKeywords((pre) => ({
                      ...pre,
                      deptKeyword: e,
                    }))
                  }
                  isOther={true}
                  handleOtherClick={handleOtherClick}
                />
              </div>
            </div>
            <div className="mt-8 pt-4 border-t border-slate-200">
              <div className="flex items-center justify-end space-x-3">
                <Button
                  buttonType="submit"
                  buttonLabel={loading ? "Saving..." : "Save"}
                  isDisable={loading}
                  buttonLabelClasses="uppercase"
                  buttonClasses="!text-white !bg-orange-500 focus-visible:outline justify-center rounded-md border border-orange-500"
                />
              </div>
            </div>
          </div>
        </form>

        <DepartmentMange
          loadDeplist={loadDeplist}
          isDepModal={isDepModal}
          setDepModal={setDepModal}
        />
      </Modal>
      <PositionAdd
        loadPosList={loadPosList}
        isPositionModal={isPositionModal}
        setPositionModal={setPositionModal}
        setPositionData={setPositionData}
      />
    </>
  );
}

import Image from "../../../elements/Image";
import EventLoader from "../../../loaders/EventLoader";
import noEmpImg from "../../../../assets/images/noEmpImg.svg";
import dayjs from "dayjs";
import { eventTypes } from "../../../constant/constant";

export default function Availability({ todayEventList, tomorrowEventList }) {
  const renderEventList = (eventList, label) => (
    <div className="col-span-1 ">
      <p className="font-semibold text-slate-600">{label}</p>
      <div className="flex flex-col space-y-2 py-2">
        {eventList?.loading ? (
          <EventLoader count={3} />
        ) : eventList?.data?.length === 0 ? (
          <div className="border border-slate-300 rounded-lg p-3 lg:p-5 bg-white shadow-sm">
            <div className="border-2 border-dashed rounded-lg p-3 lg:p-6 text-center flex flex-col items-center justify-center gap-4">
              <div className="w-20 h-20 flex items-center justify-center rounded-full bg-gray-100">
                <i className="fa-light fa-calendar-times text-3xl text-gray-500" />
              </div>
              <p className="text-center text-sm font-medium text-slate-700">
                {`No upcoming events for ${label.toLowerCase()} . Stay tuned!`}
              </p>
            </div>
          </div>
        ) : (
          eventList?.data?.map((event, i) => {
            const eventType = eventTypes[event?.type] || {};
            return (
              <div
                key={i}
                className="flex items-center justify-between px-4 py-2 bg-white border rounded-lg shadow-sm"
              >
                <div className="flex items-center space-x-4">
                  <div className="w-12 h-12 relative">
                    {["announcement", "holiday"].includes(event?.type) ? (
                      <div
                        className={`w-full h-full flex items-center justify-center rounded-full ${
                          event?.type === "holiday"
                            ? "bg-green-100 border-green-200"
                            : "bg-purple-100 border-purple-200"
                        } border`}
                      >
                        <i
                          className={`fa-regular ${
                            event?.type === "holiday"
                              ? "fa-umbrella-beach text-green-600"
                              : "fa-bullhorn text-purple-600"
                          }`}
                        />
                      </div>
                    ) : (
                      <>
                        <Image
                          src={event?.forUserDetail?.image?.url || noEmpImg}
                          alt=""
                          effect="blur"
                          className="w-full h-full object-cover rounded-full border"
                        />
                        <span
                          className={`absolute bottom-1 -left-2 text-[10px] ${eventType.color} rounded-full py-[3px] px-[5px] border flex-shrink-0`}
                        >
                          <i className={eventType.icon} />
                        </span>
                      </>
                    )}
                  </div>
                  <div className="flex-1">
                    <p className="text-sm font-semibold text-slate-700 capitalize">
                      {["announcement", "holiday"].includes(event?.type)
                        ? event?.type
                        : `${event?.forUserDetail?.firstName} ${event?.forUserDetail?.lastName}`}
                    </p>
                    <p className="text-xs text-slate-500">
                      <span className="capitalize font-semibold">
                        {["announcement", "holiday"].includes(event?.type)
                          ? event?.title
                          : event?.type}
                      </span>{" "}
                      • {dayjs(event?.startDate).format("MMM D")}
                      {event?.startDate !== event?.endDate &&
                        ` - ${dayjs(event?.endDate).format("MMM D")}`}
                    </p>
                  </div>
                </div>
              </div>
            );
          })
        )}
      </div>
    </div>
  );

  return (
    <div className="p-2">
      <p className="text-xl font-semibold text-slate-600 mb-4">Availability</p>
      <div className="border border-slate-300 rounded-lg p-4 bg-white shadow-sm">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
          {renderEventList(todayEventList, "Today")}
          {renderEventList(tomorrowEventList, "Tomorrow")}
        </div>
      </div>
    </div>
  );
}

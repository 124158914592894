import React, { useEffect, useState } from "react";
import { Tooltip } from "react-tooltip";
import Button from "../../../components/form/Button";
import Input from "../../../components/form/Input";
import { profileUpdate } from "../../../services/ProfileService";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { setProfile } from "../../../redux/slice/profileSlice,";
import { useDispatch } from "react-redux";
import useForm from "../../../hooks/useForm";
import { formValidate } from "../../../helpers/formValidate";
import Modal from "../../../components/elements/Modal";
import ImageUploader from "../../../components/form/ImageUploader";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

const MyProfile = ({ profileModal, setProfileModal = () => {} }) => {
  const dispatch = useDispatch();
  const [image, setImage] = useState();
  const [loading, setLoading] = useState(false);
  const profile = useSelector((state) => state?.profile?.data);
  const [phone, setPhone] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const validation = {
    firstName: { required: true, message: "Please enter your first name!" },
    lastName: { required: true, message: "Please enter your last name!" },
  };

  const { values, handleChange, handleSubmit, errors, setFieldsValue } =
    useForm({}, validation);

  useEffect(() => {
    if (profile) {
      setFieldsValue({
        firstName: profile?.firstName || "",
        lastName: profile?.lastName || "",
        email: profile?.email || "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  useEffect(() => {
    if (profile?.phone) {
      let phoneNumber = String(profile?.phone);
      if (!phoneNumber.startsWith("+")) {
        phoneNumber = `+${phoneNumber}`;
        setPhone(phoneNumber);
        setPhoneNumber(phoneNumber);
      }
    } else {
      setPhone("");
      setPhoneNumber("");
    }
  }, [profile]);

  const handlePhoneInputChange = (value) => {
    setPhone(value);
  };

  useEffect(() => {
    if (profile?.image?.url) {
      setImage(profile?.image?.url);
    } else {
      setImage(null);
    }
  }, [profile?.image]);

  const onSubmit = (values) => {
    setLoading(true);
    profileUpdate({
      firstName: values?.firstName,
      lastName: values?.lastName,
      email: values?.email,
      phone: phone || "",
      image: image || "null",
    }).then((res) => {
      if (res?.status === 200) {
        dispatch(setProfile(res?.data));
        toast.success(res?.message);
        setProfileModal({ isOpen: false });
      } else {
        toast?.error(res?.data?.message);
      }
      setLoading(false);
    });
  };

  return (
    <>
      <Modal
        title="Edit Administrator Details"
        open={profileModal.isOpen}
        onClose={() => {
          setPhone(phoneNumber);
          setImage(profile?.image?.url);
          setProfileModal({ isOpen: false });
          setFieldsValue({
            firstName: profile?.firstName || "",
            lastName: profile?.lastName || "",
            email: profile?.email || "",
          });
          
        }}
        modaltitleClasses={"!text-slate-200 text-[15px]"}
        headerClass={"!bg-black !rounded-t-lg !text-white"}
        childrenClasses={"!rounded-b-lg"}
      >
        <div className="grid grid-cols-12 gap-6 p-6 bg-white rounded-lg shadow-md">
          {/* Profile Picture and Name Section */}
          <div className="col-span-12 lg:col-span-4 flex flex-col items-center lg:border-r border-slate-200">
            <div className="text-lg font-semibold text-slate-700 text-center border-b border-slate-200 mb-2 w-full">
              <div className="text-lg font-semibold text-slate-700">
                {profile?.firstName + " " + profile?.lastName}
              </div>
            </div>
              <ImageUploader
                label=""
                isMultiple={false}
                image={image}
                setImage={setImage}
                buttonLabel={image ? "Change Logo" : "Upload Logo"}
                buttonLabelClasses="!text-xs"
                labelClasses="text-lg font-bold"
                buttonIcon="fa-upload text-xs"
              buttonClasses="!px-1 !py-2 bg-blue-500 text-white rounded-lg hover:bg-orange-600 mx-auto !h-auto !w-[94%]"
                inputClasses="w-32 h-32 hover:bg-slate-200 rounded-full border-2 border-gray-300 overflow-hidden"
              />
          </div>

          {/* Profile Info Form Section */}
          <div className="col-span-12 lg:col-span-8 flex flex-col">
            <form onSubmit={(e) => handleSubmit(e, onSubmit)} className="space-y-6">
              <div className="grid md:grid-cols-2 gap-6">
                <div>
                  <Input
                    label={"First Name"}
                    inputType={"text"}
                    value={values?.firstName}
                    inputPlaceholder={"Enter first name"}
                    inputName="firstName"
                    onChange={handleChange}
                    isValidate={true}
                    charLimit={20}
                    {...formValidate(errors, "firstName")}
                  />
                </div>

                <div>
                  <Input
                    label={"Last Name"}
                    inputType={"text"}
                    value={values?.lastName}
                    inputPlaceholder={"Enter last name"}
                    inputName="lastName"
                    onChange={handleChange}
                    isValidate={true}
                    charLimit={20}
                    {...formValidate(errors, "lastName")}
                  />
                </div>

                <div>
                  <Input
                    label={"Email"}
                    inputType={"email"}
                    value={values?.email}
                    inputPlaceholder={"Enter email"}
                    inputName="email"
                    onChange={handleChange}
                    isValidate={true}
                    {...formValidate(errors, "email")}
                    isDisabled={true}
                  />
                </div>

                <div>
                  <div className="text-sm font-medium text-slate-500 mb-1">Phone</div>
                  <div className="border rounded-md focus:ring focus:ring-blue-200 w-full mt-1">
                    <PhoneInput
                      placeholder="(xxx) xxx-xxxx"
                      value={phone}
                      onChange={handlePhoneInputChange}
                      international={false}
                    />
                  </div>
                </div>
              </div>

              <div className="flex justify-end items-baseline">
                <Button
                  buttonType={"submit"}
                  buttonIcon={"fa-light fa-floppy-disk"}
                  buttonIconPosition={"left"}
                  buttonLabel="Save"
                  loading={loading}
                  isDisable={loading}
                  buttonLabelClasses={"!text-sm"}
                  buttonClasses="!py-2 !px-2 rounded-lg text-white !h-auto !bg-orange-500 hover:!bg-orange-600"
                />
                <Tooltip
                  id="edit-profile"
                  className="!text-slate-500 !text-sm !bg-slate-950 !bg-opacity-100 z-100"
                  render={() => <div className="text-white">Click to save profile details.</div>}
                />
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default MyProfile;

import React, { useState } from "react";
import { Link } from "react-router-dom";
import { classNames } from "../../helpers/classNames";
import Button from "../../components/form/Button";

export default function NavigationItem({ data, selected = false }) {
  const [isOpen, setIsOpen] = useState(false);

  const hasChildren = data.children && data.children.length > 0;

  return (
    <div>
      <div
        className={classNames(
          "group relative flex items-center justify-between transition-all duration-200 ease-in-out rounded-md pr-2 font-medium",
          (typeof selected === "function" ? selected(data) : selected)
            ? "border-orange-100 bg-orange-100 text-orange-600 "
            : data?.type === "link" &&
                "border-transparent bg-transparent hover:bg-orange-100 hover:text-orange-500"
        )}
      >
        {data?.type === "link" && (
          <Link
            to={data.link}
            className={classNames(
              "flex items-center w-full h-10 gap-2 px-2 flex-shrink text-[15px]",
              data?.isChild && "!text-[13px] !h-8"
            )}
            onClick={(e) => {
              if (hasChildren) {
                e.preventDefault();
                setIsOpen(!isOpen);
              }
            }}
          >
            <i className={classNames("fa-fw", data.icon)}></i>
            <span>{data.name}</span>
          </Link>
        )}

        {data?.type === "button" && (
          <div
            className={classNames(
              "flex items-center w-full px-2 flex-shrink gap-4"
            )}
          >
            <Button
              buttonHasLink={false}
              isImage={true}
              buttonClasses={classNames(
                "!bg-transparent !w-full !border-slate-300 rounded-tl-xl rounded-br-xl"
              )}
              imageUrl={data?.buttonData?.image}
              buttonLabel={data?.buttonData?.name || "Default Label"}
              buttonLabelClasses={classNames(
                "!font-semibold !text-sm",
                data?.buttonData?.color && `text-${data.buttonData.color}`
              )}
              buttonLink={""}
              buttonFunction={() => data?.click()}
            />
          </div>
        )}
        {hasChildren && (
          <button
            onClick={() => setIsOpen(!isOpen)}
            className="text-sm text-slate-400 !leading-none transition-all duration-200 rounded w-5 aspect-square flex items-center justify-center"
          >
            <i
              className={classNames(
                "fa-regular fa-fw fa-angle-down",
                isOpen ? "rotate-0" : "-rotate-90"
              )}
            ></i>
          </button>
        )}
      </div>
      {hasChildren && isOpen && (
        <div className="ml-2 space-y-1 mt-1">
          {data.children.map((child, index) => (
            <NavigationItem key={index} data={child} selected={selected} />
          ))}
        </div>
      )}
    </div>
  );
}

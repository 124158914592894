import React, { useEffect, useState } from "react";
import Button from "../../../components/form/Button";
import Input from "../../../components/form/Input";
import Textarea from "../../../components/form/Textarea";
import Select from "../../../components/form/Select";
import { toast } from "react-toastify";

const ApiKeysSetting = ({
  loading,
  setLoading = () => {},
  details,
  setDetails = () => {},
}) => {
  const [formData, setFormData] = useState([
    {
      whiteListedDomain: "",
      name: "",
      jwtSecretKey: "",
      expireDay: "",
    },
  ]);
  const [formDataAdd, setFormDataAdd] = useState([
    {
      whiteListedDomain: "",
      name: "",
      jwtSecretKey: "",
      expireDay: "",
      providerKey: "",
    },
  ]);
  const [loaded, setLoaded] = useState(false);
  const [enabledConfirmationPopup, setEnabledConfirmationPopup] =
    useState(false);
  const [actionValue, setActionValue] = useState({});
  const [isCopy, setIsCopy] = useState(false);
  const [isOpen, setIsOpen] = useState({ _id: null, open: false });
  const [idData, setIdData] = useState({ id: null, isOpen: false });
  const [enabledDeletePopup, setenabledDeletePopup] = useState({
    isOpen: false,
    data: null,
  });

  const addField = () => {
    setFormData([
      ...formData,
      {
        whiteListedDomain: "",
        name: "",
        jwtSecretKey: "",
        expireDay: "",
      },
    ]);
  };

  console.log(details, "detailsdetailsdetails");

  useEffect(() => {
    if (details?._id) {
      const data =
        details &&
        Array?.isArray(details?.restApiInfo) &&
        details?.restApiInfo?.length > 0 &&
        details?.restApiInfo?.map((item) => ({
          name: item?.name,
          whiteListedDomain: item?.domain,
          jwtSecretKey: item?.secretKey,
          expireDay: item?.expire,
          _id: item?._id,
          providerKey: item?.providerSecretKey,
        }));
      setFormData(data);
    }
  }, [details]);

  const deleteField = (index) => {
    const updatedFormData = formData.filter((_, idx) => idx !== index);
    setFormData(updatedFormData);
  };

  const updateField = (index, field, value) => {
    const updatedFormData = [...formData];
    updatedFormData[index][field] = value;
    setFormData(updatedFormData);
  };

  const handleCopyKey = (key) => {
    navigator.clipboard.writeText(key).then(() => {
      toast.success("Secret key copied successfully!");
    });
  };

  const validateFields = () => {
    let isValid = true;

    // Iterate over each site (index-wise validation)
    for (let index = 0; index < formDataAdd.length; index++) {
      const data = formDataAdd[index];

      if (!data.name.trim()) {
        toast.error(`Site ${index + 1}: Site Name is required`);
        isValid = false;
        break;
      }

      if (!data.whiteListedDomain.trim()) {
        toast.error(`Site ${index + 1}: White Listed Domain is required`);
        isValid = false;
        break;
      }

      if (!data.jwtSecretKey.trim()) {
        toast.error(`Site ${index + 1}: JWT Secret Key is required`);
        isValid = false;
        break;
      }

      if (isNaN(data.expireDay) || data.expireDay <= 0) {
        toast.error(`Site ${index + 1}: Expire Days must be a positive number`);
        isValid = false;
        break;
      }
    }

    return isValid;
  };

  const onSubmit = async (index) => {
    setLoaded(true);
    // if (validateFields()) {
    // try {
    //   const path = "setting/apikey-update";
    //   let payload = {};

    //   if (idData?.id) {
    //     const matchedData =
    //       formData &&
    //       Array?.isArray(formData) &&
    //       formData?.length > 0 &&
    //       formData?.find((item) => item._id === idData?.id);
    //     payload = matchedData && {
    //       name: matchedData?.name,
    //       domain: matchedData?.whiteListedDomain,
    //       secretKey: matchedData?.jwtSecretKey,
    //       expire: matchedData?.expireDay,
    //       id: matchedData?._id,
    //       providerSecretKey: matchedData?.providerKey,
    //     };
    //   } else {
    //     const payloadData =
    //       formDataAdd &&
    //       Array?.isArray(formDataAdd) &&
    //       formDataAdd?.length > 0 &&
    //       formDataAdd?.map((item) => ({
    //         name: item?.name,
    //         domain: item?.whiteListedDomain,
    //         secretKey: item?.jwtSecretKey,
    //         expire: item?.expireDay,
    //       }));
    //     payload = payloadData && payloadData?.length > 0 && payloadData[0];
    //     console.log(
    //       payloadData,
    //       "payloadDatapayloadDatapayloadDatapayloadDatapayloadDatapayloadData"
    //     );
    //   }
    //   const res = await postData(path, {
    //     restApiInfo: payload,
    //     id: payload?.id,
    //   });
    //   if (res.status && res.status === 200) {
    //     toast.success(res.message);
    //     setEnabledConfirmationPopup(false);
    //     setActionValue({});
    //     setIsCopy({ show: true, index: res?.data });
    //     setIsOpen({ _id: null, open: false });
    //     setIdData({ id: null, isOpen: false });
    //     siteDetails();
    //     setFormDataAdd([
    //       {
    //         whiteListedDomain: "",
    //         name: "",
    //         jwtSecretKey: "",
    //         expireDay: "",
    //       },
    //     ]);
    //   } else {
    //     toast.error(res.message);
    //     setEnabledConfirmationPopup(false);
    //     setActionValue({});
    //     setIsCopy({ show: false, index: null });
    //   }
    // } catch (err) {
    //   console.log(err);
    // }
    // setLoaded(false);
  };

  const generateJwtSecret = async (index) => {
    setLoaded(true);
    // try {
    //   const res = await postData("generate/secret-key", {});
    //   if (res.status && res.status === 200) {
    //     updateField(index, "jwtSecretKey", res?.data);
    //     setIsCopy({ show: true, index: index });
    //   } else {
    //     toast.error(res.message);
    //   }
    // } catch (err) {
    //   console.log(err.messege);
    // }
    // setLoaded(false);
  };

  useEffect(() => {
    // if (actionValue && actionValue.button_type === "confirm") {
    //   const generateJwtSecret = async (index) => {
    //     setLoaded(true);
    //     try {
    //       const res = await postData("generate/secret-key", {});
    //       if (res.status && res.status === 200) {
    //         updateField(index, "jwtSecretKey", res?.data);
    //         onSubmit(index);
    //       } else {
    //         toast.error(res.message);
    //       }
    //     } catch (err) {
    //       console.log(err.messege);
    //     }
    //     setLoaded(false);
    //   };
    //   generateJwtSecret(actionValue?.index);
    // } else if (actionValue && actionValue.button_type === "delete") {
    //   const loadDeleteRecord = async () => {
    //     try {
    //       const statusData = await postData("delete/secret-key", {
    //         id: actionValue?.row_id,
    //       });
    //       if (statusData.status === 200) {
    //         siteDetails();
    //       } else {
    //         toast.error(statusData.message);
    //       }
    //     } catch (error) {
    //       toast.error(error.message);
    //     }
    //   };
    //   loadDeleteRecord();
    // }
  }, [actionValue]);

  const getMaskedKey = (secretKey) => {
    const visibleStart = 10;
    const visibleEnd = 10;
    const start = secretKey.slice(0, visibleStart); // First few characters
    const end = secretKey.slice(-visibleEnd); // Last few characters
    const masked = "*".repeat(secretKey.length - visibleStart - visibleEnd); // Mask middle characters
    return `${start}${masked}${end}`;
  };
  return (
    <></>
    // <div className="border bg-white border-gray-200 p-4 shadow rounded-md">
    //   <div className="relative flex justify-between items-center mb-3 flex-wrap sm:flex-nowrap gap-y-4">
    //     <div className="text-2xl font-bold">API Key Setting</div>
    //     {/* <Button
    //       buttonClasses=""
    //       buttonLabelClasses=""
    //       buttonIcon={"fa-solid fa-plus"}
    //       buttonIconPosition={"left"}
    //       buttonType={"button"}
    //       buttonLabel={t("Add More Site")}
    //       functions={addField}
    //       buttonHasLink={false}
    //     /> */}
    //     <Button
    //       buttonClasses=""
    //       buttonLabelClasses=""
    //       buttonIcon={"fa-solid fa-plus"}
    //       buttonIconPosition={"left"}
    //       buttonType={"button"}
    //       buttonLabel={"Add More Site"}
    //       buttonFunction={() => setIsOpen({ _id: null, open: true })}
    //       buttonHasLink={false}
    //     />
    //   </div>
    //   <div className="grid grid-cols-12 gap-4">
    //     <div className="col-span-12 lg:col-span-12 flex flex-col">
    //       <div
    //         className="grid grid-cols-3 gap-4 p-4 overflow-auto scroll-smooth scrollbar"
    //         // max-h-[calc(100vh-50px)]"
    //       >
    //         {formData?.length > 0 &&
    //           formData?.map((data, index) => (
    //             <div
    //               key={index}
    //               className="relative w-full space-y-2 shadow-lg p-3 rounded-lg bg-white border border-slate-100"
    //             >
    //               <div className="relative flex justify-between items-center mb-3 flex-wrap sm:flex-nowrap gap-y-4">
    //                 <div className="text-xl font-semibold text-dark-teal-600">{`Site ${
    //                   index + 1
    //                 }`}</div>
    //                 <div className="flex justify-end items-end gap-2">
    //                   <button
    //                     onClick={() =>
    //                       setIdData({ id: data?._id, isOpen: true })
    //                     }
    //                     className="text-dark-teal-500 text-lg"
    //                   >
    //                     <i className="fa-solid fa-pen-to-square"></i>
    //                   </button>
    //                   <button
    //                     onClick={() =>
    //                       setenabledDeletePopup({
    //                         isOpen: true,
    //                         data: data?._id,
    //                       })
    //                     }
    //                     className="text-rose-500 text-lg"
    //                   >
    //                     <i className="fa-solid fa-trash"></i>
    //                   </button>
    //                 </div>
    //               </div>
    //               {idData?.isOpen && idData?.id === data?._id ? (
    //                 <>
    //                   <Input
    //                     label="Site Name"
    //                     labelClasses="!text-xs"
    //                     inputType="text"
    //                     inputPlaceholder="Enter Site Name"
    //                     inputValue={data.name}
    //                     setInput={(value) => updateField(index, "name", value)}
    //                     requiredDisplay={true}
    //                     isDisabled={!idData?.isOpen}
    //                   />
    //                   <Input
    //                     label="White Listed Domain"
    //                     labelClasses="!text-xs"
    //                     inputType="text"
    //                     inputPlaceholder="Enter Domain"
    //                     inputValue={data.whiteListedDomain}
    //                     onChange={(value) =>
    //                       updateField(index, "whiteListedDomain", value)
    //                     }
    //                     requiredDisplay={true}
    //                     isDisabled={!idData?.isOpen}
    //                   />
    //                   <div className="relative flex items-end gap-3">
    //                     <div className="flex-grow flex-shrink">
    //                       <Textarea
    //                         label="Secret Key"
    //                         labelClasses="!text-xs"
    //                         inputClasses={"!h-10"}
    //                         inputPlaceholder="Generate Secret Key"
    //                         inputValue={
    //                           data.jwtSecretKey
    //                             ? "***************************"
    //                             : data.jwtSecretKey
    //                         }
    //                         setTextarea={(value) =>
    //                           updateField(index, "jwtSecretKey", value)
    //                         }
    //                         requiredDisplay={true}
    //                         isDisable={true}
    //                       />
    //                     </div>
    //                     {/* {isCopy?.show && isCopy?.index === data?._id && (
    //                       <> */}
    //                     <Button
    //                       buttonType="button"
    //                       buttonIconPosition={"left"}
    //                       buttonIcon={"fa-regular fa-copy text-gray-500"}
    //                       buttonClasses="!bg-white shadow border border-slate-200 !px-0 aspect-[1/1] !flex-shrink-0"
    //                       buttonFunction={() => handleCopyKey(data.jwtSecretKey)}
    //                       buttonHasLink={false}
    //                     />
    //                     {/* </>
    //                     )} */}
    //                   </div>
    //                   {isCopy?.show && isCopy?.index === data?._id && (
    //                     <>
    //                       <div className="text-[13px] font-medium text-rose-700">
    //                         Please copy the secret key now, as it will not be
    //                         accessible again.
    //                       </div>
    //                     </>
    //                   )}

    //                   <div className="flex gap-2 mt-2">
    //                     <Button
    //                       buttonType="button"
    //                       buttonLabel="Generate Secret Key"
    //                       buttonIconPosition={"left"}
    //                       buttonIcon={
    //                         loaded
    //                           ? "fa-light fa-spinner fa-spin"
    //                           : "fa-solid fa-rotate"
    //                       }
    //                       buttonClasses="!bg-pink-500 text-white"
    //                       functions={() => {
    //                         if (data.jwtSecretKey) {
    //                           setEnabledConfirmationPopup(true);
    //                           setActionValue((pre) => ({
    //                             ...pre,
    //                             index: index,
    //                             item: data,
    //                           }));
    //                         } else {
    //                           generateJwtSecret(index);
    //                         }
    //                       }}
    //                       buttonHasLink={false}
    //                     />
    //                   </div>
    //                   <div className="relative flex items-end gap-3">
    //                     <div className="flex-grow flex-shrink">
    //                       <Textarea
    //                         label="Provider Key"
    //                         labelClasses="!text-xs"
    //                         inputClasses={"!h-10"}
    //                         inputPlaceholder="Enter Provider Key"
    //                         inputValue={
    //                           data.providerKey
    //                             ? "***************************"
    //                             : data.providerKey
    //                         }
    //                         setTextarea={(value) =>
    //                           updateField(index, "providerKey", value)
    //                         }
    //                         requiredDisplay={true}
    //                         isDisable={!idData?.isOpen}
    //                       />
    //                     </div>
    //                   </div>
    //                   <Select
    //                     label="Expire Days"
    //                     labelClasses={"!text-xs !font-semibold"}
    //                     xPlacement={"left"}
    //                     selectedValue={data.expireDay}
    //                     dropdownClass={"!w-60"}
    //                     dropdownData={[
    //                       {
    //                         _id: " ",
    //                         label: "Select Expire Days",
    //                         value: "",
    //                       },
    //                       ...Array.from({ length: 50 }).map((_, index) => {
    //                         const value = (index + 1) * 10;
    //                         return {
    //                           _id: value,
    //                           label: `${value} Days`,
    //                           value: value,
    //                         };
    //                       }),
    //                     ].filter((itm) => itm)}
    //                     getSelectedValue={(value) =>
    //                       updateField(index, "expireDay", value._id)
    //                     }
    //                     requiredDisplay={true}
    //                   />
    //                   <div className="flex justify-end gap-4 mt-5">
    //                     <Button
    //                       buttonClasses={
    //                         "bg-white border border-rose-500 !text-rose-500 ml-2"
    //                       }
    //                       buttonLabelClasses="text-rose-500"
    //                       buttonType={"button"}
    //                       buttonIcon={"fa-solid fa-xmark"}
    //                       buttonIconPosition={"left"}
    //                       buttonLabel={"cancel"}
    //                       buttonFunction={() => {
    //                         setIdData({ id: null, isOpen: false });
    //                       }}
    //                       buttonHasLink={false}
    //                     />
    //                     <Button
    //                       buttonClasses=""
    //                       buttonLabelClasses=""
    //                       buttonType={"button"}
    //                       buttonIcon={
    //                         loaded
    //                           ? "fa-light fa-spinner fa-spin"
    //                           : "fa-light fa-floppy-disk"
    //                       }
    //                       buttonIconPosition={"left"}
    //                       buttonLabel={"save"}
    //                       buttonFunction={onSubmit}
    //                       buttonHasLink={false}
    //                     />
    //                   </div>
    //                 </>
    //               ) : (
    //                 <>
    //                   <div className="space-y-1">
    //                     <div className="font-medium text-slate-500 mb-1 text-xs">
    //                       Site Name
    //                     </div>
    //                     <div className="text-sm text-slate-700">
    //                       {data.name}
    //                     </div>
    //                   </div>
    //                   <div className="space-y-1">
    //                     <div className="font-medium text-slate-500 mb-1 text-xs">
    //                       White Listed Domain
    //                     </div>
    //                     <div className="text-sm text-slate-700">
    //                       {data.whiteListedDomain}
    //                     </div>
    //                   </div>
    //                   <div className="space-y-1">
    //                     <div className="font-medium text-slate-500 mb-1 text-xs">
    //                       Secret Key
    //                     </div>
    //                     <div className="text-sm text-slate-700">
    //                       {data?.jwtSecretKey
    //                         ? "***************************"
    //                         : "N/A"}
    //                     </div>
    //                   </div>
    //                   <div className="space-y-1">
    //                     <div className="font-medium text-slate-500 mb-1 text-xs">
    //                       Expire Days
    //                     </div>
    //                     <div className="text-sm text-slate-700">
    //                       {data.expireDay}
    //                     </div>
    //                   </div>
    //                   <div className="space-y-1">
    //                     <div className="font-medium text-slate-500 mb-1 text-xs">
    //                       Provider Key
    //                     </div>
    //                     <div className="text-sm text-slate-700">
    //                       {data?.providerKey
    //                         ? "***************************"
    //                         : "N/A"}
    //                     </div>
    //                   </div>
    //                 </>
    //               )}
    //             </div>
    //           ))}
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
};

export default ApiKeysSetting;

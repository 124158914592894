import React, { useEffect, useRef, useState } from "react";
import Image from "../../../components/elements/Image";
import Button from "../../../components/form/Button";
import { classNames } from "../../../helpers/classNames";
import OurMissionManage from "../../../components/modals/front/OurMissionManage";
import {
  companyDetails,
  companyUpdate,
} from "../../../services/frontService/CompanyService";
import { toast } from "react-toastify";
import CompanyManage from "../../../components/modals/front/CompanyManage";
import { left } from "@popperjs/core";
import WebsiteLinkManage from "../../../components/modals/front/WebsiteLinkManage";
import noImage from "../../../assets/images/no-results-bg.2d2c6ee3.png";
import { setProfile } from "../../../redux/slice/profileSlice,";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Links from "../../../components/sections/front/Links";
import noUser from "../../../assets/images/noEmpImg.svg";
import FrontCompanySkeleton from "../../../components/skeleton/FrontCompanySkeleton";
import { Tooltip } from "react-tooltip";
import MyProfile from "../profile/MyProfile";
import { setTitle } from "../../../helpers/MetaTag";
import { formatPhoneNumber } from "../../../helpers/formatPhoneNumber";

const CompanyDetails = () => {
  setTitle("ORG Chart | Company");
  const dispatch = useDispatch();
  const profile = useSelector((state) => state?.profile?.data);

  const userRole = profile?.role?.find(
    (item) => item?.code === "COMPANY-MANAGER"
  );
  const fileInputRef = useRef(null);
  const [details, setDetails] = useState();
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);

  const [ourMissionModal, setMissionModal] = useState({
    isOpen: false,
    data: [],
  });
  const [websiteLinkModal, setWebsiteLinkModal] = useState({
    isOpen: false,
    data: [],
  });
  const [companyDetailsModal, setCompanyDetailsModal] = useState({
    isOpen: false,
    data: {},
  });
  const [profileModal, setProfileModal] = useState({
    isOpen: false,
    data: {},
  });

  useEffect(() => {
    setLoading(true);
    companyDetails().then((res) => {
      if (res?.status === 200) {
        setDetails(res?.data);
      } else {
        toast.error("Something went wrong!");
      }
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [isExpanded, setIsExpanded] = useState(false);
  const toggleReadMore = () => setIsExpanded(!isExpanded);

  const data = details?.digitalAddress;

  const patternAfter =
    "after:content-[''] after:absolute after:z-[-1] after:w-[112%] md:after:w-1/2 after:h-40 after:bg-amber-500/15 after:-bottom-1/2 after:right-[-4.5rem] md:after:-right-10 after:rotate-[-28deg] md:after:rotate-[-18deg] lg:after:rotate-[-15deg]";
  const patternBefore =
    "before:content-[''] before:absolute before:z-[-1] before:w-[14rem] md:before:w-60 before:h-80 before:bg-cyan-500/20 before:-top-20 before:-right-40 before:rotate-[-26deg] md:before:rotate-[-15deg]";

  useEffect(() => {
    if (details?.bannerImage) {
      setImage(details?.bannerImage?.url);
    }
  }, [details]);

  const removeImage = () => {
    companyUpdate({
      bannerImage: "null",
    }).then((res) => {
      if (res?.status === 200) {
        setImage(null);
        dispatch(
          setProfile({
            ...profile,
            companyDetails: {
              ...profile?.companyDetails,
              bannerImage: { url: res.data?.bannerImage?.url },
            },
          })
        );
      } else {
        toast.error("Something went wrong!");
      }
    });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && ["jpg", "jpeg", "png"].includes(file.type.split("/")[1])) {
      setImage(file);
      companyUpdate({
        bannerImage: file || "null",
      }).then((res) => {
        if (res?.status === 200) {
          dispatch(
            setProfile({
              ...profile,
              companyDetails: {
                ...profile?.companyDetails,
                bannerImage: { url: res.data?.bannerImage?.url },
              },
            })
          );
        }
      });
    } else {
      toast.error("We accept only jpg, jpeg and png format!");
    }
  };
  return (
    <>
      {!loading ? (
        <div className="flex flex-row  w-full pb-6">
          <div className="w-full relative z-10">
            <div className="w-full h-64 relative group">
               {image && (
                <>
                  <Image
                    src={
                      image instanceof File ? URL.createObjectURL(image) : image
                    }
                    alt="Company Image"
                    effect="blur"
                    className="object-cover w-full h-full"
                  />
                </>
              )} 
              {userRole?.code !== "COMPANY-MANAGER" && (
                <>
                  <div className="absolute top-4 right-16 flex items-center gap-4">
                    {image && (
                      <div data-tooltip-id="remove-image">
                        <button
                          onClick={removeImage}
                          type="button"
                          aria-label="Remove image"
                          className="flex items-center justify-center w-8 h-8 rounded-full bg-red-500 text-white shadow text-sm"
                        >
                          <i className="fa-regular fa-fw fa-trash"></i>
                        </button>
                        <Tooltip
                          id="remove-image"
                          place="left"
                          className="!text-slate-500 !text-xs !bg-slate-950 !bg-opacity-100 z-100 hidden md:block"
                          render={() => (
                            <div className="text-white">
                              Click to remove banner image.
                            </div>
                          )}
                        />
                      </div>
                    )}
                  </div>
                  <div
                    className="absolute top-4 right-4"
                    data-tooltip-id="edit-banner"
                  >
                    <button
                      aria-label="Edit image"
                      className="text-md w-8 h-8 flex items-center justify-center rounded-full bg-white bg-opacity-70"
                    >
                      <input
                        type="file"
                        ref={fileInputRef}
                        onChange={handleFileChange}
                        className="absolute inset-0 opacity-0 cursor-pointer"
                      />
                      <i
                        className={`${
                          image
                            ? "fa-regular fa-pen-to-square"
                            : "fa-regular fa-camera"
                        } text-black`}
                      ></i>
                    </button>
                    <Tooltip
                      id="edit-banner"
                      place="bottom"
                      className="!text-slate-500 !text-xs !bg-slate-950 !bg-opacity-100 z-100 hidden md:block"
                      render={() => (
                        <div className="text-white">
                          {image
                            ? "Click to change banner image."
                            : "Click to upload banner image"}
                        </div>
                      )}
                    />
                  </div>
                </>
              )}
            </div>

            <div className="w-full lg:w-11/12 mx-auto -mt-20 space-y-8 px-6 lg:px-0">
              <div
                className={classNames(
                  "relative w-full md:flex block lg:space-x-8 md:space-x-5 space-x-0 bg-white lg:px-10 px-5 py-8 rounded-md shadow-sm border border-gray-100  z-0 overflow-hidden",
                  patternBefore,
                  patternAfter
                )}
              >
                <div className="flex justify-center">
                  <div className="4xl:w-40 3xl:w-36 lg:w-44 md:w-36 w-32 !aspect-square overflow-hidden rounded-lg flex-shrink-0 flex border border-slate-200 p-1">
                    <Image
                      src={details?.image?.url || noImage}
                      effect={"blur"}
                      className="object-cover"
                    />
                  </div>
                </div>
                <div className="flex-shrink flex-grow space-y-2 flex flex-col">
                  <div className="md:flex block justify-between">
                    <div className="flex gap-4 items-center lg:justify-start justify-center">
                      <div data-tooltip-id="company-details-section">
                        <div className="text-gray-800 font-semibold text-3xl">
                          {details?.companyCredential?.name &&
                          details.companyCredential.name.length > 20
                            ? details.companyCredential.name.substring(0, 20) +
                              "..."
                            : details?.companyCredential?.name || ""}
                        </div>
                      </div>
                    </div>

                    <div className="border border-orange-300 px-2 rounded-md text-orange-500 font-bold text-base flex items-center justify-center uppercase mt-4">
                      Total Employee : {details?.employeeCount?.count || "00"}
                    </div>
                  </div>

                  <div
                    className="space-y-4 mt-4 w-fit"
                    data-tooltip-id="company-details-section"
                  >
                    <div className="flex gap-2 items-center">
                      <div className="text-xs text-slate-500 flex-shrink-0">
                        <i className="fa-regular fa-fw fa-envelope"></i>
                      </div>
                      <div className="flex-shrink border-l border-slate-200 pl-2 flex-grow">
                        <div className="text-sm text-slate-500">
                          {details?.companyCredential?.email}
                        </div>
                      </div>
                    </div>

                    <div className="flex gap-2 items-center">
                      <div className="text-xs text-slate-500 flex-shrink-0">
                        <i className="fa-regular fa-fw fa-phone"></i>
                      </div>
                      <div className="flex-shrink border-l border-slate-200 pl-2 flex-grow">
                        <div className="text-sm text-slate-500">
                          {details?.companyCredential?.phone
                            ? `+${formatPhoneNumber(
                                details?.companyCredential?.phone
                              )}`
                            : "N/A"}
                        </div>
                      </div>
                    </div>
                  </div>
                  {userRole?.code !== "COMPANY-MANAGER" && (
                    <div className="flex justify-end items-end !mt-auto">
                      <div data-tooltip-id="edit-company-details">
                        <Button
                          buttonClasses="!bg-transparent !p-0 !h-6 !border-none !text-orange-500"
                          buttonHasLink={false}
                          buttonType="button"
                          buttonIconPosition="left"
                          buttonIcon={"fa-regular fa-pen-to-square"}
                          buttonLabelClasses="!text-xs !font-medium"
                          buttonFunction={() =>
                            setCompanyDetailsModal({
                              isOpen: true,
                              data: {
                                companyCredential: details?.companyCredential,
                                image: details?.image,
                              },
                            })
                          }
                        />
                        <Tooltip
                          id="edit-company-details"
                          place="right"
                          className="!text-slate-500 !text-sm !bg-slate-950 !bg-opacity-100 z-100"
                          render={() => (
                            <div className="text-white">
                              Click to edit company details.
                            </div>
                          )}
                        />
                      </div>
                    </div>
                  )}
                  <Tooltip
                    id="company-details-section"
                    place="right"
                    className="!text-slate-500 !text-sm !bg-slate-950 !bg-opacity-100 z-100"
                    render={() => (
                      <div className="text-white">
                        <div>
                          Company:{" "}
                          {details?.companyCredential?.name &&
                          details.companyCredential.name.length > 20
                            ? details.companyCredential.name.substring(0, 20) +
                              "..."
                            : details?.companyCredential?.name || ""}
                        </div>
                        <div>Email: {details?.companyCredential?.email}</div>
                        <div>
                          Phone:
                          {profile?.phone
                            ? `+${formatPhoneNumber(profile?.phone)}`
                            : "N/A"}
                        </div>
                      </div>
                    )}
                  />
                </div>
              </div>
              <div className="md:grid grid-cols-5 gap-6 bg-white rounded-md px-6 ">
                <div className="col-span-3 space-y-10 md:border-r md:pr-6 py-8">
                  <div className="relative space-y-2">
                    <div className="relative bg-white rounded-lg px-5 py-4 space-y-4 shadow border border-gray-100">
                      <div className="flex justify-between items-center">
                        <div className="text-gray-800 font-bold text-lg capitalize">
                          <i class="fa-regular fa-star"></i>{" "}
                          {details?.companyInfo?.goal || "Mission Statement"}
                        </div>
                        {userRole?.code !== "COMPANY-MANAGER" && (
                          <div
                            className="flex-shrink-0"
                            data-tooltip-id="edit-company-mission"
                          >
                            <Button
                              buttonClasses={`!bg-transparent !p-0 !h-6 !text-orange-500 ${
                                details?.companyInfo?.goal
                                  ? "!border-none"
                                  : "!border-orange-500 !p-2"
                              }`}
                              buttonHasLink={false}
                              buttonType="button"
                              buttonIconPosition={left}
                              buttonIcon={
                                details?.companyInfo?.goal
                                  ? "fa-regular fa-pen-to-square"
                                  : "fa-regular fa-plus"
                              }
                              buttonLabelClasses="!text-xs !font-medium"
                              buttonLabel={!details?.companyInfo?.goal && "Add"}
                              buttonFunction={() =>
                                setMissionModal({
                                  isOpen: true,
                                  data: details?.companyInfo,
                                })
                              }
                            />
                            <Tooltip
                              id="edit-company-mission"
                              place="top"
                              className="!text-slate-500 !text-sm !bg-slate-950 !bg-opacity-100 z-100"
                              render={() => (
                                <div className="text-white">
                                  {details?.companyInfo?.goal
                                    ? "Click to edit company mission."
                                    : "Click to add company mission."}
                                </div>
                              )}
                            />
                          </div>
                        )}
                      </div>
                      {details?.companyInfo ? (
                        <div className="text-slate-500 text-sm leading-normal break-words">
                          {details?.companyInfo?.description ? (
                            <>
                              {details.companyInfo.description.length > 300 ? (
                                <>
                                  {isExpanded
                                    ? details.companyInfo.description
                                    : `${details.companyInfo.description.substring(
                                        0,
                                        300
                                      )}...`}
                                  <button
                                    onClick={toggleReadMore}
                                    className="text-orange-500 ml-2"
                                  >
                                    {isExpanded ? "Show Less" : "Read More"}
                                  </button>
                                </>
                              ) : (
                                details.companyInfo.description
                              )}
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      ) : (
                        <div className="overflow-auto  p-4 flex flex-col items-center justify-center">
                          <div className="text-orange-100 text-4xl mb-4">
                            <i className="fa-regular fa-star"></i>
                          </div>
                          <div className="text-center text-slate-600 text-md">
                            What is your company mission statement?Add it here
                            to keep your team aligned with a sense of purpose
                            and direction.
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="relative space-y-3">
                    <div className="text-gray-800 font-bold text-lg">
                      {userRole?.code !== "COMPANY-MANAGER"
                        ? "Administrator Details"
                        : "Manager Details"}
                    </div>
                    <div className="relative bg-white rounded-lg px-5 py-4 space-y-4 shadow border border-gray-100">
                      <div className="flex flex-col md:flex-row items-center md:items-start gap-5">
                        <div className="w-24 aspect-square overflow-hidden flex rounded-lg flex-shrink-0">
                          <Image
                            src={profile?.image?.url || noUser}
                            effect={"blur"}
                            className={"object-cover"}
                          />
                        </div>

                        <div className="flex-shrink flex-grow flex">
                          <div
                            className="space-y-2"
                            data-tooltip-id="company-administrator-section"
                          >
                            <div className="text-lg text-gray-800 font-semibold">
                              {profile?.firstName + " " + profile?.lastName}
                            </div>
                            <div className="relative space-y-1">
                              <div className="flex items-center gap-2">
                                <div className="flex-shrink-0 text-slate-500 text-xs border-r border-r-slate-300 pr-1">
                                  <i class="fa-regular fa-fw fa-envelope"></i>
                                </div>
                                <div className="flex-grow flex-shrink text-sm text-slate-500">
                                  {profile?.email}
                                </div>
                              </div>
                              <div className="flex items-center gap-2">
                                <div className="flex-shrink-0 text-slate-500 text-xs border-r border-r-slate-300 pr-1">
                                  <i class="fa-regular fa-fw fa-phone"></i>
                                </div>
                                <div className="flex-grow flex-shrink text-sm text-slate-500">
                                  {profile?.phone
                                    ? `+${formatPhoneNumber(profile?.phone)}`
                                    : "N/A"}
                                </div>
                              </div>

                              <Tooltip
                                id="company-administrator-section"
                                place="right"
                                className="!text-slate-500 !text-sm !bg-slate-950 !bg-opacity-100 z-100"
                                render={() => (
                                  <div className="text-white">
                                    <div>
                                      Name:{" "}
                                      {profile?.firstName +
                                        " " +
                                        profile?.lastName}
                                    </div>
                                    <div>Email: {profile?.email}</div>
                                    <div>
                                      Phone:{" "}
                                      {profile?.phone
                                        ? `+${formatPhoneNumber(
                                            profile?.phone
                                          )}`
                                        : "N/A"}
                                    </div>
                                  </div>
                                )}
                              />
                            </div>
                          </div>
                        </div>
                        <div
                          className="flex-shrink-0 flex items-end"
                          data-tooltip-id="edit-company-adminitrator"
                        >
                          <Button
                            buttonClasses={
                              "!bg-transparent !p-0 !h-6 !border-none !text-orange-500"
                            }
                            buttonHasLink={false}
                            buttonType={"button"}
                            buttonIconPosition={left}
                            buttonIcon={"fa-regular fa-pen-to-square"}
                            buttonLabelClasses={"!text-xs !font-medium"}
                            buttonFunction={() =>
                              setProfileModal({ isOpen: true })
                            }
                          />
                          <Tooltip
                            id="edit-company-adminitrator"
                            place="top"
                            className="!text-slate-500 !text-sm !bg-slate-950 !bg-opacity-100 z-100"
                            render={() => (
                              <div className="text-white">
                                Click to edit{" "}
                                {userRole?.code !== "COMPANY-MANAGER"
                                  ? "administrator"
                                  : "manager"}{" "}
                                details.
                              </div>
                            )}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-span-2 space-y-2 py-8 ">
                  <div className="flex justify-between items-center">
                    <div className="text-gray-800 font-bold text-xl">
                      {<i class="fa-sharp fa-solid fa-link"></i>} Links
                    </div>
                    {userRole?.code !== "COMPANY-MANAGER" && (
                      <div data-tooltip-id="edit-company-links">
                        <Button
                          buttonClasses={`!bg-transparent !p-0 !h-6 !text-orange-500 ${
                            data?.length > 0
                              ? "!border-none"
                              : "!border-orange-500 !p-2"
                          }`}
                          buttonHasLink={false}
                          buttonType={"button"}
                          buttonIconPosition={left}
                          buttonIcon={
                            data?.length > 0
                              ? "fa-regular fa-pen-to-square"
                              : "fa-regular fa-plus"
                          }
                          buttonLabelClasses={"!text-xs !font-medium"}
                          buttonLabel={!data?.length > 0 && "Add"}
                          buttonFunction={() =>
                            setWebsiteLinkModal({ isOpen: true, data: data })
                          }
                        />
                        <Tooltip
                          id="edit-company-links"
                          place="top"
                          className="!text-slate-500 !text-sm !bg-slate-950 !bg-opacity-100 z-100"
                          render={() => (
                            <div className="text-white">
                              {data?.length
                                ? "Click to edit links."
                                : "Click to add links."}
                            </div>
                          )}
                        />
                      </div>
                    )}
                  </div>
                  <div className="w-full border  rounded-lg h-auto overflow-y-scroll scrollbar">
                    <div className="overflow-auto divide-y divide-slate-200">
                      {data?.length > 0 ? (
                        data?.map((item, index) => (
                          <div className="relative" key={index}>
                            <Links data={item} />
                          </div>
                        ))
                      ) : (
                        <div className="overflow-auto  p-4 flex flex-col items-center justify-center">
                          <div className="text-orange-100 text-4xl mb-4">
                            <i className="fa-sharp fa-solid fa-link"></i>
                          </div>
                          <div className="text-center text-slate-600 text-md">
                            Share links to employee handbooks, your HR system,
                            hiring dashboard, or anything else that sounds
                            useful.
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <CompanyManage
            companyDetailsModal={companyDetailsModal}
            setCompanyDetailsModal={setCompanyDetailsModal}
            setDetails={setDetails}
          />
          <OurMissionManage
            ourMissionModal={ourMissionModal}
            setMissionModal={setMissionModal}
            setDetails={setDetails}
          />
          <WebsiteLinkManage
            websiteLinkModal={websiteLinkModal}
            setWebsiteLinkModal={setWebsiteLinkModal}
            setDetails={setDetails}
          />
          <MyProfile
            profileModal={profileModal}
            setProfileModal={setProfileModal}
          />
        </div>
      ) : (
        <FrontCompanySkeleton />
      )}
    </>
  );
};

export default CompanyDetails;

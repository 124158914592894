import React, { useEffect, useState } from "react";
import noImage from "../../../assets/images/no-results-bg.2d2c6ee3.png";
import Modal from "../../../components/elements/Modal";
import ImageUploader from "../../form/ImageUploader";
import Button from "../../form/Button";
import Input from "../../form/Input";
import useForm from "../../../hooks/useForm";
import { formValidate } from "../../../helpers/formValidate";
import { toast } from "react-toastify";
import { companyUpdate } from "../../../services/frontService/CompanyService";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setProfile } from "../../../redux/slice/profileSlice,";

const CompanyManage = ({
  companyDetailsModal,
  setCompanyDetailsModal = () => {},
  setDetails = () => {},
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const validation = {
    companyName: { required: true, message: "Please enter company name!" },
  };
  const profile = useSelector((state) => state?.profile?.data);

  const { values, handleChange, handleSubmit, errors, setFieldsValue } =
    useForm({}, validation);
  const [image, setImage] = useState(null);

  const data = companyDetailsModal?.data;

  useEffect(() => {
    if (data) {
      setFieldsValue({
        companyName: data?.companyCredential?.name || "",
        email: data?.companyCredential?.email || "",
      });
      if (data?.image?.url) {
        setImage(data?.image?.url);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const onSubmit = (values) => {
    setLoading(true);
    companyUpdate({
      companyName: values?.companyName,
      image: image || "null",
    }).then((res) => {
      if (res?.status === 200) {
        setDetails(res?.data);
        toast.success(res?.message);
        setCompanyDetailsModal({ isOpen: false });
        setLoading(false);
        dispatch(
          setProfile({
            ...profile,
            companyDetails: {
              ...profile?.companyDetails,
              companyName: res?.data?.companyCredential?.name,
            },
          })
        );
      } else {
        toast.error(res?.data?.message);
        setCompanyDetailsModal({ isOpen: false });
        setLoading(false);
      }
    });
  };
  return (
    <div>
      <Modal
        title="Edit Company Details"
        size="xl"
        open={companyDetailsModal.isOpen}
        onClose={() => setCompanyDetailsModal({ isOpen: false })}
        modaltitleClasses={"!text-slate-200 text-[15px]"}
        headerClass={"!bg-black !rounded-t-lg !text-white"}
        childrenClasses={"!rounded-b-lg"}
      >
        <form onSubmit={(event) => handleSubmit(event, onSubmit)}
          className="grid grid-cols-12 gap-4 p-6 lg:pl-0 bg-white rounded-lg shadow-md"
        >
          <div className="image bg-white col-span-12 lg:col-span-5 border-0 lg:border-r border-slate-200 h-auto">
            <h2 className="text-lg font-semibold text-slate-700 text-center border-b border-slate-200 mb-2">Company logo</h2>
            <div className="relative">
              <ImageUploader
                label=""
                image={image}
                altImage={noImage}
                isMultiple={false}
                setImage={setImage}
                // isCameraIcon={true}
                buttonLabelClasses="!text-xs"
                buttonIcon="fa-upload text-xs"
                labelClasses="!text-sm font-bold"
                buttonClasses="!px-1 !py-2 bg-blue-500 text-white rounded-lg hover:bg-orange-600 mx-auto !h-auto !w-[94%]"
                inputClasses="w-32 h-32 hover:bg-slate-200 rounded-full border-2 border-gray-300 overflow-hidden"
              />
            </div>
          </div>

          <div className="input bg-white col-span-12 lg:col-span-7 space-y-3 flex flex-col">
            <div className="relative">
              <Input
                isInputGroup={true}
                inputGroupIcon="fa-light fa-users text-gray-500"
                inputGroupPosition="left"
                label="Company Name"
                inputPlaceholder="Enter company name"
                inputClasses="pl-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-500"
                labelClasses="text-sm font-medium text-gray-700"
                inputType="text"
                isValidate={true}
                value={values.companyName}
                onChange={handleChange}
                inputName="companyName"
                charLimit={50}
                {...formValidate(errors, "companyName")}
              />
            </div>

            <div className="relative">
              <Input
                isInputGroup={true}
                inputGroupIcon="fa-light fa-envelope text-gray-500"
                inputGroupPosition="left"
                label="Company Email"
                inputPlaceholder="Enter company email"
                inputClasses="pl-3 border border-gray-300 rounded-lg bg-gray-100 text-gray-500"
                labelClasses="text-sm font-medium text-gray-700"
                inputType="email"
                isDisabled={true}
                isValidate={true}
                value={values.email}
                onChange={handleChange}
                inputName="email"
              />
            </div>

            <div className="relative flex justify-end items-end gap-2 !mt-auto">
              <Button
                buttonLabel="Cancel"
                buttonIcon="fa-regular fa-xmark"
                buttonIconPosition="left"
                buttonClasses="!py-2 !px-2 border border-orange-500 !text-orange-500 rounded-lg !bg-transparent !h-auto"
                buttonLabelClasses={"!text-sm"}
                buttonFunction={() => setCompanyDetailsModal({ isOpen: false })}
              />
              <Button
                loading={loading}
                buttonLabel={loading ? "Saving..." : "Save"}
                buttonIcon="fa-light fa-floppy-disk"
                buttonIconPosition="left"
                buttonType="submit"
                buttonLabelClasses={"!text-sm"}
                buttonClasses={`!py-2 !px-2 rounded-lg text-white !h-auto ${loading ? "bg-gray-400 cursor-not-allowed" : "bg-orange-500 hover:bg-orange-600"
                  }`}
                isDisable={loading}
              />
            </div>
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default CompanyManage;

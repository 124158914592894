import React, { useState } from "react";
import siteLogo from "../../assets/images/logo2.png";
import Input from "../../components/form/Input";
import Button from "../../components/form/Button";
import { formValidate } from "../../helpers/formValidate";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Validation } from "../../helpers/Validation";
import useForm from "../../hooks/useForm";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { acceptUser } from "../../services/ProfileService";

const validation = {
  password: { required: true, message: "Password is required" },
  confirmPassword: { required: true, message: "Comfirm password id required" },
};

export default function AcceptEmployee() {
  const { values, handleChange, handleSubmit, errors, setFieldsValue } =
    useForm({}, validation);
  const [searchParams] = useSearchParams();
  let token = searchParams.get("token");

  const navigate = useNavigate();
  const settings = useSelector((state) => state?.settings?.data);

  const [loaded, setLoaded] = useState(false);

  const validatePassword = (password) => {
    const errors = [];
    if (password.length < 8) {
      errors.push("At least 8 characters long.");
    }
    if (!/(?=.*\d)/.test(password)) {
      errors.push("At least one digit.");
    }
    if (!/(?=.*[a-z])/.test(password)) {
      errors.push("At least one lowercase letter.");
    }
    if (!/(?=.*[A-Z])/.test(password)) {
      errors.push("At least one uppercase letter.");
    }
    if (!/(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?])/.test(password)) {
      errors.push("At least one special character (e.g., @, #, $, etc.).");
    }
    if (errors.length > 0) {
      errors.unshift("Password requirements:");
    }

    return errors;
  };

  const onSubmit = async (values) => {
    setLoaded(true);
    try {
      const passwordErrors = values.password
        ? validatePassword(values.password)
        : [];
      if (passwordErrors.length > 0) {
        toast.error(passwordErrors.join(" "));
        setLoaded(false);
        return;
      }

      if (
        values.password &&
        !Validation("confirmPassword", values.confirmPassword, values.password)
      ) {
        toast.error("Confirm password and password do not match");
        setLoaded(false);
        return;
      }

      if (!token) {
        toast.warn("Somethingh went wrong. Please try again");
        setLoaded(false);
        return;
      }

      const resp = await acceptUser({
        token,
        password: values?.password,
        status: 1,
      });

      if (resp?.status === 200) {
        toast.success(resp?.message);
        localStorage.setItem("auth_token", resp?.data?.accessToken);
        localStorage.setItem("role", "COMPANY-MANAGER");
        setTimeout(() => {
          navigate("/");
        }, 2000);
        setLoaded(false);
      } else {
        toast.warn(resp?.data?.message);
        setLoaded(false);
      }
    } catch (error) {
      setLoaded(false);
      toast.error(error);
    }
  };

  return (
    <div className="w-full min-h-screen bg-slate-50 flex items-center justify-center py-3 px-5">
      <div className="w-full max-w-sm  bg-white py-10 shadow-md rounded-2xl border border-orange-700 ">
        <div className="flex min-h-full flex-1 flex-col justify-center px-2 py-4 lg:px-2">
          <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <img
              className="mx-auto h-20 w-auto"
              src={settings?.favicon?.url || siteLogo}
              alt="Your Company"
            />

            <h2 className="mt-1 text-center text-base font-semibold leading-9 tracking-tight text-black">
              Please Set Your Password
            </h2>
          </div>

          <form
            onSubmit={(event) => handleSubmit(event, onSubmit)}
            className="flex flex-col items-center justify-center px-3"
          >
            <div className="w-full mt-2">
              <Input
                label={"Password"}
                inputPlaceholder={"Enter your password"}
                labelClasses={"!text-xs"}
                inputType={"password"}
                value={values.password}
                inputName={"password"}
                onChange={handleChange}
                {...formValidate(errors, "password")}
              />
            </div>

            <div className="w-full mt-2">
              <Input
                label={"Confirm Password"}
                inputPlaceholder={"Confirm your password"}
                labelClasses={"!text-xs"}
                inputType={"password"}
                value={values.confirmPassword}
                inputName={"confirmPassword"}
                onChange={handleChange}
                {...formValidate(errors, "confirmPassword")}
              />
            </div>

            <div className="w-full mt-4">
              <Button
                loading={loaded}
                buttonHasLink={false}
                buttonType={"submit"}
                buttonLabel={"Set Password"}
                buttonIconPosition={"left"}
                buttonIcon={"fa-light fa-right-to-bracket"}
                buttonClasses={
                  "w-full !text-white !bg-orange-500 !hover:bg-white !hover:text-orange-500 focus-visible:outline justify-center rounded-md border border-orange-500"
                }
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

import React, { useState } from "react";
import Modal from "../../elements/Modal";
import Image from "../../elements/Image";
import Button from "../../form/Button";
import duplicate from "../../../assets/images/duplicate.png";
import replace from "../../../assets/images/replace.png";

export default function ChartEditEmployee({
  assignedEmployee,
  editNode,
  customMessage,
  loading = false,
  onClose = () => {},
  title = "Employee",
  setAssignedEmployee = () => {},
  setEmpDetails = () => {},
  setSidePanelOpen = () => {},
}) {
  const [status, setStatus] = useState("replace");
  const handleImageClick = (image) => {
    setStatus(image);
  };
  const data = assignedEmployee?.data;
  const handleSubmit = async () => {
    const res = await editNode(
      {
        userId: data?.userId?._id,
        ...(data?.positionId && { positionId: data.positionId }),
        ...(data?.departmentId?.length > 0 && {
          departmentId: data.departmentId,
        }),
        userAssignType: status,
      },
      status
    );

    if (res?.status === 200) {
      setAssignedEmployee((pre) => ({ ...pre, isOpen: false }));
      setSidePanelOpen({ isOpen: false });
      setStatus("replace");
    } else {
      setEmpDetails((pre) => ({ ...pre, loading: false }));
      setAssignedEmployee((pre) => ({ ...pre, isOpen: false }));
      setStatus("replace");
    }
  };

  return (
    <>
      <Modal
        open={assignedEmployee?.isOpen}
        onClose={() => {
          onClose();
          setAssignedEmployee((pre) => ({ ...pre, isOpen: false }));
        }}
        title={title}
        size="xl"
        modaltitleClasses={"!text-slate-200 text-lg"}
        headerClass={"!bg-black !rounded-t-lg !text-white"}
        childrenClasses={"!rounded-b-lg"}
      >
        <div className="p-3">
          <div className="text-center text-orange-600 font-semibold text">
            {customMessage ? (
              customMessage
            ) : (
              <>
                This employee already exists on the OrgChart. How would you like
                to proceed?
              </>
            )}
          </div>

          <div className="flex justify-center gap-8 mt-3 border-b border-gray-300 py-6">
            <div
              onClick={() => handleImageClick("replace")}
              className={`cursor-pointer border-2 rounded-xl overflow-hidden p-2 transition-all duration-300 transform ${
                status === "replace"
                  ? "border-orange-500 shadow-lg scale-105"
                  : "border-gray-300"
              }`}
            >
              <Image
                src={replace}
                alt="Single Delete"
                className="object-cover w-full h-full rounded-xl"
              />
            </div>
            <div
              onClick={() => handleImageClick("duplicate")}
              className={`cursor-pointer border-2 rounded-xl overflow-hidden p-2 transition-all duration-300 transform ${
                status === "duplicate"
                  ? "border-orange-500 shadow-lg scale-105"
                  : "border-gray-300"
              }`}
            >
              <Image
                src={duplicate}
                alt="Multiple Delete"
                className="object-cover w-full h-full rounded-xl"
              />
            </div>
          </div>

          <div className="flex gap-4 justify-end mt-5">
            <Button
              buttonIconPosition={"left"}
              buttonIcon={"fa-regular fa-close"}
              buttonLabel={"Cancel"}
              buttonFunction={() => {
                onClose();
                setStatus("replace");
                setAssignedEmployee((pre) => ({ ...pre, isOpen: false }));
              }}
              buttonClasses={"bg-slate-50 border-red-500 !text-slate-500"}
            />
            <Button
              buttonFunction={handleSubmit}
              buttonIconPosition={"left"}
              buttonIcon={
                loading
                  ? "fa-duotone fa-spinner-third animate-spin"
                  : "fa-light fa-floppy-disk"
              }
              buttonLabel={loading ? "Saving..." : "Save"}
              buttonClasses={"bg-orange-500 border-orange-500"}
              loading={loading}
            />
          </div>
        </div>
      </Modal>
    </>
  );
}

import React from "react";
import Button from "../form/Button";
import Modal from "../elements/Modal";

const DeleteModal = ({
  open = false,
  onClose = () => {},
  deleteHandler = () => {},
  title = "Delete Record",
  subTitle = "You are trying to delete the record",
  loading = false,
  customMessage,
  deleteButtonLabel = "Delete",
  deleteButtonIcon,
  titleIcon,
}) => {
  return (
    <Modal
      open={open}
      onClose={() => onClose()}
      title={title}
      size="sm"
      modaltitleClasses={"!text-slate-200 text-[15px]"}
      headerClass={"!bg-black !rounded-t-lg !text-white"}
      childrenClasses={"!rounded-b-lg"}
      titleIcon={titleIcon}
    >
      <div className="text-center mb-3 text-slate-500">
        {customMessage ? (
          customMessage
        ) : (
          <>
            {subTitle},
            <br /> click &quot;{deleteButtonLabel}&quot; below.
          </>
        )}
      </div>
      <div className="flex gap-2 justify-center">
        <Button
          buttonIconPosition={"left"}
          buttonIcon={"fa-regular fa-close !text-sm"}
          buttonLabel={"Cancel"}
          buttonFunction={() => onClose()}
          buttonClasses="!py-2 !px-2 border border-orange-500 !text-orange-500 rounded-lg !bg-transparent !h-auto"
          buttonLabelClasses={"!text-sm"}
        />
        <Button
          buttonFunction={deleteHandler}
          buttonIconPosition={"left"}
          isDisable={loading}
          isLoaderDisabled={true}
          buttonIcon={
            loading
              ? "fa-duotone fa-spinner-third animate-spin"
              : deleteButtonIcon || "fa-regular fa-trash-can !text-sm"
          }
          buttonLabel={deleteButtonLabel || "Delete"}
          buttonClasses={"!py-2 !px-2 rounded-lg text-white !h-auto !bg-red-600 hover:!bg-red-700 transition-all duration-300 !border-0"}
          buttonLabelClasses={"!text-sm"}
        />
      </div>
    </Modal>
  );
};

export default DeleteModal;

import { useCallback, useEffect, useState } from "react";
import { formValidate } from "../../../helpers/formValidate";
import useForm from "../../../hooks/useForm";
import Modal from "../../elements/Modal";
import SelectDropdownSearch from "../../form/SelectDropdownSearch";
import { getEmployeeList } from "../../../services/frontService/EmployeeService";
import noEmpImg from "../../../assets/images/noEmpImg.svg";
import { useDebounce } from "../../../helpers/useDebounce";
import DatePickerInput from "../../form/DatePicker";
import TextAreaAutoSize from "../../form/TextareaAutoSize";
import Input from "../../form/Input";
import Button from "../../form/Button";
import {
  eventAdd,
  eventEdit,
} from "../../../services/frontService/EventService";
import { toast } from "react-toastify";
import utc from "dayjs/plugin/utc";
import dayjs from "dayjs";

dayjs.extend(utc);

export default function EventManage({
  date,
  eventModal,
  setEventList = () => {},
  setEventModal = () => {},
}) {
  const [startDate, setStartDate] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [endDate, setEndDate] = useState(new Date());
  const [empList, setEmpList] = useState({ loading: false, data: [] });
  const [keywords, setKeywords] = useState({
    empKeyword: "",
  });
  const empKeyword = useDebounce(keywords.empKeyword, 400);

  const data = eventModal?.data;

  let validation = {
    type: { required: true, message: "Please choose a reason." },
  };

  const { values, handleChange, handleSubmit, errors, setFieldsValue } =
    useForm({}, validation);

  if (values?.type === "holiday" || values?.type === "announcement") {
    validation.title = { required: true, message: "Please give a title." };
  } else {
    validation.forUser = { required: true, message: "Please choose a person." };
  }

  useEffect(() => {
    if (data) {
      setFieldsValue({
        forUser: data?.forUserDetail?._id,
        description: data?.description,
        title: data?.title,
        type: data?.type,
      });
      setStartDate(data?.startDate || new Date());
      setEndDate(data?.endDate || new Date());
    }
  }, [data]);

  const loadempList = useCallback(() => {
    setEmpList({ loading: true, data: [] });
    getEmployeeList({ keyword: empKeyword, limit: 20, isAssigned: true }).then(
      (res) => {
        setEmpList({
          loading: false,
          data: res?.status === 200 ? res?.docs : [],
        });
      }
    );
  }, [empKeyword]);

  useEffect(() => loadempList(), [loadempList]);

  const onSubmit = async (values) => {
    setLoading(true);
    if (endDate < startDate) {
      toast.error("The end date must be greater than start date.");
      setLoading(false);
      return;
    }

    let payload = {
      ...values,
      startDate: dayjs(startDate).utc().startOf("day").toISOString(),
      endDate: dayjs(endDate).utc().startOf("day").toISOString(),
    };

    if (values?.type == "holiday" || values?.type === "announcement") {
      delete payload.forUser;
    }

    const res = data?._id
      ? await eventEdit({
          ...payload,
          eventId: data?._id,
        })
      : await eventAdd(payload);

    setLoading(false);

    if (res?.status === 200) {
      toast.success(res?.message);

      if (data?._id) {
        setEventList((prev) => ({
          ...prev,
          data: prev?.data.map((item) =>
            item?._id === res?.data?._id ? res?.data : item
          ),
        }));
      }

      if (date.toDateString() === new Date().toDateString()) {
        setEventList((prev) => ({
          ...prev,
          data: [
            res?.data,
            ...prev?.data?.filter((item) => item?._id !== res?.data?._id),
          ],
        }));
      }

      setEventModal((pre) => ({ ...pre, isOpen: false }));
    } else {
      toast.error(res?.data?.message);
    }
  };
  return (
    <Modal
      title={"Add Events"}
      size="xl"
      open={eventModal?.isOpen}
      onClose={() => {
        setEventModal((pre) => ({ ...pre, isOpen: false }));
      }}
      modaltitleClasses={"!text-slate-200 text-[15px]"}
      headerClass={"!bg-black !rounded-t-lg !text-white"}
      childrenClasses={"!rounded-b-lg"}
    >
      <form
        onSubmit={(event) => handleSubmit(event, onSubmit)}
        className="grid grid-cols-2 gap-2"
      >
        <div className="col-span-1">
          <SelectDropdownSearch
            label={"Reason"}
            placeholder={"Choose a reason"}
            isSearch={true}
            selectName={"type"}
            value={values?.type}
            onChange={handleChange}
            isValidate={true}
            dropdownData={[
              {
                name: "Birthday",
                _id: "birthDay",
                icon: "fa-regular fa-cake-candles text-red-500",
              },
              {
                name: "Marriage",
                _id: "marriage",
                icon: "fa-regular fa-ring-diamond text-blue-500",
              },
              {
                name: "Holiday",
                _id: "holiday",
                icon: "fa-regular fa-umbrella-beach text-green-500",
              },
              {
                name: "Leave",
                _id: "leave",
                icon: "fa-regular fa-bed text-yellow-500",
              },
              {
                name: "Announcement",
                _id: "announcement",
                icon: "fa-regular fa-bullhorn text-purple-500",
              },
            ]}
            {...formValidate(errors, "type")}
          />
        </div>

        <div className="col-span-1">
          {!(values?.type === "holiday" || values?.type === "announcement") && (
            <SelectDropdownSearch
              label={"Whom is this status for ?"}
              placeholder={"Choose a person"}
              isSearch={true}
              selectName={"forUser"}
              value={values?.forUser}
              onChange={handleChange}
              isValidate={true}
              dropdownClass={"!min-h-44 !h-0"}
              dropdowndataclass={"!w-8 !h-8"}
              dropdowntitleclass={"!text-xs"}
              dropdowntickclass={"!bg-[#fff] !w-[20px] !h-[20px] !rounded-[50px] !top-[12px] !text-center !pl-[5px] !left-[6px]"}
              dropdownData={empList?.data?.map((item) => ({
                name: item?.firstName + " " + item?.lastName,
                _id: item?._id,
                url: item?.image?.url ? item?.image?.url : noEmpImg,
                subItem: item?.positionDetails?.title,
              }))}
              onSearch={(val) => {
                setKeywords((pre) => ({ ...pre, empKeyword: val }));
              }}
              {...formValidate(errors, "forUser")}
            />
          )}
        </div>
        <div className="col-span-1 texye">
          <DatePickerInput
            label={"From"}
            type="input"
            startDate={startDate}
            setStartDate={setStartDate}
            isReqired={true}
            dateFormat={"yyyy/MM/dd"}
            {...formValidate(errors, "startDate")}
          />
        </div>
        <div className="col-span-1">
          <DatePickerInput
            label={"To"}
            type="input"
            startDate={endDate}
            setStartDate={setEndDate}
            isReqired={true}
            dateFormat={"yyyy/MM/dd"}
            {...formValidate(errors, "endDate")}
          />
        </div>
        <div className="col-span-2">
          <Input
            label={"Title"}
            inputPlaceholder={"Enter a title of status"}
            inputClasses={"pl-2"}
            labelClasses={"!text-sm !font-medium"}
            inputType={"text"}
            value={values.title}
            onChange={handleChange}
            inputName={"title"}
            isValidate={
              values?.type == "holiday" || values?.type === "announcement"
                ? true
                : false
            }
            charLimit={200}
            {...formValidate(errors, "title")}
          />
        </div>
        <div className="col-span-2">
          <TextAreaAutoSize
            label={"Description"}
            inputValue={values?.description}
            inputName={"description"}
            inputPlaceholder="Anything you would like to add"
            onChange={handleChange}
          />
        </div>
        <div className={`col-span-2 flex items-center justify-end`}>
          <Button
            loading={loading}
            buttonLabel={
              data?._id
                ? loading
                  ? "Saving..."
                  : "Save"
                : loading
                ? "Adding..."
                : "Add"
            }
            buttonIcon={
              data?._id
                ? "fa-light fa-floppy-disk"
                : "fa-regular fa-calendar-plus"
            }
            isDisable={loading ? true : false}
            buttonIconPosition={"left"}
            buttonType="submit"
            buttonClasses={"!py-2 !px-2 rounded-lg text-white !h-auto !bg-orange-500 hover:!bg-orange-600"}
          />
        </div>
      </form>
    </Modal>
  );
}

import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import unAuthImage from "../assets/images/unauthorized.png";
import logo from "../assets/images/logoddd.png";
import axios from "axios";
import AuthLoader from "../components/common/AuthLoader";
import { API_BASE_URL } from "../components/constant/ApiConstant";

const Authentication = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [responseData, setResponseData] = useState({
    status: null,
    message: "",
  });
  const windowUrl = window.location.search;
  const params = new URLSearchParams(windowUrl);
  const token = params.get("key");
  const loaderStyles = {
    width: "fit-content",
    fontWeight: "bold",
    fontFamily: "monospace",
    fontSize: "26px",
    color: "#0000",
    background:
      "linear-gradient(90deg, #f17b12 calc(50% + 0.5ch), #000 0) right/calc(200% + 1ch) 100%",
    WebkitBackgroundClip: "text",
    backgroundClip: "text",
    animation: "l7 2s infinite steps(11)",
  };

  const keyframes = `
        @keyframes l7 {
            to { background-position: left; }
        }
    `;

  const companyCreateVerify = useCallback(async () => {
    setLoading(true);
    const data = {};
    const baseUrl = API_BASE_URL;
    try {
      const result = await axios.post(`${baseUrl}authentication/login`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(result, "resultresultresultresult");
      if (result.status === 200) {
        setLoading(false);
        // dispatch(authLogin(result.data));
        localStorage.setItem("auth_token", result?.accessToken);
        localStorage.setItem(
          "role",
          result?.roleData?.find(
            (item) => item?.code === "COMPANY-ADMINISTRATOR"
          )
            ? "COMPANY-ADMINISTRATOR"
            : result?.roleData?.find(
                (item) => item?.code === "COMPANY-MANAGER"
              )
            ? "COMPANY-MANAGER"
            : ""
        );
        return navigate("/dashboard");
      } else {
        setLoading(false);
        setResponseData({ status: 401, message: result.message });
      }
    } catch (err) {
      console.log(err.message);
      setLoading(false);
      setResponseData({ status: 401, message: err.message });
    }
  }, [token]);

  useEffect(() => {
    companyCreateVerify();
  }, [companyCreateVerify]);

  return (
    <>
      <div className="relative min-h-screen flex items-center justify-center bg-orange-500">
        <div className="absolute inset-0 bg-orange-50 clip-diagonal"></div>

        <div className="relative bg-white rounded-xl shadow-lg p-6 text-center w-[500px]">
          <div className="h-10 w-full flex justify-center object-contain mb-5">
            <img src={logo} alt="logo" className="w-26 h-26" />
          </div>
          {loading && (
            <>
              <div className="flex justify-center items-center mt-8">
                <AuthLoader />
              </div>
              <div className="flex justify-center items-center mt-5">
                <style>{keyframes}</style>
                <div style={loaderStyles}>Authenticating, Please Wait...</div>
              </div>
            </>
          )}
          {!loading && responseData?.status === 401 && (
            <>
              <div className="flex items-center justify-center">
                <div className="inset-0 flex items-center justify-center text-[80px] text-orange-600/30 font-extrabold drop-shadow-md pointer-events-none">
                  401
                </div>
                <div className="w-20 h-16 flex items-center justify-center">
                  <img
                    src={unAuthImage}
                    alt="Unauthorized"
                    className="w-20 h-20"
                  />
                </div>
              </div>

              <div className="text-xl mt-1 font-bold text-rose-600">
                {responseData?.message ||
                  "You are not authorized to view this page."}
              </div>

              <button
                onClick={() => navigate("/")}
                className="mt-2 px-8 py-2 bg-gradient-to-r from-orange-400 to-orange-600 text-white rounded-full hover:scale-105 transition-transform shadow-md hover:shadow-lg"
              >
                Go Home
              </button>
            </>
          )}
          {!loading && responseData?.status === 404 && (
            <>
              <div className="flex items-center justify-center">
                <div className="inset-0 flex items-center justify-center text-[80px] text-orange-600/30 font-extrabold drop-shadow-md pointer-events-none">
                  401
                </div>
                <div className="w-20 h-16 flex items-center justify-center">
                  <img
                    src={unAuthImage}
                    alt="Unauthorized"
                    className="w-20 h-20"
                  />
                </div>
              </div>

              <div className="text-xl mt-1 font-bold text-rose-600">
                {responseData?.message ||
                  "You are not authorized to view this page."}
              </div>

              <button
                onClick={() => navigate("/")}
                className="mt-2 px-8 py-2 bg-gradient-to-r from-orange-400 to-orange-600 text-white rounded-full hover:scale-105 transition-transform shadow-md hover:shadow-lg"
              >
                Go Home
              </button>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Authentication;

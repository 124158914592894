import { Link, useParams } from "react-router-dom";
import Image from "../../../elements/Image";
import { employeeDetails } from "../../../../services/frontService/EmployeeService";
import { useCallback, useEffect, useState } from "react";
import EmployeeDetailsLoader from "../../../loaders/EmployeeDetailsLoader";
import NoEmpImg from "../../../../assets/images/noEmpImg.svg";
import { setTitle } from "../../../../helpers/MetaTag";
import Directory from "../../../../pages/front/directory/Directory";
import EmployeeEdit from "../../../modals/front/EmployeeEdit";
import Button from "../../../form/Button";
import { formatPhoneNumber } from "../../../../helpers/formatPhoneNumber";

export default function EmployeeDetails() {
  setTitle("ORG Chart | Employees");
  const { id } = useParams();
  const [employee, setEmployee] = useState({ loading: false, data: {} });
  const [editmodalOpen, setEditModalOpen] = useState({ isOpen: false });
  const role = employee?.data?.roleDetails?.find((item) => item);

  const loadDetails = useCallback(() => {
    if (!id) return;
    setEmployee({ loading: true, data: {} });

    employeeDetails({ id }).then((res) => {
      if (res?.status === 200) {
        setEmployee({
          loading: false,
          data: res?.data,
        });
      } else {
        setEmployee({ loading: false, data: {} });
      }
    });
  }, [id]);

  useEffect(() => loadDetails(), [loadDetails]);

  return (
    <Directory
      name={
        !employee?.loading
          ? `${employee?.data?.firstName} ${employee?.data?.lastName}`
          : ""
      }
    >
      {employee?.loading ? (
        <EmployeeDetailsLoader />
      ) : (
        <div className="grid grid-cols-12 gap-4">
            <div className="col-span-12 lg:col-span-5 3xl:col-span-4 relative w-full bg-white shadow-md rounded-xl border border-gray-200 space-y-4 md:space-y-8 overflow-hidden bg-gradient-to-b from-orange-200 to-white md:bg-gradient-to-r md:from-orange-200 md:to-white lg:from-orange-400 lg:to-orange-100 1xl:from-orange-200 1xl:to-white">
            <div className="flex flex-col md:flex-row lg:flex-col 1xl:flex-row pb-7 lg:pb-0 1xl:py-8 px-7 lg:px-0 1xl:px-8 !pt-[20px] 1xl:!pt-[35px] gap-6 2xl:gap-9 group relative h-[100%] 1xl:h-auto">
                <div className="w-28 md:w-32 xl:w-36 3xl:w-40 h-28 md:h-32 xl:h-36 3xl:h-40 rounded-full border-2 shadow-lg md:mb-0 flex-shrink-0 bg-white mx-auto 1xl:m-0">
                <Image
                  src={employee?.data?.image?.url || NoEmpImg}
                  alt={`${employee?.data?.firstName} ${employee?.data?.lastName}`}
                  className="object-cover rounded-full border-orange-300 object-top"
                />
              </div>
                <div className="!mt-0 md:!mt-5 lg:!mt-2 relative lg:absolute bottom-0 w-full 1xl:relative">
                <div className="space-y-2 !mt-[-8px] bg-transparent lg:bg-white 1xl:bg-transparent px-0 lg:px-8 1xl:px-0 py-0 lg:py-6 1xl:py-0">
                  <h2 className="text-lg lg:text-2xl 2xl:text-3xl text-gray-900 font-extrabold mb-2">
                    {employee?.data?.firstName} {employee?.data?.lastName}
                  </h2>
                  <div className="!mt-0">
                    {/* <div className="relative mb-3">
                        <i className="fa-sharp fa-solid fa-subtitles h-7 w-7 text-orange-500 text-xs bg-[#f0f0f0] text-center rounded-full mr-3 pt-[7px]"></i>
                        <span className="border-b border-slate-400 border-dashed uppercase text-sm font-bold text-orange-400">Position</span>
                        {employee?.data?.positionDetails?.title && (
                          <p className="text-sm font-normal mt-1">
                            {employee?.data?.positionDetails?.title}
                          </p>
                        )}
                      </div> */}
                      <div className="md:flex lg:block items-center gap-10 space-y-2 md:space-y-0">
                        <div className="relative lg:mb-3 flex items-center">
                          <i className="fa-regular fa-envelope text-blue-500 mr-2 lg:mr-4 h-7 w-7 text-xs bg-white md:bg-white lg:bg-red-100 1xl:bg-white text-center rounded-full pt-[6px] shadow-[0px_0px_7px_0px_rgba(0,0,0,0.2)] flex-shrink-0" />
                          <div className="w-full">
                            {/* <span className="uppercase text-sm font-bold text-black border-b border-black border-dashed">Email</span> */}
                            <p className="text-sm text-gray-800">
                              {employee?.data?.email}
                            </p>
                          </div>
                        </div>
                        <div className="relative flex items-center">
                          <i className="fa-regular fa-phone text-green-500 mr-2 lg:mr-4 h-7 w-7 text-xs bg-white md:bg-white lg:bg-red-100 1xl:bg-white text-center rounded-full pt-[6px] shadow-[0px_0px_7px_0px_rgba(0,0,0,0.2)] flex-shrink-0" />
                          <div className="w-full">
                            {/* <span className="border-b border-black border-dashed uppercase text-sm font-bold text-black">Phone</span> */}
                            <p className="text-sm text-gray-800">
                              {employee?.data?.phone
                                ? `+${formatPhoneNumber(employee?.data?.phone)}`
                                : "N/A"}
                            </p>
                          </div>
                        </div>
                      </div>
                    <Button
                      buttonLabel="Change Profile"
                      buttonLabelClasses={"!text-white !text-sm !font-medium group"}
                        buttonClasses={"!bg-orange-500 flex items-center justify-center transition-all ease-in-out delay-150 duration-300 flex-shrink-0 !mt-3 !py-2 !px-2 rounded-lg !border !border-orange-500 transition-all duration-300 hover:!bg-orange-600 !h-auto"}
                      buttonFunction={() => {setEditModalOpen({ isOpen: true });}}
                    />
                    {/* <div className="relative">
                        <i className="fa-solid fa-building-memo h-7 w-7 text-orange-500 text-xs bg-[#f0f0f0] text-center rounded-full mr-3 pt-[7px]"></i>
                        <span className="border-b border-slate-400 border-dashed uppercase text-sm font-bold text-orange-400">Department</span>
                        <p className="text-sm font-normal mt-1">
                          {employee?.data?.departmentDetails
                            ?.map((department) => department.name)
                            .join(" | ")}
                        </p>
                      </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
            <div className="col-span-12 lg:col-span-7 3xl:col-span-8 relative w-full bg-white shadow-md rounded-xl p-2 md:p-4 border border-gray-200 space-y-4 md:space-y-5">
            <section className="grid grid-cols-1 1xl:grid-cols-3 gap-4">
              <div className="flex items-center bg-gray-50 px-4 py-3 1xl:py-2 shadow-sm rounded-lg border border-gray-200 border-l-2 border-l-orange-400">
                <i className="fa-solid fa-user-shield text-purple-500 mr-4 h-7 w-7 text-xs bg-white text-center rounded-full pt-[6px] shadow-[0px_0px_7px_0px_rgba(0,0,0,0.2)] flex-shrink-0" />
                <div className="w-full">
                  <h4 className="text-sm font-bold text-gray-600 border-b border-slate-200 pb-1 mb-1 uppercase">
                    Role
                  </h4>
                  <p className="text-sm text-gray-800 capitalize">
                    {role?.code === "COMPANY-MANAGER"
                      ? "Evaluator"
                      : role?.code === "COMPANY-ADMINISTRATOR"
                      ? "Administrator"
                      : "Employee"}
                  </p>
                </div>
              </div>
                <div className="flex items-center bg-gray-50 px-4 py-3 1xl:py-2 shadow-sm rounded-lg border border-gray-200 border-l-2 border-l-orange-400">
                <i className="fa-solid fa-circle-check text-green-500 mr-4 h-7 w-7 text-xs bg-white text-center rounded-full pt-[6px] shadow-[0px_0px_7px_0px_rgba(0,0,0,0.2)] flex-shrink-0" />
                <div className="w-full">
                  <h4 className="text-sm font-bold text-gray-600 border-b border-slate-200 pb-1 mb-1 uppercase">
                    Status
                  </h4>
                  <p className="text-sm text-gray-800">
                    {employee?.data?.status === 1 ? "Active" : "Inactive"}
                  </p>
                </div>
              </div>
                <div className="flex items-center bg-gray-50 px-4 py-3 1xl:py-2 shadow-sm rounded-lg border border-gray-200 border-l-2 border-l-orange-400">
                <i className="fa-solid fa-building-memo h-7 w-7 text-orange-500 text-xs bg-white text-center rounded-full mr-4 pt-[6px] shadow-[0px_0px_7px_0px_rgba(0,0,0,0.2)] flex-shrink-0"></i>
                <div className="w-full">
                  <h3 className="text-sm font-bold text-gray-600 border-b border-slate-200 pb-1 mb-1 uppercase">
                    Position
                  </h3>
                  {employee?.data?.positionDetails?.title && (
                    <p className="text-sm font-normal mt-1">
                      {employee?.data?.positionDetails?.title}
                    </p>
                  )}
                </div>
              </div>
            </section>
            <section className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                <div className="flex items-center bg-gray-50 px-4 py-3 1xl:py-2 shadow-sm rounded-lg border border-gray-200 border-l-2 border-l-orange-400">
                <i className="fa-sharp fa-solid fa-subtitles text-orange-500 mr-4 h-7 w-7 text-xs bg-white text-center rounded-full pt-[6px] shadow-[0px_0px_7px_0px_rgba(0,0,0,0.2)] flex-shrink-0"></i>
                <div className="w-full">
                  <h3 className="text-sm font-bold text-gray-600 border-b border-slate-200 pb-1 mb-1 uppercase">
                    Department
                  </h3>
                  <p className="text-sm text-gray-800">
                    {employee?.data?.departmentDetails
                      ?.map((department) => department.name)
                      .join(" | ")}
                  </p>
                </div>
              </div>

                <div className="flex items-center bg-gray-50 px-4 py-3 1xl:py-2 shadow-sm rounded-lg border border-gray-200 border-l-2 border-l-orange-400">
                <i className="fa-solid fa-chalkboard-user text-blue-500 mr-4 h-7 w-7 text-xs bg-white text-center rounded-full pt-[6px] shadow-[0px_0px_7px_0px_rgba(0,0,0,0.2)] flex-shrink-0"></i>
                <div className="w-full">
                  <h3 className="text-sm font-bold text-gray-600 border-b border-slate-200 pb-1 mb-1 uppercase">
                    Evaluatees
                  </h3>
                  <div className="3xl:grid grid-cols-2 gap-6">
                    {employee?.data?.evaluatees?.length > 0 ? (
                      employee.data.evaluatees.map((evaluatee, i) => (
                        <Link
                          key={i}
                          to={`/directory/employee/${evaluatee?._id}`}
                          className=""
                        >
                          <div className="w-14 h-14">
                            <Image
                              src={evaluatee?.image || NoEmpImg}
                              alt={`${evaluatee?.firstName} ${evaluatee?.lastName}`}
                              className="rounded-full object-cover border border-gray-300"
                            />
                          </div>
                          <div>
                            <h4 className="text-sm text-gray-900">
                              {evaluatee?.firstName} {evaluatee?.lastName}
                            </h4>
                            <p className="text-sm text-gray-500">
                              {evaluatee?.positionDetails?.title}
                            </p>
                          </div>
                        </Link>
                      ))
                    ) : (
                      <p className="text-gray-500">No evaluatees available.</p>
                    )}
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      )}

      <EmployeeEdit
        details={employee}
        setDetails={setEmployee}
        editmodalOpen={editmodalOpen}
        setEditModalOpen={setEditModalOpen}
      />
    </Directory>
  );
}

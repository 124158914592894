import React, { useEffect, useState } from "react";
import Button from "../../../components/form/Button";
import Modal from "../../../components/elements/Modal";
import TextAreaAutoSize from "../../form/TextareaAutoSize";
import { companyUpdate } from "../../../services/frontService/CompanyService";
import { toast } from "react-toastify";
import useForm from "../../../hooks/useForm";
import Input from "../../form/Input";
import { formValidate } from "../../../helpers/formValidate";

const OurMissionManage = ({
  ourMissionModal,
  setMissionModal = () => {},
  setDetails = () => {},
}) => {
  const validation = {
    goal: { required: true, message: "Please enter..." },
    description: { required: true, message: "Please enter..." },
  };
  const { values, handleChange, handleSubmit, errors, setFieldsValue } =
    useForm({}, validation);
  const [loading, setLoading] = useState(false);
  const data = ourMissionModal?.data;

  useEffect(() => {
    if (data) {
      setFieldsValue({
        goal: data?.goal || "",
        description: data?.description || "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const onSubmit = (values) => {
    setLoading(true);
    companyUpdate({
      companyGoal: values?.goal,
      companyDescription: values?.description,
    }).then((res) => {
      if (res?.status === 200) {
        setDetails(res?.data);
        toast.success(res?.message);
        setMissionModal({ isOpen: false });
        setLoading(false);
      } else {
        toast.error(res?.data?.message);
        setMissionModal({ isOpen: false });
        setLoading(false);
      }
    });
  };

  return (
    <div>
      <Modal
        title={"Mission Statement"}
        size="xl"
        open={ourMissionModal.isOpen}
        onClose={() => setMissionModal({ isOpen: false , data : {} })}
        footer={null}
        modaltitleClasses={"!text-slate-200 text-lg"}
        headerClass={"!bg-black !rounded-t-lg !text-white"}
        childrenClasses={"!rounded-b-lg"}
      >
        <form onSubmit={(event) => handleSubmit(event, onSubmit)} className=""> 
          <div className="relative col-span-12">
            <Input
              label={"Heading"}
              inputPlaceholder={"Enter section heading"}
              inputClasses={"pl-2"}
              labelClasses={"!text-sm !font-medium"}
              inputType={"text"}
              value={values.goal}
              isValidate={true}
              onChange={handleChange}
              inputName={"goal"}
              charLimit={200}
              {...formValidate(errors, "goal")}
            />
          </div>
          <div className="relative col-span-12 mt-1">
            <TextAreaAutoSize
              label={"Description"}
              inputValue={values?.description}
              inputName={"description"}
              inputPlaceholder="Enter description"
              onChange={handleChange}
              isValidate={true}
              {...formValidate(errors, "description")}
            />
          </div>

          <div className="flex items-center justify-end mt-4 gap-2">
            <Button
              buttonLabel={"Cancel"}
              buttonIcon={"fa-regular fa-xmark"}
              buttonIconPosition={"left"}
              buttonClasses="!py-2 !px-2 border border-orange-500 !text-orange-500 rounded-lg !bg-transparent !h-auto"
              buttonFunction={() => setMissionModal({ isOpen: false })}
            />
            <Button
              loading={loading}
              buttonLabel={loading ? "Saving..." : "Save"}
              buttonIcon={"fa-light fa-floppy-disk"}
              buttonIconPosition={"left"}
              buttonClasses="!py-2 !px-2 rounded-lg text-white !h-auto"
              buttonType="submit"
              isDisable={loading ? true : false}
            />
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default OurMissionManage;

import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../../components/form/Button";
import { Tooltip } from "react-tooltip";
import Input from "../../../components/form/Input";
import useForm from "../../../hooks/useForm";
import {
  companyAdd,
  companyDetails,
  companyEdit,
} from "../../../services/adminService/CompanyService";
import { toast } from "react-toastify";
import Select from "../../../components/form/Select";
import { formValidate } from "../../../helpers/formValidate";
import ImageUploader from "../../../components/form/ImageUploader";
import { Validation } from "../../../helpers/Validation";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

const ManageCompany = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(null);
  const [details, setDetails] = useState({});
  const [noOfEmployees, setNoOfEmployees] = useState("<50");
  const [accountStatus, setAccountStatus] = useState("unpaid");
  const [phone, setPhone] = useState("");

  const validation = {
    firstName: { required: true, message: "Please enter your first name!" },
    lastName: { required: true, message: "Please enter your last name!" },
    email: { required: true, message: "Please enter your email!" },
    companyName: { required: true, message: "Please enter your Company Name!" },
  };

  const {
    values,
    handleChange,
    handleSubmit,
    errors,
    setFieldsValue,
    resetField,
  } = useForm({}, validation);

  useEffect(() => {
    if (id) {
      companyDetails({ id }).then((res) => {
        if (res?.status === 200) {
          setDetails(res?.data?.company);
        } else {
          toast.error("Something went wrong!");
        }
      });
    }
  }, [id]);

  useEffect(() => {
    if (id && details) {
      const admin = details?.companyAdministrator?.[0] || {};

      setFieldsValue({
        companyName: details?.companyName || "",
        firstName: admin?.firstName || "",
        lastName: admin?.lastName || "",
        email: admin?.email || "",
      });

      setNoOfEmployees(details?.noOfEmployees);
      setAccountStatus(details?.accountStatus);

      if (details?.image?.url) {
        setImage(details.image.url);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, details]);

  useEffect(() => {
    if (details?.companyAdministrator?.[0]?.phone) {
      let phoneNumber = String(details?.companyAdministrator?.[0]?.phone);
      if (!phoneNumber.startsWith("+")) {
        phoneNumber = `+${phoneNumber}`;
        setPhone(phoneNumber);
      }
    } else {
      setPhone("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [details?.companyAdministrator?.[0]?.phone]);

  const handlePhoneInputChange = (value) => {
    setPhone(value);
  };

  const onSubmit = async (values) => {
    setLoading(true);
    try {
      if (!Validation("email", values.email)) {
        toast.error("Invalid email format. Please enter a valid email.");
        setLoading(false);
        return;
      }
      
      const payload = {
        companyName: values?.companyName,
        firstName: values?.firstName,
        lastName: values?.lastName,
        email: values?.email,
        phone: phone || "",
        noOfEmployees,
        accountStatus,
        image: image || "null",
      };

      const response = id
        ? await companyEdit({
            ...payload,
            companyId: id,
            administratorId: details?.companyAdministrator?.[0]?._id,
          })
        : await companyAdd(payload);

      setLoading(false);
      if (response?.status === 201 || response?.status === 200) {
        toast.success(response?.message);
        navigate("/admin/companies");
      } else {
        toast.error(response?.data?.message || response?.error);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong!");
    }
  };

  return (
    <div className="border bg-white border-gray-200 p-4 shadow rounded-md">
      <div className="flex justify-between items-center mb-3">
        <div className="text-2xl font-bold">
          {id ? "Update" : "Add"} Company
        </div>
        <div className="relative flex items-center ml-auto gap-2">
          <div data-tooltip-id="reset-company" data-tooltip-place="bottom">
            <Button
              buttonType={"button"}
              buttonIcon={"fa-regular fa-rotate-left"}
              buttonIconPosition={"left"}
              buttonHasLink={false}
              buttonFunction={() => resetField()}
            />
            <Tooltip
              id="reset-company"
              className="!text-slate-500 !text-sm !bg-slate-950 !bg-opacity-100 z-100"
              render={() => (
                <div className="text-white">Please click to reset.</div>
              )}
            />
          </div>
          <div data-tooltip-id="back-company" data-tooltip-place="bottom">
            <Button
              buttonType={"button"}
              buttonClasses={"!text-center"}
              buttonLabel={"Back"}
              buttonIcon={"fa-solid fa-arrow-left"}
              buttonIconPosition={"left"}
              buttonHasLink={false}
              buttonFunction={() => navigate("/admin/companies")}
            />
            <Tooltip
              id="back-company"
              className="!text-slate-500 !text-sm !bg-slate-950 !bg-opacity-100 z-100"
              render={() => (
                <div className="text-white">
                  Click to go back to company list.
                </div>
              )}
            />
          </div>
        </div>
      </div>

      <form onSubmit={(e) => handleSubmit(e, onSubmit)}>
        <div className="grid grid-cols-12 gap-4">
          <div className="image bg-white p-4 col-span-12 lg:col-span-3 border-r border-slate-200 h-auto">
            <ImageUploader
              label=""
              labelClasses="text-lg font-bold"
              isMultiple={false}
              image={image}
              setImage={setImage}
              buttonLabel={
                image ? "Change Company Logo" : "Upload Company Logo"
              }
            />
          </div>
          <div className="input bg-white col-span-12 lg:col-span-9">
            <div className="text-xl font-bold mb-3">Company Info</div>
            <div className="grid lg:grid-cols-2 gap-3">
              <div className="relative col-span-2">
                <Input
                  label={"Company Name"}
                  inputType={"text"}
                  value={values?.companyName}
                  inputPlaceholder={"Enter company name"}
                  inputName="companyName"
                  onChange={handleChange}
                  isValidate={true}
                  {...formValidate(errors, "companyName")}
                />
              </div>
              <div className="relative">
                <Select
                  label={"No of Employees"}
                  dropdownData={[
                    { name: "<50", value: "<50" },
                    { name: "51-100", value: "51-100" },
                    { name: "101-250", value: "101-250" },
                    { name: "251-500", value: "251-500" },
                    { name: "501-1000", value: "501-1000" },
                    { name: ">1000", value: ">1000" },
                  ]}
                  selectedValue={noOfEmployees}
                  setCustomSelecedvalue={(val) => setNoOfEmployees(val)}
                />
              </div>
              <div className="relative">
                <Select
                  label={"Account Status"}
                  dropdownData={[
                    { name: "Paid", value: "paid" },
                    { name: "Unpaid", value: "unpaid" },
                  ]}
                  selectedValue={accountStatus}
                  setCustomSelecedvalue={(val) => setAccountStatus(val)}
                />
              </div>
            </div>
            <div className="text-xl font-bold my-3">Administrator Info</div>
            <div className="grid lg:grid-cols-2 gap-3">
              <div className="relative">
                <Input
                  label={"First Name"}
                  inputType={"text"}
                  inputPlaceholder={"Enter first name"}
                  value={values?.firstName}
                  inputName="firstName"
                  onChange={handleChange}
                  isValidate={true}
                  {...formValidate(errors, "firstName")}
                />
              </div>
              <div className="relative">
                <Input
                  label={"Last Name"}
                  inputType={"text"}
                  inputPlaceholder={"Enter last name"}
                  inputName="lastName"
                  value={values?.lastName}
                  onChange={handleChange}
                  isValidate={true}
                  {...formValidate(errors, "lastName")}
                />
              </div>
              <div className="relative">
                <Input
                  label={"Administrator Email"}
                  inputName="email"
                  inputType={"text"}
                  value={values?.email}
                  inputPlaceholder={"Enter administrator email"}
                  onChange={handleChange}
                  isValidate={true}
                  {...formValidate(errors, "email")}
                />
              </div>
              <div className="relative mt-0.5">
                <div className="text-sm font-medium text-slate-500">Phone</div>
                <PhoneInput
                  placeholder="(xxx) xxx-xxxx"
                  value={phone}
                  onChange={handlePhoneInputChange}
                  // defaultCountry="US"
                  international={false}
                />
              </div>
            </div>
            <div className="w-full flex items-center justify-end gap-3 mt-4">
              <div data-tooltip-id="company-add" data-tooltip-place="left">
                <Button
                  buttonType={"submit"}
                  buttonIcon={
                    id ? "fa-light fa-floppy-disk" : "fa-light fa-plus"
                  }
                  buttonIconPosition={"left"}
                  buttonLabel={id ? "Save" : "Add"}
                  loading={loading}
                />
                <Tooltip
                  id="company-add"
                  className="!text-slate-500 !text-sm !bg-slate-950 !bg-opacity-100 z-100"
                  render={() => (
                    <div className="text-white">Click to add company.</div>
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ManageCompany;

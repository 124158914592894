import { SsoLoginProvider } from "./Context/SsoLoginContext";
import { SyncDataModalProvider } from "./Context/SyncDataModalContext";
import CreateRoutes from "./routes";
function App() {
  return (
    <SyncDataModalProvider>
      <SsoLoginProvider>
        <CreateRoutes />
      </SsoLoginProvider>
    </SyncDataModalProvider>
  );
}

export default App;

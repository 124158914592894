import React, { Fragment, useState, useRef } from "react";
import NoUserImg from "../../assets/images/noEmpImg.svg";
import { bottom, top, left, right } from "@popperjs/core";
import { Combobox, Transition } from "@headlessui/react";
import { classNames } from "../../helpers/classNames";
import { usePopper } from "react-popper";
import Image from "../elements/Image";

export default function SearchWithDropdown({
  label,
  labelClasses,
  placeholder,
  divClasses,
  inputClasses,
  iconClasses,
  iconPosition = "left",
  icon = "fa-regular fa-magnifying-glass",
  xPlacement,
  dropdownClass,
  dropdownData = [],
  keyword,
  loading,
  onChange = () => {},
  setKeyword = () => {},
}) {
  const [selected, setSelected] = useState("");
  const popperElRef = useRef(null);
  const [targetElement, setTargetElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [inputValue, setInputValue] = useState(keyword || "");

  const placements = {
    bottom: bottom,
    bottomLeft: "bottom-start",
    bottomRight: "bottom-end",
    top: top,
    topLeft: "top-start",
    topRight: "top-end",
    right: right,
    left: left,
    "": bottom,
  };

  const { styles, attributes } = usePopper(targetElement, popperElement, {
    placement: placements[xPlacement],
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 8],
        },
      },
    ],
  });

  const handleInputChange = (event) => {
    const value = event.target.value;
    setInputValue(value);
    setKeyword(value);
  };

  const handleSelection = (val) => {
    setSelected(val);
    setInputValue(val?.name || "");
    onChange(val);
  };

  const clearSelection = () => {
    setSelected(null);
    setInputValue("");
    onChange(null);
  };

  const filteredData =
    inputValue === ""
      ? dropdownData
      : dropdownData.filter((item) => {
          const itemTitle = (item.name || "").toLowerCase().replace(/\s+/g, "");
          return itemTitle.includes(
            inputValue.toLowerCase().replace(/\s+/g, "")
          );
        });

  return (
    <div className="relative w-full md:w-auto">
      {label && (
        <div
          className={classNames(
            "text-sm font-medium text-slate-950 mb-1",
            labelClasses
          )}
        >
          {label}
        </div>
      )}
      <Combobox
        value={selected}
        onChange={handleSelection}
        as={"div"}
        className="relative"
      >
        <div
          ref={setTargetElement}
          className={classNames(
            "relative flex h-12 w-full md:w-52 lg:w-full bg-white rounded-md border border-slate-200 overflow-hidden",
            divClasses
          )}
        >
          {iconPosition === "left" && (
            <Combobox.Button
              className={classNames(
                "w-12 h-12 min-w-[40px] flex items-center justify-center text-slate-400",
                iconClasses
              )}
            >
              {loading ? (
                <i className="fa-duotone fa-spinner-third animate-spin" />
              ) : (
                <i className={classNames("fa-fw", icon)}></i>
              )}
            </Combobox.Button>
          )}
          <Combobox.Input
            displayValue={(item) => item?.title}
            value={inputValue}
            onChange={handleInputChange}
            className={classNames(
              "!border-0 w-full !ring-0 bg-transparent text-slate-600 text-base font-medium px-2 placeholder:text-slate-500 placeholder:text-sm placeholder:font-normal",
              inputClasses
            )}
            placeholder={placeholder}
          />
          {selected && (
            <div
              className="p-2 m-2 flex items-center justify-center cursor-pointer rounded-full hover:bg-slate-100"
              onClick={clearSelection}
            >
              <i className="fa-regular fa-times " />
            </div>
          )}
          {iconPosition === "right" && (
            <Combobox.Button
              className={classNames(
                "w-12 h-12 min-w-[40px] flex items-center justify-center text-black",
                iconClasses
              )}
            >
              <i className={classNames("fa-fw", icon)}></i>
            </Combobox.Button>
          )}
        </div>
        <Transition
          as={Fragment}
          leave="transition duration-75"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          className="z-50 w-full"
          beforeEnter={() => setPopperElement(popperElRef.current)}
          afterLeave={() => setPopperElement(null)}
          ref={popperElRef}
          style={styles.popper}
          {...attributes.popper}
        >
          <Combobox.Options
            className={classNames(
              "w-full origin-top-right bg-white border-0 divide-y divide-slate-50 rounded-md shadow ring-0 overflow-auto scroll-smooth scrollbar",
              dropdownClass
            )}
          >
            <div className="py-1 max-h-64 divide-y divide-slate-50">
              {loading && (
                <div className="flex items-center space-x-4 text-slate-500 py-2 px-6">
                  <div className="h-20 w-20 flex items-center justify-center">
                    <span className="select_loader"></span>
                  </div>
                  <p className="font-medium text-slate-600">
                    Searching for results...
                  </p>
                </div>
              )}
              {filteredData?.length > 0 &&
                filteredData.map((item, index) => (
                  <Combobox.Option
                    key={index}
                    className={({ active }) =>
                      classNames(
                        "relative select-none py-3 pr-12 pl-4 text-base leading-tight cursor-pointer transition-all duration-200 text-black flex items-center space-x-2",
                        active ? "bg-slate-100" : ""
                      )
                    }
                    value={item}
                  >
                    {({ selected }) => (
                      <>
                        {item?.type === "user" && (
                          <div className="flex items-center gap-2 pl-2">
                            <div className="flex items-center justify-center w-12 h-12 rounded-full overflow-hidden border">
                              <Image
                                src={item.image || NoUserImg}
                                width={100}
                                height={100}
                                alt=""
                                className="w-full h-full object-cover"
                              />
                            </div>
                            <div>
                              <p className="text-sm font-medium">{item.name}</p>
                              {item.subItem && (
                                <p className="text-xs text-gray-500">
                                  {item.subItem}
                                </p>
                              )}
                            </div>
                          </div>
                        )}
                        {item?.type === "list" && (
                          <span className="text-sm font-medium">
                            {item.name}
                          </span>
                        )}
                        {item?._id === selected?._id && (
                          <div className="absolute inset-y-0 left-0 flex items-center text-slate-800 text-base">
                            <i className="fa-regular fa-fw fa-check"></i>
                          </div>
                        )}
                      </>
                    )}
                  </Combobox.Option>
                ))}
              {filteredData?.length === 0 && !loading && (
                <div className="flex items-center space-x-4 text-slate-500 p-3">
                  <i className="fa-regular fa-face-sad-tear text-5xl"></i>
                  <div className="flex flex-col space-y-1">
                    <p className="font-semibold">No Results Found!</p>
                    <p className="text-sm text-slate-400">
                      Try refining your search with a different name.
                    </p>
                  </div>
                </div>
              )}
            </div>
          </Combobox.Options>
        </Transition>
      </Combobox>
    </div>
  );
}

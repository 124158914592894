import React from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { Tooltip } from "react-tooltip";

export default function Directory({ children, name }) {
  const profile = useSelector((state) => state?.profile?.data);
  const location = useLocation();

  const paths = location.pathname.split("/").filter(Boolean);

  return (
    <div className="flex w-full py-6 px-6 space-x-6 mb-4 pl-5 md:pl-6 pr-5 md:pr-5">
      <div className="flex-shrink flex-grow relative flex flex-col">
        <div className="font-semibold text-3xl text-slate-800 h-10 flex items-center">
          Directory
        </div>

        <nav className="flex items-center space-x-2 text-sm text-gray-600 mt-4">
          <Link
            to="/"
            className="flex items-center text-orange-500 font-medium hover:text-orange-600"
          >
            <i className="fas fa-home mr-1"></i>
          </Link>
          {paths.map((path, index) => {
            const fullPath = `/${paths.slice(0, index + 1).join("/")}`;
            const isLast = index === paths.length - 1;

            return (
              <React.Fragment key={index}>
                <span className="text-gray-400">
                  <i className="fas fa-chevron-right mx-1"></i>
                </span>
                {isLast ? (
                  <span className="text-gray-800 font-semibold capitalize">
                    {index === 2 && paths[1] === "employee"
                      ? name
                      : path.replace(/-/g, " ")}
                  </span>
                ) : (
                  <Link
                    to={fullPath}
                    className="text-orange-500 font-medium capitalize hover:text-orange-600"
                  >
                    {path.replace(/-/g, " ")}
                  </Link>
                )}
              </React.Fragment>
            );
          })}
        </nav>

        <div className="relative w-full mt-5 overflow-hidden">
          <div className="flex flex-row gap-3 whitespace-nowrap overflow-x-auto">
            <Link to="/directory/employee">
              <div
                className={`flex flex-col md:flex-row items-center justify-center md:justify-start shadow-sm gap-1 border md:pl-5 bg-slate-50 px-4 md:px-10 h-auto py-2 md:py-0 md:h-16 rounded-lg text-xl hover:shadow-md hover:bg-blue-50 hover:text-blue-600 ${location.pathname.startsWith("/directory/employee")
                    ? "shadow-md bg-blue-50 text-blue-600 border-blue-300"
                    : ""
                  }`}
                data-tooltip-id="directory-employees"
                data-tooltip-position-strategy="fixed"
              >
                <span className="flex items-center justify-center w-8 h-8 bg-blue-100 rounded-full p-2 text-blue-600 text-sm">
                  <i className="fa-regular fa-user-tie" />
                </span>
                <p className="font-medium mt-2 md:mt-0">{profile?.employeeCount}</p>
                <p className="text-sm pt-0 md:pt-1 mt-[-5px] md:mt-[0]">Employees</p>
                <Tooltip
                  id="directory-employees"
                  place="bottom"
                  className="!text-slate-500 !text-sm !bg-slate-950 !bg-opacity-100 z-100"
                  render={() => (
                    <div className="text-white">
                      <div>Click to view all employees.</div>
                    </div>
                  )}
                />
              </div>
            </Link>

            <Link to="/directory/departments">
              <div
                className={`flex flex-col md:flex-row items-center justify-center md:justify-start shadow-sm gap-1 border md:pl-5 bg-slate-50 px-4 md:px-10 h-auto py-2 md:py-0 md:h-16 rounded-lg text-xl hover:shadow-md hover:bg-red-50 hover:text-red-600 ${location.pathname === "/directory/departments"
                    ? "shadow-md bg-red-50 text-red-600 border-red-300"
                    : ""
                  }`}
                data-tooltip-id="directory-departments"
                data-tooltip-position-strategy="fixed"
              >
                <span className="flex items-center justify-center w-8 h-8 bg-red-100 rounded-full p-2 text-red-600 text-sm">
                  <i className="fa-regular fa-flag"></i>
                </span>
                <p className="font-medium mt-2 md:mt-0">{profile?.departmentCount}</p>
                <p className="text-sm pt-0 md:pt-1 mt-[-5px] md:mt-[0]">Departments</p>
                <Tooltip
                  id="directory-departments"
                  place="bottom"
                  className="!text-slate-500 !text-sm !bg-slate-950 !bg-opacity-100 z-100"
                  render={() => (
                    <div className="text-white">
                      <div>Click to view all departments.</div>
                    </div>
                  )}
                />
              </div>
            </Link>

            <Link to="/directory/positions">
              <div
                className={`flex flex-col md:flex-row items-center justify-center md:justify-start shadow-sm gap-1 border md:pl-5 bg-slate-50 px-4 md:px-10 h-auto py-2 md:py-0 md:h-16 rounded-lg text-xl hover:shadow-md hover:bg-emerald-50 hover:text-emerald-600 ${location.pathname === "/directory/positions"
                    ? "shadow-md bg-emerald-50 text-emerald-600 border-emerald-300"
                    : ""
                  }`}
                data-tooltip-id="directory-positions"
                data-tooltip-position-strategy="fixed"
              >
                <span className="flex items-center justify-center w-8 h-8 bg-emerald-100 rounded-full p-2 text-emerald-600 text-sm">
                  <i className="fa-regular fa-users"></i>
                </span>
                <p className="font-medium mt-2 md:mt-0">{profile?.positionCount}</p>
                <p className="text-sm pt-0 md:pt-1 mt-[-5px] md:mt-[0]">Positions</p>
                <Tooltip
                  id="directory-positions"
                  place="bottom"
                  className="!text-slate-500 !text-sm !bg-slate-950 !bg-opacity-100 z-100"
                  render={() => (
                    <div className="text-white">
                      <div>Click to view all positions.</div>
                    </div>
                  )}
                />
              </div>
            </Link>
          </div>
        </div>

        <div className="mt-6">
          {children}
        </div>
      </div>
    </div>
  );
}

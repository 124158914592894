import React, { useState, useRef, useEffect } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { usePopper } from "react-popper";
import { bottom } from "@popperjs/core";
import { classNames } from "../../helpers/classNames";
import Search from "./Search";
import Image from "../elements/Image";
import NoData from "../../assets/images/no-results-bg.2d2c6ee3.png";

const Select = ({
  dropdownData = [{ name: "Select", value: null }],
  setCustomSelecedvalue = () => {},
  onSearch = () => {},
  selectedValueType = "value",
  dropdownButtonClass,
  disabled = false,
  hasPlaceholder = false,
  isSearch = false,
  dropdownClass,
  selectedValue,
  labelClasses,
  placeholder = "Select an item",
  xPlacement,
  errorType,
  errorText,
  loading = false,
  label,
  getSelectedValue = () => {},
  ...props
}) => {
  const [selected, setSelected] = useState();
  const [list, setList] = useState([]);

  useEffect(() => {
    if (
      dropdownData &&
      Array.isArray(dropdownData) &&
      dropdownData?.length > 0
    ) {
      setList(dropdownData || []);
    }
  }, [dropdownData]);

  useEffect(() => {
    if (
      selectedValue &&
      dropdownData &&
      Array.isArray(dropdownData) &&
      dropdownData?.length > 0
    ) {
      setSelected(dropdownData.find((item) => item.value == selectedValue));
    } else {
      setSelected(dropdownData.find((item) => item.value == selectedValue));
    }
  }, [selectedValue, dropdownData]);


  const popperElRef = useRef(null);
  const [targetElement, setTargetElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);

  const placements = {
    left: "bottom-start",
    right: "bottom-end",
    "": bottom,
  };

  const { styles, attributes } = usePopper(targetElement, popperElement, {
    placement: placements[xPlacement],
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 8],
        },
      },
    ],
  });

  const setSelectedFunction = (e) => {
    setSelected(e);
    setCustomSelecedvalue(e[selectedValueType]);
  };

  const onSearchHandler = (e) => {
    onSearch(e);
    setList(dropdownData.filter((item) => item.name.toLowerCase().includes(e)));
  };
  return (
    <>
      {label && (
        <div
          className={classNames(
            "text-sm font-medium text-slate-500 mb-1",
            labelClasses
          )}
        >
          {label}
        </div>
      )}
      <Listbox
        value={selected}
        // onChange={setSelectedFunction}
        onChange={(e) => {
          setSelectedFunction(e);
          getSelectedValue(e);
        }}
        disabled={disabled}
        as={"div"}
        className="relative"
      >
        <Listbox.Button
          ref={setTargetElement}
          className="flex items-center w-full"
        >
          <div
            className={classNames(
              "relative w-full h-10 flex items-center justify-between focus:ring-0 transition-all duration-200 gap-2 pr-2 pl-3 bg-white rounded-md border border-slate-200 ",
              dropdownButtonClass
            )}
          >
            {selected?.image && (
              <div className="w-5 aspect-1 overflow-hidden rounded-md flex-shrink-0">
                <Image
                  src={selected?.image}
                  alt={""}
                  effect={"blur"}
                  className={"object-cover"}
                />
              </div>
            )}
            <div className="text-[13px] text-slate-500 font-medium whitespace-nowrap truncate">
              {loading ? "Searching..." : selected?.name || placeholder}
            </div>
            <div className="text-xs text-slate-400">
              {loading ? (
                <i
                  className={"fa-duotone fa-spinner-third animate-spin text-sm"}
                />
              ) : (
                <i className={"fa-light fa-fw fa-chevron-down"}></i>
              )}
            </div>
          </div>
        </Listbox.Button>
        <Transition
          as="div"
          leave="transition duration-75"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          className="w-full z-50"
          beforeEnter={() => setPopperElement(popperElRef.current)}
          afterLeave={() => setPopperElement(null)}
          ref={popperElRef}
          style={styles.popper}
          {...attributes.popper}
        >
          <Listbox.Options
            className={classNames(
              "origin-top-right bg-white border-0 divide-y divide-slate-100 rounded-md shadow ring-0 overflow-auto scroll-smooth scrollbar",
              dropdownClass
            )}
          >
            {isSearch && (
              <div className="py-2 px-3">
                <Search
                  placeholder={"Search"}
                  iconClasses={""}
                  search={(e) => onSearchHandler(e)}
                />
              </div>
            )}
            {Array.isArray(dropdownData) ? (
              dropdownData.length === 0 && !loading ? (
                <div className="flex flex-col items-center justify-center w-full py-3 space-y-3">
                  <div
                    className={classNames("relative w-full overflow-hidden")}
                  >
                    <Image
                      src={NoData}
                      alt="no-data"
                      className="w-full h-full"
                    />
                  </div>
                </div>
              ) : (
                <div className="py-1 max-h-80 divide-y divide-slate-100">
                  {dropdownData.map((item, index) => (
                    <Listbox.Option
                      key={index}
                      className={({ active }) =>
                        classNames(
                          "relative select-none py-2 pl-8 pr-2 text-xs leading-tight cursor-pointer transition-all duration-200",
                          active
                            ? "bg-carnation-50 text-slate-700"
                            : "text-slate-500"
                        )
                      }
                      value={item}
                    >
                      {({ selected }) => (
                        <div className="flex items-center gap-2">
                          {item.image && (
                            <div className="w-5 aspect-1 overflow-hidden rounded-md flex-shrink-0">
                              <Image
                                src={item.image}
                                alt={""}
                                effect={"blur"}
                                className={"object-cover"}
                              />
                            </div>
                          )}
                          <div
                            className={classNames(
                              "block truncate",
                              selected ? "font-medium" : "font-normal"
                            )}
                          >
                            {item?.name}
                          </div>
                          {selected && (
                            <div className="absolute inset-y-0 left-0 flex items-center pl-2 text-carnation-500 text-base">
                              <i className="fa-regular fa-fw fa-check"></i>
                            </div>
                          )}
                        </div>
                      )}
                    </Listbox.Option>
                  ))}
                </div>
              )
            ) : null}
          </Listbox.Options>
        </Transition>
      </Listbox>
      {errorType && errorText && (
        <div
          className={classNames(
            "text-xs mt-1",
            errorType === "error" && "text-red-600",
            errorType === "warning" && "text-amber-600",
            errorType === "success" && "text-green-600",
            errorType === "info" && "text-sky-600"
          )}
        >
          <i
            className={classNames(
              "fa-regular fa-fw text-sm mr-1",
              errorType === "error" && "fa-square-exclamation",
              errorType === "warning" && "fa-triangle-exclamation",
              errorType === "success" && "fa-circle-check",
              errorType === "info" && "fa-circle-info"
            )}
          ></i>
          {errorText}
        </div>
      )}
    </>
  );
};

export default Select;

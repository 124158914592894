import React, { useEffect, useState } from "react";
import Button from "../../form/Button";
import Modal from "../../elements/Modal";
import { formValidate } from "../../../helpers/formValidate";
import useForm from "../../../hooks/useForm";
import Input from "../../form/Input";
import Select from "../../form/Select";
import { toast } from "react-toastify";
import { companyUpdate } from "../../../services/frontService/CompanyService";
import { Validation } from "../../../helpers/Validation";
import DeleteModal from "../../../components/common/DeleteModal";

const WebsiteLinkManage = ({
  websiteLinkModal,
  setWebsiteLinkModal = () => {},
  setDetails = () => {},
}) => {
  const [fields, setFields] = useState([
    { value: "", fieldName: "SelectField" },
  ]);
  const [loading, setLoading] = useState(false);
  const [draggingIndex, setDraggingIndex] = useState(null);
  const [dragOverIndex, setDragOverIndex] = useState(null);
  const [deleteModal, setDeleteModal] = useState({
    isOpen: false,
  });

  const { values, handleChange, handleSubmit, errors, setFieldsValue } =
    useForm({}, Validation);

  const data = websiteLinkModal?.data;

  useEffect(() => {
    if (data && data.length > 0) {
      const initialFields = data.map((item) => ({
        fieldName: item.fieldName,
        value: item.value,
        _id: item?._id,
      }));

      setFields(initialFields);
    }
  }, [data]);

  const handleFieldChange = (e, index) => {
    const { name, value } = e.target;
    const newFields = [...fields];
    newFields[index][name] = value;
    setFields(newFields);
  };

  const addField = () => {
    setFields((prevFields) => [...prevFields, { value: "", fieldName: "" }]);
  };

  const getAvailableOptions = (index) => {
    const selectedValues = fields.map(
      (field, idx) => idx !== index && field.fieldName
    );
    const availableOptions = [
      { name: "Website", value: "website" },
      { name: "Instagram", value: "instagram" },
      { name: "Facebook", value: "facebook" },
      { name: "Linked In", value: "linkedin" },
      { name: "Twitter", value: "twitter" },
    ];

    return availableOptions.filter(
      (option) => !selectedValues.includes(option.value)
    );
  };

  const onSubmit = (values) => {
    const emptyField = fields.find(
      (field) => field.fieldName && field.value.trim() === ""
    );
    if (emptyField && emptyField.fieldName !== "SelectField") {
      toast.error(`URL for ${emptyField.fieldName} can't be empty!`);
      return;
    }
    const validFields = fields.filter(
      (field) => field.fieldName && field.value.trim() !== ""
    );
    setLoading(true);
    companyUpdate({
      digitalAddress: validFields?.length > 0 ? validFields : "null",
    }).then((res) => {
      if (res?.status === 200) {
        setDetails(res?.data);
        setWebsiteLinkModal({ isOpen: false });
        validFields?.length > 0
          ? toast.success(res?.message)
          : toast.info("Empty field can't added!");
          setLoading(false);
      } else {
        toast.error(res?.data?.message);
        setWebsiteLinkModal({ isOpen: false });
        setLoading(false);
      }
    });
  };

  const handleDragStart = (e, index) => {
    e.dataTransfer.setData("dragged-index", index);
    e.target.style.opacity = "0.5";
  };

  const handleDragEnd = (e) => {
    e.target.style.opacity = "1";
    setDraggingIndex(null);
  };

  const handleDragEnter = (index) => {
    setDragOverIndex(index);
  };

  const handleDragLeave = () => {
    setDragOverIndex(null);
  };

  const handleDrop = (e, targetIndex) => {
    e.preventDefault();
    const draggedIndex = parseInt(e.dataTransfer.getData("dragged-index"), 10);

    if (draggedIndex !== targetIndex) {
      const updatedFields = [...fields];
      const draggedItem = updatedFields[draggedIndex];
      updatedFields.splice(draggedIndex, 1);
      updatedFields.splice(targetIndex, 0, draggedItem);

      setFields(updatedFields);

      toast.success("Drag and drop completed!");
    }

    setDragOverIndex(null);
  };
  const deletefunction = () => {
    if (deleteModal.indexToDelete !== undefined) {
      const updatedFields = fields.filter(
        (_, i) => i !== deleteModal.indexToDelete
      );
      setFields(updatedFields);

      if (
        updatedFields.length === 0 ||
        (updatedFields.length === 1 &&
          updatedFields[0].fieldName === "" &&
          updatedFields[0].value === "")
      ) {
        setFields([{ value: "", fieldName: "" }]);
      }

      toast.success("Field deleted successfully!");
    }
    setDeleteModal((prev) => ({ ...prev, isOpen: false }));
  };

  return (
    <div>
      <Modal
        title="Links Details"
        size="xl"
        open={websiteLinkModal.isOpen}
        onClose={() => setWebsiteLinkModal({ isOpen: false })}
        footer={null}
        modaltitleClasses={"!text-slate-200 text-[15px]"}
        headerClass={"!bg-black !rounded-t-lg !text-white"}
        childrenClasses={"!rounded-b-lg"}
      >
        <form
          onSubmit={(event) => handleSubmit(event, onSubmit)}
          className="grid grid-cols-1 gap-y-6 p-6 bg-white rounded-lg shadow-md"
        >
          {fields.map((field, index) => (
            <div
              key={index}
              className="flex flex-col md:flex-row items-end gap-4"
              onDragOver={(e) => e.preventDefault()}
              onDrop={(e) => handleDrop(e, index)}
              onDragEnter={() => handleDragEnter(index)}
              onDragLeave={handleDragLeave}
              draggable
              onDragStart={(e) => handleDragStart(e, index)}
              onDragEnd={handleDragEnd}
              style={{
                backgroundColor:
                  dragOverIndex === index ? "white" : "transparent",
                transition: "background-color 0.2s ease",
              }}
            >
              <div className="relative w-full">
                <Select
                  label="Select Site"
                  selectedValue={field.fieldName}
                  dropdownData={getAvailableOptions(index)}
                  setCustomSelecedvalue={(val) =>
                    handleFieldChange(
                      { target: { name: "fieldName", value: val } },
                      index
                    )
                  }
                  {...formValidate(errors, "fieldName")}
                />
              </div>
              <div className="relative w-full">
                <Input
                  label={"URL"}
                  inputPlaceholder={"Enter url"}
                  inputClasses={"pl-2"}
                  labelClasses={"!text-sm !font-medium"}
                  inputType={"url"}
                  value={field.value}
                  onChange={(e) => handleFieldChange(e, index)}
                  inputName={"value"}
                  isValidate={true}
                  charLimit={200}
                  {...formValidate(errors, "value")}
                />
              </div>
              <div className="flex items-center gap-2">
                {/* {getAvailableOptions(index).length > 1 &&
                  index === fields.length - 1 && (
                    <button
                      type="button"
                      onClick={addField}
                      className="text-gray-400 border border-gray-400 p-3 w-5 h-5 rounded-full flex items-center justify-center"
                    aria-label="Add new field"
                    buttonLabel="Add New Field"
                    >
                      <i className="fa-regular fa-plus"></i>
                    </button>
                  )} */}
                {fields?.length > 0 &&
                  !(
                    fields.length === 1 &&
                    fields[0].value === "" &&
                    fields[0].fieldName === ""
                  ) && (
                    <button
                      type="button"
                      onClick={() => {
                        setDeleteModal((prev) => ({
                          ...prev,
                          isOpen: true,
                          indexToDelete: index,
                        }));
                      }}
                    className="p-3 w-10 h-10 flex items-center justify-center border border-orange-500 text-black rounded-md"
                      aria-label="Remove this field"
                    >
                      <i className="fa-solid fa-xmark"></i>
                    </button>
                  )}
              </div>
            </div>
          ))}

          <div className="flex justify-end">
            <button
              type="button"
              onClick={addField}
              className="!py-2 !px-2 border border-orange-500 !text-white rounded-lg !bg-orange-500 hover:!bg-orange-600 !h-auto transition-all duration-300 text-sm"
              aria-label="Add new field"
            >
              <i className="fa-regular fa-plus text-sm"></i>
              <span className="ml-1">Add Field</span>
            </button>
          </div>

          <div className="flex items-center justify-end mt-4 gap-2">
            <Button
              buttonLabel={"Cancel"}
              buttonIcon={"fa-regular fa-xmark"}
              buttonIconPosition={"left"}
              buttonClasses="!py-2 !px-2 border border-orange-500 !text-orange-500 rounded-lg !bg-transparent !h-auto"
              buttonLabelClasses={"!text-sm"}
              buttonFunction={() => setWebsiteLinkModal({ isOpen: false })}
            />
            <Button
              loading={loading}
              buttonLabel={loading ? "Saving..." : "Save"}
              buttonIcon={"fa-light fa-floppy-disk"}
              buttonIconPosition={"left"}
              buttonType="submit"
              buttonClasses="!py-2 !px-2 border border-orange-500 !text-white rounded-lg !bg-orange-500 hover:!bg-orange-600 !h-auto transition-all duration-300"
              buttonLabelClasses={"!text-sm"}
              isDisable={loading}
            />
          </div>
        </form>
      </Modal>
      <DeleteModal
        title="Delete Fields"
        customMessage="Are you want to sure to delete fields?"
        deleteButtonLabel="Delete"
        open={deleteModal?.isOpen}
        loading={deleteModal?.loading}
        onClose={() => setDeleteModal((pre) => ({ ...pre, isOpen: false }))}
        deleteHandler={deletefunction}
      />
    </div>
  );
};

export default WebsiteLinkManage;

import React, { useEffect, useState } from "react";
import Tabs from "../../../components/elements/Tabs";
import ApiKeysSetting from "./ApiKeysSetting";
import Settings from "./Settings";
import { useDispatch } from "react-redux";
import { getSettings } from "../../../redux/slice/settingsSlice";
import { siteSettingDetails } from "../../../services/SettingService";
import { toast } from "react-toastify";

const SettingsPage = () => {
  const [selectedTabId, setSelectedTabId] = useState();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState({});

  const tabData = [
    {
      _id: 1,
      name: "Settings",
      component: (
        <Settings
          loading={loading}
          setLoading={setLoading}
          details={details}
          setDetails={setDetails}
        />
      ),
    },
    {
      _id: 2,
      name: "Api Keys",
      component: (
        <ApiKeysSetting
          loading={loading}
          setLoading={setLoading}
          details={details}
          setDetails={setDetails}
        />
      ),
    },
  ];

  useEffect(() => {
    siteSettingDetails().then((res) => {
      if (res?.status === 200) {
        setDetails(res?.data);
        dispatch(getSettings(res?.data));
      } else {
        toast.error("Something went wrong!");
      }
    });
  }, []);

  return (
    <div className="relative w-full">
      <Tabs
        navTabClass={" !w-1/2"}
        tabs={tabData}
        selectedTabId={selectedTabId}
        onTabChange={(tabId) => setSelectedTabId(tabId)}
      />
    </div>
  );
};

export default SettingsPage;

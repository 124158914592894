import React, { useEffect, useState } from "react";
import Button from "../../../components/form/Button";
import Input from "../../../components/form/Input";
import useForm from "../../../hooks/useForm";
import { toast } from "react-toastify";
import { formValidate } from "../../../helpers/formValidate";
import {
  siteSettingDetails,
  updateSetting,
} from "../../../services/SettingService";
import ImageUploader from "../../../components/form/ImageUploader";
import { getSettings } from "../../../redux/slice/settingsSlice";
import { useDispatch } from "react-redux";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { setTitle } from "../../../helpers/MetaTag";
import { Validation } from "../../../helpers/Validation";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

const Settings = ({
  loading,
  setLoading = () => {},
  details,
  setDetails = () => {},
}) => {
  setTitle("ORG Chart Admin | Settings");
  const dispatch = useDispatch();
  const [logo, setLogo] = useState([]);
  const [favicon, setFavIcon] = useState([]);
  const [phone, setPhone] = useState("");

  const validation = {
    title: { required: true, message: "Please enter title!" },
    email: { required: true, message: "Please enter email!" },
  };

  const { values, handleChange, handleSubmit, errors, setFieldsValue } =
    useForm({}, validation);

  useEffect(() => {
    siteSettingDetails().then((res) => {
      if (res?.status === 200) {
        setDetails(res?.data);
        dispatch(getSettings(res?.data));
      } else {
        toast.error("Something went wrong!");
      }
    });
  }, []);

  useEffect(() => {
    if (details) {
      setFieldsValue({
        title: details?.title || "",
        email: details?.email || "",
        copyright: details?.copyright || "",
      });

      if (details?.logo?.url) {
        setLogo(details.logo.url);
      }
      if (details?.favicon?.url) {
        setFavIcon(details?.favicon?.url);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [details]);

  useEffect(() => {
    if (details?.phone) {
      let phoneNumber = String(details?.phone);
      if (!phoneNumber.startsWith("+")) {
        phoneNumber = `+${phoneNumber}`;
        setPhone(phoneNumber);
      }
    } else {
      setPhone("");
    }
  }, [details]);

  const handlePhoneInputChange = (value) => {
    setPhone(value);
  };

  const onSubmit = (values) => {
    setLoading(true);
    if (!Validation("email", values.email)) {
      toast.error("Invalid email format. Please enter a valid email.");
      setLoading(false);
      return;
    }
    updateSetting({
      id: details?._id,
      title: values?.title,
      email: values?.email,
      phone: phone || "",
      copyright: values?.copyright,
      logo: logo,
      favicon: favicon,
    }).then((res) => {
      if (res?.status === 200) {
        dispatch(getSettings(res?.data));
        setDetails(res?.data);
        toast?.success(res?.message);
      }
      setLoading(false);
    });
  };

  return (
    <div className="border bg-white border-gray-200 p-4 shadow rounded-md">
      <div className="flex justify-between items-center mb-3">
        <div className="text-2xl font-bold">Settings</div>
      </div>
      <form onSubmit={(e) => handleSubmit(e, onSubmit)}>
        <div className="grid grid-cols-12 gap-4">
          <div className="image bg-white p-4 col-span-12 lg:col-span-3 border-r border-slate-200 h-auto">
            <ImageUploader
              label=""
              labelClasses="text-lg font-bold"
              isMultiple={false}
              image={logo}
              setImage={setLogo}
              removeIcon={true}
              buttonLabel={logo ? "Change logo" : "Upload logo"}
              inputClasses="w-40 h-40 p-3"
              inputbgcolour="!bg-[#c5c5c5]"
              imageobjectfit="!object-contain"
            />
            <div className="mt-2">
              <ImageUploader
                label=""
                labelClasses="text-lg font-bold"
                isMultiple={false}
                image={favicon}
                setImage={setFavIcon}
                removeIcon={true}
                buttonLabel={favicon ? "Change favicon" : "Upload favicon"}
                inputClasses="w-40 h-40 p-7"
              />
            </div>
          </div>
          <div className="input bg-white col-span-12 lg:col-span-9">
            <div className="grid lg:grid-cols-2 gap-3">
              <div className="relative col-span-2">
                <Input
                  label={"Title"}
                  inputType={"text"}
                  value={values?.title}
                  inputPlaceholder={"Enter title"}
                  inputName="title"
                  onChange={handleChange}
                  isValidate={true}
                  {...formValidate(errors, "title")}
                />
              </div>
              <div className="relative">
                <Input
                  label={"Email"}
                  inputType={"text"}
                  value={values?.email}
                  inputPlaceholder={"Enter email"}
                  inputName="email"
                  onChange={handleChange}
                  isValidate={true}
                  {...formValidate(errors, "email")}
                />
              </div>
              <div className="relative mt-0.5">
                <div className="text-sm font-medium text-slate-500">Phone</div>
                <PhoneInput
                  placeholder="(xxx) xxx-xxxx"
                  value={phone}
                  onChange={handlePhoneInputChange}
                  // defaultCountry="US"
                  international={false}
                />
              </div>
              <div className="relative col-span-2">
                <Input
                  label={"Copyright"}
                  inputType={"text"}
                  value={values?.copyright}
                  inputPlaceholder={"Enter copyright"}
                  inputName="copyright"
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="w-full flex items-center justify-end gap-3 mt-4">
              <div
                className="flex justify-end items-center"
                data-tooltip-id="save-setting"
                data-tooltip-place="left"
              >
                <Button
                  buttonType={"submit"}
                  buttonIcon={"fa-light fa-floppy-disk"}
                  buttonIconPosition={"left"}
                  buttonLabel="Save"
                  isDisable={loading ? true : false}
                  loading={loading}
                />
                <Tooltip
                  id="save-setting"
                  className="!text-slate-500 !text-sm !bg-slate-950 !bg-opacity-100 z-100"
                  render={() => (
                    <div className="text-white">Click to save it.</div>
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Settings;

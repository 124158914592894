import React, { useCallback, useEffect, useState } from "react";
import Modal from "../../elements/Modal";
import { formValidate } from "../../../helpers/formValidate";
import Input from "../../form/Input";
import useForm from "../../../hooks/useForm";
import Button from "../../form/Button";
import { toast } from "react-toastify";
import {
  employeeAdd,
  employeeEdit,
} from "../../../services/frontService/EmployeeService";
import ImageUploader from "../../form/ImageUploader";
import noEmpImg from "../../../assets/images/noEmpImg.svg";
import { setProfile } from "../../../redux/slice/profileSlice,";
import { useDispatch } from "react-redux";
import SelectDropdownSearch from "../../form/SelectDropdownSearch";
import Radio from "../../form/Radio";
import { useSelector } from "react-redux";
import { employeeModal } from "../../../redux/slice/employeeSlice";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import PositionManage from "./PositionManage";
import MultiselectDropdown from "../../form/MultiselectDropdown";
import { getDepartmentList } from "../../../services/frontService/DepartmentService";
import { useDebounce } from "../../../helpers/useDebounce";
import DepartmentMange from "./DepartmentMange";

export default function EmployeeManage({
  setList,
  pos_list,
  keywords,
  setKeywords = () => {},
  loadPoslist = () => {},
  setEmpAddtype = () => {},
}) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [userType, setUserType] = useState("COMPANY-EMPLOYEE");
  const [image, setImage] = useState("");
  const [phone, setPhone] = useState("");
  const [selectedDepartments, setSelectedDepartments] = useState([]);

  const empModal = useSelector((state) => state.empModal.data);

  const data = empModal?.data && empModal?.data;

  const validation = {
    firstName: { required: true, message: "Please enter  first name!" },
    lastName: { required: true, message: "Please enter  last name!" },
    email: { required: true, message: "Please enter  email !" },
    positionId: {
      required: userType !== "COMPANY-ADMINISTRATOR" ? true : false,
      message: "Please choose a position !",
    },
    departmentId: {
      required:
        userType !== "COMPANY-ADMINISTRATOR" && selectedDepartments?.length <= 0
          ? true
          : false,
      message: "Please choose a department !",
    },
  };

  const { values, handleChange, handleSubmit, errors, setFieldsValue } =
    useForm({}, validation);

  useEffect(() => {
    if (values?.positionId) {
      setKeywords("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values?.positionId]);

  const deptKeyword = useDebounce(keywords.deptKeyword, 400);

  const [depList, setDepList] = useState({ loading: false, data: [] });
  const [department, setDepartment] = useState([]);
  const [isDepModal, setDepModal] = useState({
    data: {},
    isOpen: false,
  });
  const loadDeplist = useCallback(() => {
    setDepList({ loading: true, data: [] });
    const payload = {
      limit: 10000,
      ...(deptKeyword && { keyword: deptKeyword }),
    };
    getDepartmentList(payload).then((res) => {
      if (res?.status === 200) {
        const selectedIds =
          data?.departmentDetails?.map((item) => item?._id) || [];
        setDepList({ loading: false, data: res.docs });
        setDepartment(
          res?.docs?.map(({ _id, name, departmentEmpCount }) => ({
            _id,
            name,
            value: _id,
            checked: selectedIds.includes(_id),
            count: departmentEmpCount,
          }))
        );
      } else {
        setDepList((prev) => ({ ...prev, data: [], loading: false }));
        toast.error(res?.message);
      }
    });
  }, [deptKeyword, data]);

  useEffect(() => {
    loadDeplist();
  }, [loadDeplist]);

  useEffect(() => {
    if (data) {
      setFieldsValue({
        firstName: data?.firstName,
        lastName: data?.lastName,
        email: data?.email,
        positionId: data?.positionDetails?._id,
      });
      setImage(data?.image?.url);
      const rolePriority = [
        "COMPANY-ADMINISTRATOR",
        "COMPANY-MANAGER",
        "COMPANY-EMPLOYEE",
      ];
      const highestPriorityRole = data?.roleDetails?.reduce(
        (prev, curr) => {
          return rolePriority.indexOf(curr.code) <
            rolePriority.indexOf(prev.code)
            ? curr
            : prev;
        },
        { code: "COMPANY-EMPLOYEE" }
      );
      setUserType(highestPriorityRole?.code || "COMPANY-EMPLOYEE");
    }
  }, [data]);

  useEffect(() => {
    if (data?.phone) {
      let phoneNumber = String(data?.phone);
      if (!phoneNumber.startsWith("+")) {
        phoneNumber = `+${phoneNumber}`;
        setPhone(phoneNumber);
      }
    } else {
      setPhone("");
    }
  }, [data]);
  const handlePhoneInputChange = (value) => {
    setPhone(value);
  };

  const onSubmit = async (values) => {
    setLoading(true);
    const departments = {};

    if (
      selectedDepartments &&
      Array.isArray(selectedDepartments) &&
      selectedDepartments.length > 0
    ) {
      selectedDepartments.forEach((item, i) => {
        departments[`departmentId[${i}]`] = item;
      });
    }
    try {
      if (values.positionId === "other" && !positionData?._id) {
        toast.error("Select a valid position");
        setLoading(false);
        return;
      }
      const payload = {
        firstName: values?.firstName,
        lastName: values?.lastName,
        email: values?.email,
        positionId: positionData?._id ? positionData?._id : values?.positionId,
        userType,
        phone: phone || "",
        image: image ? image : "null",
        ...departments,
      };
      const response = data?._id
        ? await employeeEdit({
            ...payload,
            id: data?._id,
          })
        : await employeeAdd(payload);
      setLoading(false);
      if (response?.status === 201 || response?.status === 200) {
        toast.success(response?.message);
        setPositionData({});
        setEmpAddtype({ isOpen: false });
        dispatch(employeeModal({ data: {}, isOpen: false }));
        loadDeplist();
        loadPoslist();
        setList((prev) => ({
          ...prev,
          data:
            response?.status === 200
              ? prev.data.map((item) =>
                  item?._id === response?.data?._id ? response?.data : item
                )
              : [response?.data, ...prev.data],
        }));
        dispatch(
          setProfile((pre) => ({
            ...pre,
            employeeCount:
              response?.status === 201
                ? pre?.employeeCount + 1
                : pre?.employeeCount,
          }))
        );
      } else {
        toast.error(response?.data?.message || response.message);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong!");
    }
  };
  const handleSelectedItems = (val) => {
    setSelectedDepartments(val);
  };
  const handleOtherClick = () => {
    setDepModal({ data: {}, isOpen: true });
  };
  const radioOptions = [
    { label: "Administrator", value: "COMPANY-ADMINISTRATOR" },
    { label: "Evaluator", value: "COMPANY-MANAGER" },
    { label: "Employee", value: "COMPANY-EMPLOYEE" },
  ];
  const [positionData, setPositionData] = useState({});
  const [isPositionModal, setPositionModal] = useState({
    data: {},
    isOpen: false,
  });

  useEffect(() => {
    if (values?.positionId === "other") {
      setPositionModal({ data: {}, isOpen: true });
    }
  }, [values?.positionId]);
  return (
    <Modal
      title={data?._id ? "Edit Employee" : "Add Employee"}
      size="xl4"
      open={empModal?.isOpen}
      onClose={() => {
        dispatch(employeeModal({ data: {}, isOpen: false }));
      }}
      modaltitleClasses={"!text-slate-200 text-lg"}
      headerClass={"!bg-black !rounded-t-lg !text-white"}
      childrenClasses={"!rounded-b-lg"}
    >
      <form
        onSubmit={(event) => handleSubmit(event, onSubmit)}
        className="grid grid-cols-12 gap-4 p-6 lg:pl-0 bg-white rounded-lg shadow-md"
      >
        <div className="image bg-white col-span-12 lg:col-span-4 border-0 lg:border-r border-slate-200 h-auto">
          <h2 className="text-lg font-semibold text-slate-700 text-center border-b border-slate-200 mb-2">
            Employee Image
          </h2>
          <div className="relative">
            <ImageUploader
              label=""
              image={image}
              altImage={noEmpImg}
              isMultiple={false}
              setImage={setImage}
              buttonLabelClasses="!text-xs"
              buttonIcon="fa-upload text-xs"
              labelClasses="text-lg font-bold"
              buttonClasses="!px-1 py-2 bg-blue-500 text-white rounded-lg hover:bg-orange-600 w-36 mx-auto"
              inputClasses="w-40 h-40 hover:bg-slate-200 rounded-full border-2 border-gray-300 overflow-hidden"
              buttonLabel={data?.image ? "Change image" : "Upload image"}
            />
          </div>
        </div>

        <div className="input bg-white col-span-12 lg:col-span-8">
          <div className="md:grid lg:grid-cols-2 gap-3 space-y-3 md:space-y-0">
            <div className="relative">
              <Input
                isInputGroup={true}
                inputGroupIcon={"fa-light fa-user text-slate-400"}
                inputGroupPosition={"left"}
                label={"First Name"}
                inputPlaceholder={"Enter first name"}
                inputClasses={"pl-2"}
                labelClasses={"!text-sm !font-medium"}
                inputType={"text"}
                value={values.firstName}
                isValidate={true}
                inputName={"firstName"}
                onChange={handleChange}
                charLimit={100}
                {...formValidate(errors, "firstName")}
              />
            </div>
            <div className="relative">
              <Input
                isInputGroup={true}
                inputGroupIcon={"fa-light fa-user text-slate-400"}
                inputGroupPosition={"left"}
                label={"Last Name"}
                charLimit={100}
                inputPlaceholder={"Enter last name"}
                inputClasses={"pl-2"}
                labelClasses={"!text-sm !font-medium"}
                inputType={"text"}
                value={values.lastName}
                isValidate={true}
                inputName={"lastName"}
                onChange={handleChange}
                {...formValidate(errors, "lastName")}
              />
            </div>
            <div className="relative ">
              <Input
                isInputGroup={true}
                inputGroupIcon={"fa-light fa-envelope text-slate-400"}
                inputGroupPosition={"left"}
                label={"Email"}
                inputPlaceholder={"Enter email"}
                inputClasses={"pl-2"}
                labelClasses={"!text-sm !font-medium"}
                inputType={"email"}
                value={values.email}
                inputName={"email"}
                isValidate={true}
                onChange={handleChange}
                {...formValidate(errors, "email")}
              />
            </div>
            <div className="relative">
              <div className="text-sm font-medium text-slate-500">Phone</div>
              <div className="border rounded-md focus:ring focus:ring-blue-200 w-full mt-1">
                <PhoneInput
                  placeholder="(xxx) xxx-xxxx"
                  value={phone}
                  onChange={handlePhoneInputChange}
                  // defaultCountry="US"
                  international={false}
                />
              </div>
            </div>
            <div className="relative">
              <SelectDropdownSearch
                label={"Position"}
                placeholder={"Choose an position"}
                isSearch={true}
                xPlacement={"bottom"}
                buttonArrowIcon={"fa-solid fa-chevron-down"}
                tabTextColor={"text-slate-700"}
                selectName={"positionId"}
                loading={pos_list?.loading}
                value={values?.positionId}
                onChange={handleChange}
                isValidate={true}
                dropdownButtonClass="!px-3 !border-gray-200 !w-full"
                {...formValidate(errors, "positionId")}
                dropdownData={[
                  ...(Array.isArray(pos_list?.data)
                    ? pos_list?.data?.map(({ _id, title }) => ({
                        name: title,
                        _id: _id,
                      }))
                    : []),
                  {
                    name: "Other",
                    _id: "other",
                  },
                ]}
                onSearch={(val) => {
                  setKeywords((pre) => ({ ...pre, posKeyword: val }));
                }}
                newAddedPosition={positionData}
                setPositionData={setPositionData}
              />
            </div>

            {userType !== "COMPANY-ADMINISTRATOR" ? (
              <div className="relative">
                <MultiselectDropdown
                  label={"Departments"}
                  buttonLabel={"Select departments"}
                  xPlacement={"bottom"}
                  isCheckbox={true}
                  isSearch={true}
                  dropdownData={department}
                  setDropdownData={setDepartment}
                  value={selectedDepartments}
                  buttonArrowIconClasses={"!text-sm !text-slate-500 !right-3"}
                  buttonLabelClasses={"!font-medium !text-gray-600"}
                  buttonsinglelabelclass={"!text-xs md:!text-sm !font-medium"}
                  selectedItems={handleSelectedItems}
                  isValidate={true}
                  {...formValidate(errors, "departmentId")}
                  onSearch={(e) =>
                    setKeywords((pre) => ({
                      ...pre,
                      deptKeyword: e,
                    }))
                  }
                  isOther={true}
                  handleOtherClick={handleOtherClick}
                />
              </div>
            ) : (
              <div></div>
            )}
            <div className="relative">
              <h2 className="text-sm font-medium text-slate-600">User Role</h2>
              <div className="flex flex-col md:flex-row gap-4 mt-2">
                {radioOptions.map((option) => (
                  <Radio
                    key={option?.value}
                    radioLabel={option?.label}
                    radioValue={option?.value}
                    onChange={(e) => setUserType(e.target.value)}
                    isChecked={userType === option?.value}
                    radioName="userRole"
                    divClass="!overflow-visible"
                  />
                ))}
              </div>
            </div>
            <div className="relative flex justify-end items-end">
              <Button
                loading={loading}
                buttonLabel={
                  data?._id
                    ? loading
                      ? "Saving..."
                      : "Save"
                    : loading
                    ? "Adding..."
                    : "Add"
                }
                isDisable={loading ? true : false}
                buttonIcon={
                  data?._id ? "fa-light fa-floppy-disk" : "fa-regular fa-plus"
                }
                buttonIconPosition={"left"}
                buttonType="submit"
                buttonClasses="flex-shrink-0"
              />
            </div>
          </div>
        </div>
      </form>
      <PositionManage
        loadPosList={loadPoslist}
        isPositionModal={isPositionModal}
        setPositionModal={setPositionModal}
        setPositionData={setPositionData}
      />
      <DepartmentMange
        loadDeplist={loadDeplist}
        isDepModal={isDepModal}
        setDepModal={setDepModal}
      />
    </Modal>
  );
}

import { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const Editor = ({ type = "", value = "", onChange = () => {}, label = "" }) => {
  const Quill = ReactQuill.Quill;
  var Font = Quill.import("formats/font");
  Font.whitelist = ["Roboto", "Raleway", "Montserrat", "Lato", "Rubik", "Inter", "Pacifico"];
  Quill.register(Font, true);

  var Size = Quill.import("attributors/style/size");
  Size.whitelist = ["small", false, "large", "huge"];
  Quill.register(Size, true);

  const [textValue, setTextValue] = useState(value);

  useEffect(() => {
    if (value) {
      setTextValue(value);
    }
  }, [value, type]);

  const modules = {
    toolbar: [
      [{ font: Font.whitelist }],
      [{ size: Size.whitelist }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      [{ script: "sub" }, { script: "super" }],
      ["blockquote", "code-block"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
      ["link", "image", "video"],
      ["clean"],
    ],
  };

  return (
    <div>
      {label && <label className="block mb-2 text-slate-500">{label}</label>}
      <ReactQuill
        className="bg-white"
        theme="snow"
        value={textValue}
        onChange={(val) => {
          setTextValue(val);
          onChange(val);
        }}
        placeholder={"Write something awesome"}
        modules={modules}
      />
    </div>
  );
};

export default Editor;

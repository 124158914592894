import { Link } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import Image from "../../../elements/Image";
import noEmpImg from "../../../../assets/images/noEmpImg.svg";
import Button from "../../../form/Button";

export default function EmployeeItem({
  emp,
  index,
  dispatch = () => {},
  employeeModal = () => {},
  setDeleteModal = () => {},
}) {
  return (
    <Link
      to={`/directory/employee/${emp?._id}`}
      key={index}
      className="col-span-1 relative flex flex-col transition-all bg-white duration-300 rounded-2xl employee-item"
    >
      <div className="relative w-full">
        <div className="w-full overflow-hidden rounded-2xl aspect-square">
          <Image
            src={emp?.image?.url || noEmpImg}
            alt={`empImg`}
            className="object-cover !w-full !h-full"
            effect={"blur"}
          />
        </div>
        <div className="absolute top-2 right-0 px-2 py-2 rounded-l-full edit-button duration-300 z-0">
          <div className="flex space-x-2">
            <div data-tooltip-id="delete-employee">
              <Button
                buttonIcon={"fa-regular fa-trash-can text-sm"}
                buttonIconPosition={"left"}
                buttonClasses={
                  "w-6 h-6 !border !bg-slate-200 !border-red-600 !text-red-600 hover:!bg-red-100 !rounded-full flex items-center justify-center"
                }
                buttonFunction={(e) => {
                  e.preventDefault();
                  setDeleteModal((pre) => ({
                    ...pre,
                    isOpen: true,
                    ids: [emp?._id],
                  }));
                }}
              />
              <Tooltip
                id="delete-employee"
                place="bottom"
                className="!text-slate-500 !text-xs !bg-slate-950 !bg-opacity-100 z-100"
                render={() => (
                  <div className="text-white">Click to delete employee.</div>
                )}
              />
            </div>
            <div data-tooltip-id="edit-employee">
              <Button
                buttonIcon={"fa-regular fa-pen-to-square text-sm"}
                buttonIconPosition={"left"}
                buttonClasses={
                  "w-6 h-6 !border !bg-slate-50 !border-black !text-black hover:!bg-blue-100 !rounded-full flex items-center justify-center"
                }
                buttonFunction={(e) => {
                  e.preventDefault();
                  dispatch(employeeModal({ data: emp, isOpen: true }));
                }}
              />
              <Tooltip
                id="edit-employee"
                place="bottom"
                className="!text-slate-500 !text-xs !bg-slate-950 !bg-opacity-100 z-100"
                render={() => (
                  <div className="text-white">
                    Click to edit employee details.
                  </div>
                )}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className="flex flex-col space-y-2 items-start justify-between pt-3 pb-5 pr-4"
        data-tooltip-id={`emp-details-${emp._id}`}
      >
        <div className="flex flex-col space-y-4 items-start justify-center">
          <div className="relative space-y-1">
            <h3 className="font-semibold text-sm text-black !leading-none capitalize flex items-center mb-1 font-smooth-antialiased font-smooth-subpixel">
              {`${emp?.firstName} ${emp?.lastName}`.substring(0, 14) +
                (`${emp?.firstName} ${emp?.lastName}`.length > 14 ? "..." : "")}
              {emp?.roleDetails?.some(
                (role) => role?.code === "COMPANY-MANAGER"
              ) && (
                <i
                  data-tooltip-id="manager"
                  className="fa-regular fa-user-crown text-xs text-orange-500 ml-2 border px-1 py-0.5 border-orange-200 rounded-lg"
                />
              )}
              {emp?.roleDetails?.some(
                (role) => role?.code === "COMPANY-ADMINISTRATOR"
              ) && (
                <i
                  data-tooltip-id="administrator"
                  className="fa-regular fa-user-shield text-xs text-orange-500 ml-2 border px-1 py-0.5 border-orange-200 rounded-lg"
                />
              )}
            </h3>
            <Tooltip
              id={`manager`}
              place="left"
              className="!text-slate-500 !text-sm !bg-slate-950 !bg-opacity-100 z-10"
              render={() => (
                <div className="text-white">This is an evaluator.</div>
              )}
            />
            <Tooltip
              id={`administrator`}
              place="left"
              className="!text-slate-500 !text-sm !bg-slate-950 !bg-opacity-100 z-10"
              render={() => (
                <div className="text-white">This is an administrator.</div>
              )}
            />
            <div className="text-sm font-light text-black !leading-none pt-1">
              {emp?.positionDetails?.title || "No position specified"}
            </div>
          </div>
          {/* <div className="flex items-center flex-wrap gap-1 text-sm font-medium text-gray-700 !leading-none">
            {emp?.departmentDetails?.slice(0, 2)?.length > 0 ? (
              emp?.departmentDetails?.slice(0, 2)?.map((department, idx) => (
                <span
                  key={idx}
                  className="inline-block bg-orange-100 text-orange-600 px-2 py-1 rounded-lg text-xs font-medium mr-1"
                >
                  {department?.name || "Unknown"}
                </span>
              ))
            ) : (
              <span>No department specified</span>
            )}

            {emp?.departmentDetails?.slice(2)?.length > 0 && (
              <span
                className="inline-block text-orange-600 cursor-pointer text-xs"
                data-tooltip-id="more-departments"
              >
                +{emp?.departmentDetails?.slice(2)?.length} more
              </span>
            )}
          </div> */}
        </div>
        <Tooltip
          id={`emp-details-${emp._id}`}
          place="top"
          className="!text-slate-500 !text-sm !bg-slate-950 !bg-opacity-100 z-10"
          render={() => (
            <div className="text-white">
              <div>Name : {`${emp?.firstName} ${emp?.lastName}`.substring(0, 14) +
                (`${emp?.firstName} ${emp?.lastName}`.length > 14 ? "..." : "")}</div>
              <div>Position : {emp?.positionDetails?.title || "N/A"}</div>
              <div>
                Departments :{" "}
                {emp?.departmentDetails && emp?.departmentDetails.length > 1 ? (
                  <div>
                    {emp?.departmentDetails
                      ?.map((department) => department?.name)
                      ?.join(", ")}
                  </div>
                ) : (
                 "N/A"
                )}
              </div>
            </div>
          )}
        />
      </div>
    </Link>
  );
}

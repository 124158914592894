import { apiRequest } from "../util/apiUtils";

export const profileDetails = async () => {
  try {
    const res = await apiRequest("get", `profile`);
    return res?.data;
  } catch (error) {
    return error;
  }
};

export const profileUpdate = async (params) => {
  try {
    const res = await apiRequest(
      "put",
      `profile/update`,
      {
        body: {
          ...params,
        },
      },
      "multipart/form-data"
    );
    return res?.data;
  } catch (err) {
    return err;
  }
};
export const changePassword = async (params) => {
  try {
    const res = await apiRequest("put", `profile/change-password`, {
      body: {
        ...params,
      },
    });
    return res?.data;
  } catch (err) {
    return err;
  }
};
export const sendOtp = async (params) => {
  try {
    const res = await apiRequest(
      `${"post"}`,
      `${"/send-otp"}`,
      {
        body: {
          ...params,
        },
      }
      // "multipart/form-data"
    );
    const data = res.data;
    return res;
  } catch (err) {
    return err;
  }
};

export const verifyOtp = async (params) => {
  try {
    const res = await apiRequest(
      `${"post"}`,
      `${"/verify-otp"}`,
      {
        body: {
          ...params,
        },
      }
      // "multipart/form-data"
    );
    const data = res.data;
    return res;
  } catch (err) {
    return err;
  }
};
export const forgotPassword = async (params) => {
  try {
    const res = await apiRequest(
      `${"post"}`,
      `${"/forget-password"}`,
      {
        body: {
          ...params,
        },
      }
      // "multipart/form-data"
    );
    const data = res.data;
    return res;
  } catch (err) {
    return err;
  }
};
export const getProfileDetails = async (
  setResData = () => {},
  setLoaded = () => {}
) => {
  try {
    const res = await apiRequest("get", "profile/details");

    const data = res.data;
    if (res?.data?.success) setResData(res.data.docs);

    setLoaded(true);
    return res;
  } catch (err) {
    setLoaded(true);
  }
};

export const updateProfile = async (params) => {
  try {
    const res = await apiRequest(
      `${params?.id ? "put" : "post"}`,
      `profile/edit`,
      {
        body: {
          ...params,
        },
      },
      "multipart/form-data"
    );
    const data = res.data;
    return res;
  } catch (err) {
    return err;
  }
};

export const getVerifyTokan = async (params) => {
  try {
    const res = await apiRequest("post", "verify-token", {});
    return res;
  } catch (error) {
    return error;
  }
};

export const acceptUser = async (params) => {
  try {
    const res = await apiRequest("post", `user/status`, {
      body: {
        ...params,
      },
    });
    return res?.data;
  } catch (err) {
    return err;
  }
};

import React from "react";
import Button from "../form/Button";
import Modal from "../elements/Modal";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const LogoutModal = ({
  isOpen = false,
  setOpen = () => {},
  onClose = () => {},
  title = "Logout ?",
  loading = false,
}) => {
  const role = localStorage.getItem("role");
  const navigate = useNavigate();
  const onLogout = () => {
    localStorage.removeItem("auth_token");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("adminData");
    localStorage.removeItem("role");
    setOpen(false);
    toast.success("Logout Successfull!");
    setTimeout(function () {
      navigate(role === "ADMIN" ? "/admin/signin" : "/signin");
    }, 1000);
  };

  return (
    <Modal
      open={isOpen}
      onClose={() => onClose(setOpen(false))}
      title={title}
      size="md"
      titleIcon={"fa-sharp fa-regular fa-circle-exclamation"}
      modaltitleClasses={"!text-slate-200 text-[15px]"}
      headerClass={"!bg-black !rounded-t-lg !text-white"}
      childrenClasses={"!rounded-b-lg"}
    >
      <div className="text-center mb-2 text-slate-500">
        Are You Sure You Want To Logout ?
      </div>
      <div className="">
      <div className="flex gap-2 justify-center">
        <Button
          buttonIconPosition={"left"}
          buttonIcon={"fa-regular fa-close"}
          buttonLabel={"Cancel"}
          buttonFunction={() => onClose(setOpen(false))}
          buttonClasses="!py-2 !px-2 border border-orange-500 !text-orange-500 rounded-lg !bg-transparent !h-auto"
        />
        <Button
          buttonFunction={onLogout}
          isDisable={loading}
          isLoaderDisabled={true}
          buttonLabel={"Yes, I'm sure"}
            buttonClasses={"!py-2 !px-2 rounded-lg text-white !h-auto bg-red-500 hover:bg-red-600"}
        />
      </div>
      </div>
    </Modal>
  );
};

export default LogoutModal;

import { toast } from "react-toastify";

// STEP
export const verticalStepPathFunc = (linkData) => {
  if (linkData.source.data.name === "hidden") {
    return "";
  }
  const { x: sx, y: sy } = linkData.source;
  const { x: tx, y: ty } = linkData.target;

  const midY = (sy + ty) / 2;

  return `M${sx},${sy}V${midY}H${tx}V${ty}`;
};

// color themes
export const themes = [
  "#f78c6b",
  "#5c8ef2",
  "#e06377",
  "#d474d5",
  // "navy",
  // "green",
  // "orange",
  // "blue",
  // "rose",
  // "lime",
  // "indigo",
  // "cyan",
  // "brown",
  // "magenta",
  // "olive",
  // "gold",
];

// DFS > Depth first Search----------------------->
export function dfs({
  id,
  tree,
  node,
  action = "edit",
  direction = "right",
  isMultiple = false,
}) {
  function traverse(curNode, parentNode) {
    if (curNode._id === id) {
      return handleNodeAction({
        curNode,
        action,
        node,
        parentNode,
        direction,
        tree,
        isMultiple,
      });
    }

    if (curNode.children && curNode.children.length > 0) {
      for (const child of curNode.children) {
        const result = traverse(child, curNode);
        if (result) return result;
      }
    }

    return null;
  }

  return traverse(tree, null);
}

function handleNodeAction({
  curNode,
  action,
  node,
  parentNode,
  direction,
  tree,
  isMultiple,
}) {
  switch (action) {
    case "add":
      curNode.children.push(node);
      break;

    case "addSibling":
      addSibling(curNode, parentNode, node, direction);
      break;

    case "edit":
    case "replace":
      editNodeDetails(curNode, node, tree, action);
      break;

    case "delete":
      return deleteNode(curNode._id, tree, isMultiple);

    case "unassign":
      unassignUser(curNode, tree);
      break;

    default:
      toast.error(`Unknown action: ${action}`);
      break;
  }

  return { ...tree };
}

function addSibling(curNode, parentNode, node, direction) {
  if (!parentNode) {
    toast.error("Parent node not found for adding sibling");
    return;
  }

  const index = parentNode.children.indexOf(curNode);
  if (direction === "left") {
    parentNode.children.splice(index, 0, node);
  } else if (direction === "right") {
    parentNode.children.splice(index + 1, 0, node);
  }
}

function editNodeDetails(curNode, node, tree, action) {
  if (action === "replace") {
    removeDuplicateUserDetails(node?.userDetails?._id, tree);
  }
  curNode.cardNumber = node.cardNumber;
  curNode.userDetails = {
    ...curNode?.userDetails,
    ...node?.userDetails,
  };

  curNode.theme = {
    ...curNode?.theme,
    ...node?.theme,
  };
  updateDuplicateUserDetails(node?.userDetails?._id, curNode, tree);
}

function removeDuplicateUserDetails(userId, tree) {
  function traverseAndRemove(curNode) {
    if (curNode?.userDetails && curNode?.userDetails?._id === userId) {
      delete curNode?.userDetails;
    }

    if (curNode?.children && curNode?.children?.length > 0) {
      for (const child of curNode?.children) {
        traverseAndRemove(child);
      }
    }
  }

  traverseAndRemove(tree);
}
function updateDuplicateUserDetails(userId, updatedNode, tree) {
  function traverseAndUpdate(curNode) {
    if (curNode?.userDetails && curNode?.userDetails?._id === userId) {
      curNode.userDetails = {
        ...curNode?.userDetails,
        ...updatedNode.userDetails,
      };

      curNode.theme = {
        ...curNode?.theme,
        ...updatedNode.theme,
      };
    }

    if (curNode?.children && curNode?.children?.length > 0) {
      for (const child of curNode?.children) {
        traverseAndUpdate(child);
      }
    }
  }

  traverseAndUpdate(tree);
}

function deleteNode(id, tree, isMultiple) {
  let deletedNode = null;

  function traverseAndDelete(curNode, parentNode = null) {
    if (curNode.children && curNode.children.length > 0) {
      const index = curNode.children.findIndex((child) => child._id === id);

      if (index !== -1) {
        deletedNode = curNode.children[index];
        if (isMultiple) {
          curNode.children.splice(index, 1);
        } else {
          reattachChildren(curNode, index);
        }
        return { ...tree };
      }

      for (const child of curNode.children) {
        const result = traverseAndDelete(child, curNode);
        if (result) return result;
      }
    }
    return null;
  }

  const resultTree = traverseAndDelete(tree);

  if (deletedNode) {
    updateCardNumbersForUserId(
      deletedNode.userId,
      tree,
      deletedNode.cardNumber
    );
  }

  return resultTree;
}

function updateCardNumbersForUserId(userId, tree, deletedCardNumber) {
  function traverseAndUpdateCardNumber(curNode) {
    if (curNode?.userDetails && curNode?.userDetails?._id === userId) {
      if (curNode.cardNumber > 1 || curNode.cardNumber > deletedCardNumber) {
        curNode.cardNumber -= 1;
      }
    }

    if (curNode?.children && curNode?.children.length > 0) {
      for (const child of curNode.children) {
        traverseAndUpdateCardNumber(child);
      }
    }
  }

  traverseAndUpdateCardNumber(tree);
}

function reattachChildren(curNode, index) {
  const nodeToDelete = curNode.children.splice(index, 1)[0];
  if (nodeToDelete.children && nodeToDelete.children.length > 0) {
    curNode.children = [...curNode.children, ...nodeToDelete.children];
  }
}

function unassignUser(curNode, tree) {
  const unassignedUserId = curNode.userId;
  const curNodeCardNumber = curNode?.cardNumber;

  function traverseAndUpdate(node) {
    const nodeCardNumber = node?.cardNumber ?? 1;
    if (node.userDetails?._id === unassignedUserId) {
      if (nodeCardNumber > curNodeCardNumber) {
        node.cardNumber = nodeCardNumber - 1;
      }
      curNode.userId = null;
      delete curNode.userDetails;
    } else {
      curNode.userId = null;
      delete curNode.userDetails;
    }

    if (node.children && Array.isArray(node.children)) {
      for (const child of node.children) {
        traverseAndUpdate(child);
      }
    }
  }

  traverseAndUpdate(tree);
}
